import React, { CSSProperties, useState } from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { Referral } from '../../../../models/User';
import { palette } from '../../../../styles/theme';
import { useMainWindowHost } from '../../../../contexts/MainWindowHostContext';
import styles from '../../../../styles/styles.module.css';
import { useAuth } from '../../../../contexts/AuthContext';

interface ReferralDialogProps {
    open: boolean;
    referral: Referral;
    username: string;
    setOpen: (open: boolean) => void;
    setAuthDialogOpen: (open: boolean) => void;
}

const ReferralDialog = (props: ReferralDialogProps) => {
    const { isMobile } = useMainWindowHost();
    const { currentUser } = useAuth();

    const [isAlreadySignedIn, setIsAlreadySignedIn] = useState(false);

    const handleSignUpButton = () => {
        if (currentUser.isAnonymous) {
            props.setOpen(false);
            props.setAuthDialogOpen(true);
        } else {
            setIsAlreadySignedIn(true);
        }
    };

    const mainContainerStyle: CSSProperties = {
        textAlign: 'center',
    };

    const titleStyle: CSSProperties = {
        color: palette['secondary'],
        fontSize: isMobile ? '25px' : '35px',
        fontWeight: 'bold',
        padding: '10px',
    };

    const messageStyle: CSSProperties = {
        fontSize: isMobile ? '17px' : '20px',
    };

    return (
        <Dialog open={props.open} onClose={() => props.setOpen(false)}>
            <DialogTitle style={{ textAlign: 'center' }}>
                <Typography style={titleStyle}>
                    {props.referral ? 'Join Playerz today!' : 'Invalid referral!'}
                </Typography>
            </DialogTitle>
            <DialogContent>
                {props.referral ? (
                    <Box style={mainContainerStyle}>
                        <Box className={styles.inline} marginBottom={isMobile ? '40px' : '70px'}>
                            <Typography style={{ ...messageStyle, color: palette['secondary'] }}>
                                {props.username}&nbsp;
                            </Typography>
                            <Typography style={messageStyle}>is offering you a&nbsp;</Typography>
                            <Typography style={{ ...messageStyle, color: palette['secondary'] }}>
                                {props.referral.discount * 100}% discount&nbsp;
                            </Typography>
                            <Typography style={messageStyle}>
                                on your first purchase if you sign up with his referral link!
                            </Typography>
                        </Box>
                        <Button
                            style={{ backgroundColor: palette['secondary'], padding: '30px 20px' }}
                            onClick={() => {
                                handleSignUpButton();
                            }}
                        >
                            <Typography style={{ lineHeight: 1.2, fontSize: '18px' }}>
                                Sign up now to get a <br />
                                {props.referral.discount * 100}% discount!
                            </Typography>
                        </Button>
                        <Typography
                            style={{ color: palette['red'], visibility: isAlreadySignedIn ? 'visible' : 'hidden' }}
                        >
                            You are already signed in
                        </Typography>
                    </Box>
                ) : (
                    <Box className={styles.inline} style={{ textAlign: 'center', marginBottom: '30px' }}>
                        <Typography style={{ fontSize: '18px' }}>
                            Sorry, the referral link you are trying to access is either expired{' '}
                        </Typography>
                        <Typography style={{ color: palette['secondary'], fontSize: '18px' }}>expired </Typography>
                        <Typography style={{ fontSize: '18px' }}>or </Typography>
                        <Typography style={{ color: palette['secondary'], fontSize: '18px' }}>
                            does not exists
                        </Typography>
                        <Typography style={{ fontSize: '18px' }}>.</Typography>
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default ReferralDialog;
