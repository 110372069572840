import { Box, Button, Dialog, Divider, Typography } from '@material-ui/core';
import React, { CSSProperties, useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { PointsObjective, TipOption, UserStreamingData } from '../../../../../models/User';
import { LoadingButton } from '../../../../../styles/CustomComponents';
import DescriptionColumns from './DescriptionColumns';
import PointsObjectiveSetup from './PointsObjectiveSetup';
import TipMenuSetup from './TipMenuSetup';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useAuth } from '../../../../../contexts/AuthContext';
import { useMainWindowHost } from '../../../../../contexts/MainWindowHostContext';
import { palette } from '../../../../../styles/theme';
import isEqual from 'lodash.isequal';
import FirestoreUsers from '../../../../../firestore/FirestoreUsers';
import LivestreamTab from '../../../streaming_view_page/profile_page/tabs/LivestreamTab';

const DescriptionTab = () => {
    const { currentUser, currentUserStreamingData, currentUserPrivateData } = useAuth();
    const { isMobile } = useMainWindowHost();

    const [descriptionLeft, setDescriptionLeft] = useState('');
    const [descriptionRight, setDescriptionRight] = useState('');
    const [objectives, setObjectives] = useState<PointsObjective[]>([]);
    const [tipMenu, setTipMenu] = useState<TipOption[]>([]);
    const [processCompleted, setProcessCompleted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [descriptionUpdated, setDescriptionUpdated] = useState(false);
    const firestoreUsers: FirestoreUsers = new FirestoreUsers();

    useEffect(() => {
        if (!currentUserStreamingData) return;

        setObjectives(currentUserStreamingData.pointsObjectiveList);
        setTipMenu(currentUserStreamingData.tipMenu);
        setDescriptionLeft(currentUserStreamingData.descriptionColumnLeft);
        setDescriptionRight(currentUserStreamingData.descriptionColumnRight);
    }, [currentUserStreamingData]);

    useEffect(() => {
        if (!currentUserStreamingData) return;

        setDescriptionUpdated(
            descriptionLeft !== currentUserStreamingData.descriptionColumnLeft ||
                descriptionRight !== currentUserStreamingData.descriptionColumnRight ||
                !isEqual(objectives, currentUserStreamingData.pointsObjectiveList) ||
                !isEqual(tipMenu, currentUserStreamingData.tipMenu),
        );
    }, [descriptionLeft, descriptionRight, objectives, tipMenu]);

    const updateDescription = async () => {
        setLoading(true);

        firestoreUsers.updateUserDescription(
            currentUserStreamingData.userId,
            descriptionLeft,
            descriptionRight,
            tipMenu,
            objectives,
        );

        setProcessCompleted(true);
        setTimeout(() => {
            setProcessCompleted(false);
            setLoading(false);
            setDescriptionUpdated(false);
        }, 3000);
    };

    const handleClosePreview = () => {
        setIsPreviewOpen(false);
    };

    const getPreviewContent = (): UserStreamingData => {
        const previewData: UserStreamingData = JSON.parse(JSON.stringify(currentUserStreamingData));
        previewData.tipMenu = tipMenu;
        previewData.descriptionColumnLeft = descriptionLeft;
        previewData.descriptionColumnRight = descriptionRight;
        previewData.pointsObjectiveList = objectives;
        return previewData;
    };

    const saveChangesButtonStyle: CSSProperties = {
        position: 'absolute',
        bottom: isMobile ? (currentUser.isVerified ? '10px' : '40px') : '30px',
        right: isMobile ? '10px' : '60px',
        display: 'flex',
    };

    const buttonStyle: CSSProperties = {
        backgroundColor: palette['secondary'],
        borderRadius: '20px',
    };

    const dividerStyle: CSSProperties = {
        backgroundColor: palette['primary'],
        height: '2px',
    };

    const applicationReviewMessageStyle: CSSProperties = {
        borderRadius: '20px',
        padding: '10px 20px',
        backgroundColor: palette['primary'],
        marginBottom: '40px',
    };

    const applicationReviewTextStyle: CSSProperties = {
        color: palette['text_s'],
        lineHeight: '1',
    };

    return (
        <Box>
            {currentUserPrivateData.streamers.every((streamer) => !streamer.isVerified) && (
                <Box style={applicationReviewMessageStyle}>
                    <Typography
                        style={{
                            lineHeight: '1',
                            fontSize: '18px',
                            color: palette['secondary'],
                            marginBottom: '10px',
                        }}
                    >
                        Your application to become a streamer is currently under review.
                    </Typography>
                    <Typography style={applicationReviewTextStyle}>
                        You can still setup your description and subscriptions tabs, but you wont be able to start
                        streaming until your application is approved.
                    </Typography>
                </Box>
            )}
            <Box marginBottom="50px">
                <Typography style={{ fontWeight: 'bold' }}>
                    Here you can edit your main description on your profile page. You should preview it before saving.
                </Typography>
                <Divider style={dividerStyle} />
                <Box fontStyle="italic">
                    <Typography style={{ marginBottom: '25px', fontSize: '14px', marginTop: '5px' }}>
                        Pro tip: To add emojis on windows 10, use{' '}
                        <span
                            style={{ backgroundColor: palette['primary_d'], padding: '2px 4px', borderRadius: '5px' }}
                        >
                            Win + ;
                        </span>
                    </Typography>
                </Box>
                <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Box
                        style={{
                            margin: isMobile ? '0px 0px 10px 0px' : '0px 10px 10px 0px',
                            width: isMobile ? '100%' : 'auto',
                        }}
                    >
                        <DescriptionColumns
                            setDescriptionLeft={setDescriptionLeft}
                            setDescriptionRight={setDescriptionRight}
                            descriptionLeft={descriptionLeft}
                            descriptionRight={descriptionRight}
                        />
                    </Box>
                    <Box style={{ width: '100%', maxWidth: '610px' }}>
                        <PointsObjectiveSetup
                            setObjectives={setObjectives}
                            objectives={objectives}
                            monthlyScore={currentUserStreamingData.monthlyScore}
                        />
                        <TipMenuSetup setTipMenu={setTipMenu} tipMenu={tipMenu} />
                    </Box>
                    <Box style={saveChangesButtonStyle}>
                        <Button
                            onClick={() => {
                                setIsPreviewOpen(true);
                            }}
                            style={{ ...buttonStyle, marginRight: isMobile ? '10px' : '30px' }}
                        >
                            <Typography>Preview</Typography>
                        </Button>
                        {!processCompleted && (
                            <LoadingButton
                                style={buttonStyle}
                                value="Save changes"
                                disabled={!descriptionUpdated}
                                isLoading={loading}
                                onClick={() => {
                                    updateDescription();
                                }}
                            />
                        )}
                        {processCompleted && (
                            <CheckCircleIcon
                                color="secondary"
                                style={{ fontSize: '30px', marginLeft: '10px', marginTop: '4px' }}
                            />
                        )}
                    </Box>
                    <Dialog
                        open={isPreviewOpen}
                        onClose={handleClosePreview}
                        PaperProps={{
                            style: {
                                margin: '18px',
                                width: '100%',
                                maxWidth: '1200px',
                                padding: '15px',
                                backgroundColor: palette['primary_d'],
                            },
                        }}
                    >
                        <Box>
                            <LivestreamTab streamerInfo={getPreviewContent()} preview={true} />
                        </Box>
                    </Dialog>
                </Box>
            </Box>
        </Box>
    );
};

export default DescriptionTab;
