import React, { useRef, useState } from 'react';
import { Box, Button, Dialog, DialogTitle, Typography } from '@material-ui/core';
import AvatarEditor from 'react-avatar-editor';
import { palette } from '../../../../styles/theme';

interface ProfileBannerEditorDialogProps {
    setDialogState: (open: boolean) => void;
    setProfileBannerUrl: (url: string) => void;
    open: boolean;
    profileBannerUrl: string;
    bannerWidth: number;
    bannerHeight: number;
}

const ProfileBannerEditorDialog = (props: ProfileBannerEditorDialogProps) => {
    const [scale, setScale] = useState(1);

    const editorRef = useRef<AvatarEditor>(null);

    const handleClose = () => {
        props.setDialogState(false);
    };

    const confirm = () => {
        const img: HTMLCanvasElement = editorRef.current?.getImage() as HTMLCanvasElement;
        props.setProfileBannerUrl(img.toDataURL());
        handleClose();
    };

    const handleScale = (event: React.ChangeEvent<HTMLInputElement>) => {
        const scale = parseFloat(event.target.value);
        setScale(scale);
    };

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            fullWidth
            maxWidth="lg"
            PaperProps={{ style: { margin: '10px', width: '100%' } }}
        >
            <DialogTitle> Edit your profile banner </DialogTitle>
            <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                <AvatarEditor
                    ref={editorRef}
                    image={props.profileBannerUrl}
                    width={props.bannerWidth}
                    height={props.bannerHeight}
                    color={[255, 255, 255, 0.6]}
                    scale={scale}
                />
            </Box>
            <Box textAlign="center" marginTop="10px">
                <Typography display="inline"> Zoom : </Typography>
                <input
                    name="scale"
                    type="range"
                    onChange={(event) => handleScale(event)}
                    min="1"
                    max="2"
                    step="0.01"
                    defaultValue="1"
                />
            </Box>
            <Box>
                <Button
                    style={{ backgroundColor: palette['secondary'], margin: '30px 10px 10px 10px', float: 'right' }}
                    onClick={() => confirm()}
                >
                    Confirm
                </Button>
            </Box>
        </Dialog>
    );
};

export default ProfileBannerEditorDialog;
