import { firestore } from '../Firebase';
import {
    UserPublicData,
    Notification,
    Subscription,
    TipOption,
    PointsObjective,
    UserStreamingData,
} from '../models/User';
import { firestoreCollections } from '../constants/Constants';

export default class FirestoreUsers {
    private readonly publicCollection = firestore.collection(firestoreCollections.userPublic);
    private readonly privateCollection = firestore.collection(firestoreCollections.userPrivate);
    private readonly userStreamingDataCollection = firestore.collection(firestoreCollections.userStreaming);

    async isUsernameAvailable(username: string): Promise<boolean> {
        const docs = await this.publicCollection.limit(1).where('username', '==', username.toLowerCase()).get();

        return docs.empty;
    }

    async updateUserPublicProfile(userProfileUpdateInfo: UserPublicData): Promise<void> {
        await this.publicCollection.doc(userProfileUpdateInfo.userId).update(userProfileUpdateInfo);
    }

    async updateUserDescription(
        userId: string,
        descriptionColumnLeft?: string,
        descriptionColumnRight?: string,
        tipMenu?: TipOption[],
        pointsObjectiveList?: PointsObjective[],
    ): Promise<void> {
        const ref = this.userStreamingDataCollection.doc(userId);
        await ref.update({
            descriptionColumnLeft: descriptionColumnLeft,
            descriptionColumnRight: descriptionColumnRight,
            tipMenu: tipMenu,
            pointsObjectiveList: pointsObjectiveList,
        });
    }

    async updateUserSubscription(userId: string, newSubscriptions: Subscription): Promise<void> {
        const ref = this.userStreamingDataCollection.doc(userId);

        await ref.update({ subscription: newSubscriptions });
    }

    async updateUserSubscriptionBanner(userId: string, bannerUrl: string): Promise<void> {
        const ref = this.userStreamingDataCollection.doc(userId);
        await ref.update({ subscriptionBannerUrl: bannerUrl });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async getUsersLastSeen(userId: string): Promise<any> {
        const user: UserPublicData = (await this.publicCollection.doc(userId).get()).data() as UserPublicData;
        return user.lastSeen;
    }

    async setAllNotificationsToSeen(userId: string): Promise<void> {
        await this.privateCollection
            .doc(userId)
            .collection('notifications')
            .where('seen', '==', false)
            .get()
            .then((notifs) => {
                notifs.forEach((notif) => {
                    notif.ref.update({
                        seen: true,
                    });
                });
            });
    }

    async addNotifications(userId: string, notifications: Map<string, Notification>): Promise<void> {
        notifications.forEach(async (notif, id) => {
            await this.privateCollection.doc(userId).collection(firestoreCollections.notifications).doc(id).set(notif);
        });
    }

    async getUserPublicInfo(userId: string): Promise<UserPublicData> {
        const user = await this.publicCollection.doc(userId).get();
        return user.data() as UserPublicData;
    }

    async getUserStreamingData(userId: string): Promise<UserStreamingData> {
        const user = await this.userStreamingDataCollection.doc(userId).get();
        return user.data() as UserStreamingData;
    }

    async setUserLivestreamId(streamId: string, userId: string): Promise<void> {
        await this.userStreamingDataCollection.doc(userId).update({ livestreamId: streamId });
    }
}
