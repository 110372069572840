import { Box, Typography } from '@material-ui/core';
import React, { CSSProperties, useEffect, useState } from 'react';
import { palette } from '../../../../../styles/theme';
import CreateIcon from '@material-ui/icons/Create';
import EmailModificationDialog from '../../dialogs/EmailModificationDialog';
import { Input, LoadingButton } from '../../../../../styles/CustomComponents';
import { useAuth } from '../../../../../contexts/AuthContext';
import { auth } from '../../../../../Firebase';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useMainWindowHost } from '../../../../../contexts/MainWindowHostContext';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const PrivateInformation = () => {
    const { currentUser, resetPassword } = useAuth();
    const { isMobile } = useMainWindowHost();

    const [openEmailModificationDialog, setOpenEmailModificationDialog] = useState(false);
    const [email, setEmail] = useState('');
    const [showEmail, setShowEmail] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [passwordResetEmailSent, setPasswordResetEmailSent] = useState(false);

    useEffect(() => {
        setEmail(auth.currentUser?.email as string);
    }, [currentUser]);

    const handlePasswordResetButton = async () => {
        setButtonLoading(true);
        await resetPassword(email);

        setPasswordResetEmailSent(true);
        setButtonLoading(false);
    };

    const textFieldsStyle: CSSProperties = {
        width: isMobile ? '100%' : '300px',
    };

    const labelsStyle: CSSProperties = {
        width: '25vw',
        marginRight: '20px',
        maxWidth: '200px',
    };

    const inputsStyle: CSSProperties = {
        marginBottom: isMobile ? '20px' : '50px',
        flexGrow: 1,
    };

    const createIconStyle: CSSProperties = {
        marginLeft: '15px',
        marginTop: '4px',
        cursor: 'pointer',
        color: palette['text_p'],
    };

    const visibilityIconStyle: CSSProperties = {
        cursor: 'pointer',
        color: palette['text_p'],
    };

    const rowsStyle: CSSProperties = {
        display: 'flex',
        justifyContent: isMobile ? 'space-between' : 'unset',
    };

    return (
        <Box>
            <Box style={rowsStyle}>
                <Typography style={labelsStyle}> Email : </Typography>
                <Box display="flex">
                    <Box style={inputsStyle}>
                        <Input
                            value={email}
                            style={textFieldsStyle}
                            readOnly={true}
                            type={showEmail ? 'text' : 'password'}
                            endIcon={
                                showEmail ? (
                                    <VisibilityOff
                                        style={visibilityIconStyle}
                                        onClick={() => setShowEmail(!showEmail)}
                                    />
                                ) : (
                                    <Visibility style={visibilityIconStyle} onClick={() => setShowEmail(!showEmail)} />
                                )
                            }
                        />
                    </Box>
                    <CreateIcon style={createIconStyle} onClick={() => setOpenEmailModificationDialog(true)} />
                </Box>
            </Box>
            <Box style={{ ...rowsStyle, justifyContent: 'unset' }}>
                <Typography style={labelsStyle}> Password : </Typography>

                {passwordResetEmailSent ? (
                    <Box display="flex" alignItems="center">
                        <Typography>Email sent</Typography>
                        <CheckCircleIcon color="secondary" style={{ fontSize: '20px', marginLeft: '5px' }} />
                    </Box>
                ) : (
                    <LoadingButton
                        style={{ backgroundColor: palette['red'], zIndex: 0 }}
                        value="Reset password"
                        isLoading={buttonLoading}
                        onClick={() => handlePasswordResetButton()}
                    />
                )}
            </Box>

            <EmailModificationDialog
                open={openEmailModificationDialog}
                setDialogState={setOpenEmailModificationDialog}
                email={email}
                setEmail={setEmail}
            />
        </Box>
    );
};

export default PrivateInformation;
