import React from 'react';
import { Box, Typography } from '@material-ui/core';

const Usc2257 = () => {
    return (
        <Box>
            <Typography style={{ fontSize: '20px', fontWeight: 600 }}>18 U.S.C. § 2257 Compliance Notice</Typography>
            <br />

            <Typography>
                All streamers, models, actors, actresses and other persons that appear in any visual depiction of actual
                sexually explicit conduct appearing or otherwise contained in this Website were over the age of eighteen
                years at the time of the creation of such depictions.
            </Typography>
            <br />
            <Typography>
                All other visual depictions displayed on this Website are exempt from the provision of 18 U.S.C. § 2257
                and 28 C.F.R. 75 because said visual depictions do not consist of depictions of conduct as specifically
                listed in 18 U.S.C § 2256 (2) (A) through (D), but are merely depictions of non-sexually explicit
                nudity, or are depictions of simulated sexual conduct, or are otherwise exempt because the visual
                depictions were created prior to July 3, 1995.
            </Typography>
            <br />

            <Typography>
                With respect to all visual depictions displayed on this website, whether of actual sexually explicit
                conduct, simulated sexual content or otherwise, all persons in said visual depictions were at least 18
                years of age when said visual depictions were created. The original records required pursuant to 18
                U.S.C. § 2257 and 28 C.F.R. 75 for all materials contained in the website are kept by the following
                Custodian of Records: Playerz.tv, Quebec, Canada
            </Typography>
        </Box>
    );
};

export default Usc2257;
