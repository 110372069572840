import { Box, Typography } from '@material-ui/core';
import DOMPurify from 'dompurify';
import React, { CSSProperties, useEffect, useState } from 'react';
import { StreamPublicData } from '../../../../../models/Stream';
import { TipOption, User, UserStreamingData } from '../../../../../models/User';
import { palette } from '../../../../../styles/theme';
import PointsObjectiveBar from '../../../../helper/PointsObjectiveBar';
import TipMenuOption from '../../../../helper/TipMenuOption';
import { useAuth } from '../../../../../contexts/AuthContext';
import { TipRequest } from '../../../../../models/Transaction';
import FirestoreTransactions from '../../../../../firestore/FirestoreTransactions';
import 'react-quill/dist/quill.snow.css';

interface DescriptionTabProps {
    streamerInfo: UserStreamingData;
    currentStream?: StreamPublicData;
    preview?: boolean;
}

const LivestreamTab = (props: DescriptionTabProps) => {
    const { currentUser } = useAuth();

    const [descriptionColumnLeft, setDescriptionColumnLeft] = useState('');
    const [descriptionColumnRight, setDescriptionColumnRight] = useState('');

    const [tipMenuLoading, setTipMenuLoading] = useState(false);

    const firestoreTransactions: FirestoreTransactions = new FirestoreTransactions();

    useEffect(() => {
        if (!props.streamerInfo) return;

        setDescriptionColumnLeft(props.streamerInfo.descriptionColumnLeft);
        setDescriptionColumnRight(props.streamerInfo.descriptionColumnRight);
    }, [props.streamerInfo]);

    const handleTipping = async (tipOptionId: number, tipOption: TipOption) => {
        setTipMenuLoading(true);
        const tipRequest: TipRequest = {
            senderId: (currentUser as User) && currentUser?.userId,
            senderUsername: currentUser?.displayUsername,
            receiverId: props.currentStream && props.currentStream.ownerId,
            receiverUsername: location.pathname.substring(1),
            requestId: tipOptionId,
            points: tipOption.points,
        };
        await firestoreTransactions.addTipRequest(tipRequest);
        setTipMenuLoading(false);
    };

    const previewStyle: CSSProperties = {
        fontFamily: 'Mulish, sans-serif',
        color: palette['text_p'],
        backgroundColor: palette['primary_d'],
        flexGrow: 1,
        marginBottom: '20px',
        padding: '0px 8px',
        maxWidth: '336px',
    };

    const titleStyle: CSSProperties = {
        display: 'inline',
        fontWeight: 700,
        fontSize: '19px',
        textShadow: '2px 2px 4px black',
    };

    const mainContainerStyle: CSSProperties = {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'center',
        margin: '10px 1px',
    };

    const createMarkup = (value: string) => {
        return {
            __html: DOMPurify.sanitize(value),
        };
    };

    return props.streamerInfo ? (
        <Box style={mainContainerStyle}>
            <Box
                className="ql-editor"
                dangerouslySetInnerHTML={createMarkup(descriptionColumnLeft)}
                style={previewStyle}
            />
            <Box
                className="ql-editor"
                dangerouslySetInnerHTML={createMarkup(descriptionColumnRight)}
                style={previewStyle}
            />
            <Box style={{ flexGrow: 1, padding: '0px 8px', minWidth: '280px' }}>
                {props.streamerInfo.pointsObjectiveList &&
                    props.streamerInfo.pointsObjectiveList.length > 0 &&
                    typeof props.streamerInfo.monthlyScore !== 'undefined' &&
                    ((props.currentStream && typeof props.currentStream.startingScore !== 'undefined') ||
                        props.preview) && (
                        <Box style={{ marginBottom: '20px' }}>
                            <Typography style={{ ...titleStyle, color: palette['secondary'] }}>Points&nbsp;</Typography>
                            <Typography style={titleStyle}>Tracker</Typography>
                            <PointsObjectiveBar
                                objectives={props.streamerInfo.pointsObjectiveList}
                                currentScore={props.streamerInfo.monthlyScore}
                                startingScore={props.currentStream?.startingScore}
                            />
                        </Box>
                    )}
                {props.streamerInfo.tipMenu && props.streamerInfo.tipMenu.length > 0 && (
                    <Box>
                        <Typography style={{ ...titleStyle, color: palette['secondary'] }}>Tip&nbsp;</Typography>
                        <Typography style={titleStyle}>Menu</Typography>

                        <Box style={{ backgroundColor: palette['primary_l'], borderRadius: '10px', padding: '10px' }}>
                            <TipMenuOption
                                tipMenu={props.streamerInfo.tipMenu}
                                handleTipping={!props.preview ? handleTipping : undefined}
                                loading={tipMenuLoading}
                                disable={
                                    !props.preview &&
                                    (!props.currentStream?.active || currentUser?.userId === props.streamerInfo.userId)
                                }
                            />
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    ) : (
        <Box></Box>
    );
};

export default LivestreamTab;
