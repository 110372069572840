import { Box, Dialog, Divider, Input, Typography } from '@material-ui/core';
import React, { CSSProperties, useState } from 'react';
import { useAuth } from '../../../../contexts/AuthContext';
import { useMainWindowHost } from '../../../../contexts/MainWindowHostContext';
import FirestoreTransactions from '../../../../firestore/FirestoreTransactions';
import { DonationDetails } from '../../../../models/Transaction';
import { UserPublicData } from '../../../../models/User';
import { palette } from '../../../../styles/theme';
import PinterestIcon from '@material-ui/icons/Pinterest';
import { LoadingButton } from '../../../../styles/CustomComponents';

interface DonationDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    donationDialogX: number;
    donationDialogY: number;
    user: UserPublicData;
    subscriptionBadge?: string;
}

const DonationDialog = (props: DonationDialogProps) => {
    const firestoreTransactions: FirestoreTransactions = new FirestoreTransactions();

    const { isMobile } = useMainWindowHost();
    const { currentUser, currentUserPrivateData } = useAuth();

    const [donationAmount, setDonationAmount] = useState('');
    const [donationMessage, setDonationMessage] = useState('');
    const [notEnoughPoints, setNotEnoughPoints] = useState(false);
    const [loading, setLoading] = useState(false);

    const onSendingDonation = async () => {
        setLoading(true);
        if (currentUserPrivateData.pointsAmount < Number(donationAmount)) {
            setNotEnoughPoints(true);
            setDonationAmount('');
            return;
        }
        if (Number(donationAmount) > 0) {
            const donation: DonationDetails = {
                senderId: currentUser.userId,
                senderUsername: currentUser.displayUsername,
                receiverId: props.user && props.user.userId,
                receiverUsername: location.pathname.substring(1),
                points: Number(donationAmount),
                messages: donationMessage !== '' ? [donationMessage] : [],
            };
            await firestoreTransactions.addNewDonation(donation);
        }
        handleDonationClose();
        setLoading(false);
    };

    const handleDonationClose = () => {
        props.setOpen(false);
        setDonationAmount('');
        setDonationMessage('');
    };

    const donationProfilePictureStyle: CSSProperties = {
        width: isMobile ? '45px' : '70px',
        height: isMobile ? '45px' : '70px',
        borderRadius: '45px',
        border: `solid 3px ${palette['primary_d']}`,
    };

    const donationTitleStyle: CSSProperties = {
        fontWeight: 700,
        fontSize: '25px',
        display: 'inline',
    };

    const donationSubTitleStyle: CSSProperties = {
        fontWeight: 700,
        fontSize: '18px',
        display: 'inline',
    };

    return (
        <Dialog
            id="customized-menu"
            open={props.open}
            onClose={handleDonationClose}
            PaperProps={{
                style: {
                    padding: '10px',
                    maxWidth: '300px',
                    boxShadow: '0px 0px 15px rgba(0,0,0,0.8)',
                    left: props.donationDialogX,
                    position: 'absolute',
                    margin: '0px',
                    top: props.donationDialogY,
                },
            }}
            BackdropProps={{ style: { backgroundColor: 'rgba(0,0,0,0)' } }}
        >
            <Box style={{ display: 'flex', width: '100%' }}>
                <Box style={{ width: '100%' }}>
                    <Box>
                        <Typography style={donationTitleStyle}>Give&nbsp;</Typography>
                        <Typography style={{ ...donationTitleStyle, color: palette['secondary'] }}>Points</Typography>
                        <Typography style={donationTitleStyle}>&nbsp;To</Typography>
                    </Box>
                    <Box style={{ display: 'flex' }}>
                        <Typography
                            style={{
                                ...donationTitleStyle,
                                color: palette['secondary'],
                                marginLeft: 'auto',
                            }}
                        >
                            {props.user.displayUsername}
                        </Typography>
                    </Box>
                </Box>
                <img
                    src={props.user?.profilePictureUrl}
                    style={{ ...donationProfilePictureStyle, marginLeft: 'auto' }}
                />
            </Box>
            <Divider
                style={{
                    backgroundColor: palette['primary_d'],
                    height: '2px',
                    margin: '10px 0px',
                }}
            />
            <Typography style={{ fontSize: '16px' }}>Your Message:</Typography>

            <Input
                style={{
                    backgroundColor: palette['primary_d'],
                    borderRadius: '15px',
                    width: '100%',
                    padding: '15px 10px',
                }}
                placeholder="Optional"
                multiline
                disableUnderline={true}
                value={donationMessage}
                onChange={(event) => {
                    setDonationMessage(event.target.value);
                }}
            />
            <Divider
                style={{
                    backgroundColor: palette['primary_d'],
                    height: '2px',
                    margin: '10px 0px',
                }}
            />

            <Box style={{ display: 'flex' }}>
                <Box
                    style={{
                        minWidth: 'fit-content',
                        float: 'left',
                        display: 'inline-block',
                        marginRight: '15px',
                    }}
                >
                    <Typography style={donationSubTitleStyle}>How many&nbsp;</Typography>
                    <Typography style={{ ...donationSubTitleStyle, color: palette['secondary'] }}>Points</Typography>
                    <Typography style={donationSubTitleStyle}>?</Typography>
                    <Typography style={{ fontSize: '14px', color: palette['text_s'] }}>
                        You have {currentUserPrivateData.pointsAmount} points
                    </Typography>
                </Box>
                <Input
                    style={{
                        backgroundColor: palette['primary_d'],
                        borderRadius: '15px',
                        width: '100%',
                        padding: '5px 10px',
                    }}
                    placeholder="40"
                    disableUnderline={true}
                    endAdornment={<PinterestIcon style={{ color: palette['secondary'] }} />}
                    value={donationAmount}
                    onChange={(event) => {
                        if (event.target.value === '' || /^[0-9\b]+$/.test(event.target.value)) {
                            setDonationAmount(event.target.value);
                            setNotEnoughPoints(false);
                        }
                    }}
                />
            </Box>
            {notEnoughPoints && (
                <Typography style={{ color: palette['red'] }}>*You do not have enough Points</Typography>
            )}

            <Divider
                style={{
                    backgroundColor: palette['primary_d'],
                    height: '2px',
                    margin: '10px 0px',
                }}
            />
            <Box style={{ display: 'flex' }}>
                <LoadingButton
                    style={{
                        backgroundColor: palette['secondary'],
                        margin: 'auto',
                        borderRadius: '15px',
                        cursor: 'pointer',
                        padding: '8px 15px',
                    }}
                    value="Donate"
                    onClick={() => onSendingDonation()}
                    isLoading={loading}
                />
            </Box>
        </Dialog>
    );
};

export default DonationDialog;
