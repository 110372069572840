import { firestoreCollections } from '../constants/Constants';
import { firestore } from '../Firebase';
import { Notification } from '../models/User';

export default class FirestoreNofications {
    private readonly collection = firestore.collection(firestoreCollections.notifications);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async getNotificationsSinceLastLogin(followingIds: string[], lastLogin: any): Promise<Map<string, Notification>> {
        const notifications = await this.collection
            .where('timestamp', '>', lastLogin)
            .where('userId', 'in', followingIds)
            .get();

        const res = new Map<string, Notification>();
        notifications.forEach((notif) => {
            res.set(notif.id, notif.data() as Notification);
        });

        return res;
    }
}
