import React, { useContext } from 'react';
import { theme } from '../styles/theme';
import { ThemeProvider as MaterialUIThemeProvider } from '@material-ui/styles';
import { Overrides } from '@material-ui/core/styles/overrides';

interface ThemeProviderValue {
    updateThemeOverrides: (overrides: Overrides) => void;
}

const ThemeContext = React.createContext({} as ThemeProviderValue);

export const useTheme = () => {
    return useContext(ThemeContext);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ThemeProvider: React.FC = ({ children }: any) => {
    const updateThemeOverrides = (overrides: Overrides) => {
        theme.overrides = overrides;
    };

    const value = {
        updateThemeOverrides,
    };

    return (
        <ThemeContext.Provider value={value}>
            <MaterialUIThemeProvider theme={theme}> {children} </MaterialUIThemeProvider>;
        </ThemeContext.Provider>
    );
};
