import React from 'react';
import { Box, Typography } from '@material-ui/core';

const Rules = () => {
    return (
        <Box>
            <Typography>
                <h3>Playerz Community Guidelines</h3>
                Effective Date: July 10, 2021
                <br />
                <br />
                Playerz&apos;s goal is to provide a safe environment for its streamers and viewers. To achieve this
                goal, we ask our users to respect to following guidelines in order to create a positive and respectful
                experience for everyone using our platform.
                <br /> <br />
                Any user that does not respect these rules will be subject to sanctions such as temporary or permanent
                account suspensions. Any attempt to circumvent an account suspension or chat ban will result in
                additionnal sanctions.
                <br /> <br />
                <h3>SEXUALIZED CONTENT</h3>
                Providing sexualised content is allowed by any accepted streamer on an account. To be accepted as a
                streamer, you need to prove that you are at least 18 years old by providing valid documents with a clear
                picture of yourself holding them. Take note that if multiple streamers appear in your livestreams, they
                all need to be accepted with the same process. Unverified streamers in your livestreams will lead to an
                immediate account suspensions without prior notice.
                <br /> <br />
                <h3>LAWS</h3>
                Breaking any local, national or international laws while using our services is prohibited. This includes
                featuring, encouraging, offering or soliciting any illegal activity such as theft, death threats, sexual
                assault, child abuse and more.
                <br /> <br />
                <h3>VIOLENCE, THREATS AND HATEFUL CONDUCT</h3>
                All accounts associated with violent activities such as physically harming or killing others will be
                suspended indefinitely. This includes commiting but also encouraging, soliciting, offering or featuring
                in such activities.
                <br /> <br />
                Any form of threats such as harming or killing others, hacking or DDOSing and the use of weapons to
                intimidate, harm or kill will result in an indefinite suspension.
                <br /> <br />
                Hateful conduct and harassment are also not allowed on Playerz and will result in sanctions. This
                includes but is not limited to hateful speeches about race, ethnicity, color, caste, national origin,
                immigration status, religion, sex, gender, gender identity, sexual orientation, disability, serious
                medical condition, and veteran status. Harassment can also include stalking, repetitive insults, sexual
                harassment and more.
                <br /> <br />
                <h3>SELF-DESTRUCTIVE BEHAVIOR</h3>
                Any activity that may endanger your life or lead to your physical harm is prohibited. This includes, but
                is not limited to suicide threats, intentional physical trauma, illegal use of drugs, illegal or
                dangerous consumption of alcohol, and dangerous or distracted driving.
                <br /> <br />
                <h3>IMPERSONATION</h3>
                Any attempts to misrepresent yourself as an individual or organization will result in an indefinite
                account suspension.
                <br /> <br />
                <h3>SPAMS & SCAMS</h3>
                Any activities related to spamming and scamming such as repetitively posting unwanted messages or user
                reports, unauthorized advertisements, phishing, tampering, spreading viruses or misinformation and more
                are subject account suspensions.
                <br /> <br />
                <h3>INTELLECTUAL PROPERTY RIGHTS</h3>
                Creators can only share their own content or content that they have been given permission to use. By
                sharing content on your channel that you do not have the rights to share, you may be violating someone
                else&apos;s intellectual property rights. In that case, the unauthorized content can be taken down by
                Playerz at the request of the creator. Multiple violations of this policy can lead to permanent account
                suspensions.
                <br /> <br />
                <h3>LABELING YOU OWN CONTENT</h3>
                Playerz asks you to accurately label your own content (stream title, tags, etc.). Repetitive misuses of
                content labeling will lead to sanctions.
            </Typography>
        </Box>
    );
};

export default Rules;
