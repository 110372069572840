import { firestoreCollections, paypalTransactionTypes } from '../constants/Constants';
import { firestore, functions } from '../Firebase';
import { CompletedPaypalOrder, DonationDetails, TipRequest } from '../models/Transaction';

export default class FirestoreTransactions {
    private readonly collection = firestore.collection(firestoreCollections.transactions);

    async addPaypalTransaction(order: CompletedPaypalOrder) {
        await this.collection.doc(order.paypalId).set(order);
    }

    async getTransaction(transactionId: string) {
        const doc = await this.collection.doc(transactionId).get();
        return doc.data();
    }

    // async getPointsPackages() {
    //     const getPointsPackages = functions.httpsCallable('payPalFunctions-getPointsPackages');
    //     const ret = await getPointsPackages();
    //     return ret.data;
    // }

    async setNewPaypalTransaction(transaction: CompletedPaypalOrder) {
        const newPaypalTransaction =
            transaction.type === paypalTransactionTypes.order
                ? functions.httpsCallable('payPalFunctions-processPaypalTransaction')
                : functions.httpsCallable('payPalFunctions-processPaypalSubscription');

        const ret = await newPaypalTransaction({ transaction: transaction });
        return ret.data;
    }

    async addNewDonation(donationDetails: DonationDetails) {
        const processDonation = functions.httpsCallable('usersFunctions-processDonation');
        const ret = await processDonation({ donation: donationDetails });
        return ret;
    }

    async addTipRequest(tipRequest: TipRequest) {
        const processTipRequest = functions.httpsCallable('usersFunctions-processTipRequest');
        const ret = await processTipRequest({ donation: tipRequest });
        return ret;
    }

    async getSubscriptionPlanId(monthlyFee: number) {
        const getBillingPlanId = functions.httpsCallable('payPalFunctions-getBillingPlanId');
        const ret = await getBillingPlanId({ monthlyFee: monthlyFee });
        return ret.data;
    }

    async cancelPaypalSubscription(subscriptionId: string, userId: string, subscribedToId: string) {
        const cancelPaypalSubscription = functions.httpsCallable('payPalFunctions-cancelPaypalSubscription');
        const ret = await cancelPaypalSubscription({
            subscriptionId: subscriptionId,
            userId: userId,
            subscribedToId: subscribedToId,
        });

        return ret.data;
    }
}
