import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { Box, Typography, Button, Tooltip } from '@material-ui/core';
import { LoadingButton } from '../../../../../styles/CustomComponents';
import { useMainWindowHost } from '../../../../../contexts/MainWindowHostContext';
import StartingStreamDialog from '../../dialogs/startingStreamDialog/StartingStreamDialog';
import UnfollowDialog from '../../dialogs/UnfollowDialog';
import StreamEndConfirmationDialog from '../../dialogs/StreamEndConfirmationDialog';
import SubscribeDialog from '../../dialogs/subscriptions/SubscribeDialog';
import AuthenticationDialog from '../../../../nav_bar/authentication/AuthenticationDialog';
import { firestore } from '../../../../../Firebase';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../../../contexts/AuthContext';
import FirestoreFollows from '../../../../../firestore/FirestoreFollows';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { firestoreCollections } from '../../../../../constants/Constants';
import { FollowUser, SubscriptionUser, UserPublicData, UserStreamingData } from '../../../../../models/User';
import { palette } from '../../../../../styles/theme';
import { StreamPublicData } from '../../../../../models/Stream';
import UnsubscribeDialog from '../../dialogs/subscriptions/UnsubscribeDialog';
import PersonIcon from '@material-ui/icons/Person';
import PinterestIcon from '@material-ui/icons/Pinterest';
import DonationDialog from '../../dialogs/DonationDialog';
import { getNumberInThousands } from '../../../../../helpers/helpers';

interface ProfileHeaderButtonsProps {
    user: UserPublicData;
    currentStream: StreamPublicData;
    userStreamingData: UserStreamingData;
    banned: boolean;
}

const ProfileHeaderButtons = (props: ProfileHeaderButtonsProps) => {
    const firestoreFollowers: FirestoreFollows = new FirestoreFollows();

    const location = useLocation();
    const { currentUser, currentUserPrivateData } = useAuth();
    const { isMobile } = useMainWindowHost();

    const [followers] = useCollectionData<FollowUser>(
        firestore
            .collection(firestoreCollections.follows)
            .doc(props.user.userId)
            .collection(firestoreCollections.followers),
    );

    const [subscribers] = useCollectionData<SubscriptionUser>(
        firestore
            .collection(firestoreCollections.subscriptions)
            .doc(props.user.userId)
            .collection(firestoreCollections.subscribers),
    );

    const [startingStreamDialogOpen, setStaringStreamDialogOpen] = useState(false);
    const [endStreamDialogOpen, setEndStreamDialogOpen] = useState(false);
    const [unfollowDialogOpen, setUnfollowDialogOpen] = useState(false);
    const [subscribeDialogOpen, setSubscribeDialogOpen] = useState(false);
    const [unsubscribeDialogOpen, setUnsubscribeDialogOpen] = useState(false);
    const [authenticationDialogOpen, setAuthenticationDialogOpen] = useState(false);
    const [donationDialogOpen, setDonationDialogOpen] = useState(false);

    const [donationDialogX, setDonationDialogX] = useState<number>();
    const [donationDialogY, setDonationDialogY] = useState<number>();
    const [subscription, setSubscription] = useState<SubscriptionUser>();

    const [following, setFollowing] = useState(false);
    const [subscribed, setSubscribed] = useState(false);
    const isUserOwner = location.pathname.substring(1) === currentUser.username;

    const donationButtonRef = useRef<HTMLDivElement>(null);
    const isStreamActive = props.currentStream?.active && !props.banned;

    useEffect(() => {
        function onResize() {
            setDonationDialogPosition();
        }
        window.addEventListener('resize', onResize);
    }, []);

    useEffect(() => {
        if (!followers) {
            setFollowing(false);
            return;
        }

        setFollowing(followers?.filter((follower) => follower.userId === currentUser.userId).length > 0);
    }, [followers]);

    useEffect(() => {
        if (!subscribers) {
            setSubscribed(false);
            return;
        }

        const res = subscribers.filter((subscriber) => subscriber.userId === currentUser.userId);
        if (res.length > 0) {
            setSubscribed(true);
            setSubscription(res[0]);
        } else {
            setSubscribed(false);
            setSubscription(undefined);
        }
    }, [subscribers]);

    const setDonationDialogPosition = () => {
        const pos = donationButtonRef.current?.getBoundingClientRect();
        if (pos) {
            setDonationDialogX(
                isMobile
                    ? window.innerWidth / 2 - 160
                    : pos.left + pos.width + 320 > window.innerWidth
                    ? window.innerWidth - 330
                    : pos.left + pos.width,
            );

            if (isMobile) {
                setDonationDialogY(window.innerHeight / 2 - 325 / 2);
            } else if (pos.top + pos.height + 325 / 2 > window.innerHeight) {
                setDonationDialogY(window.innerHeight - 325);
            } else if (window.innerHeight - pos.top + pos.height + 325 / 2 > window.innerHeight) {
                setDonationDialogY(5);
            } else {
                setDonationDialogY(pos.top + pos.height - 200);
            }
        }
    };

    const handleDonationClick = () => {
        setDonationDialogPosition();
        setDonationDialogOpen(true);
    };

    const followUser = async () => {
        setFollowing(!following);

        await firestoreFollowers.addToFollowing(currentUser.userId, {
            userId: props.user.userId,
        });

        await firestoreFollowers.addToFollowers(props.user.userId, {
            userId: currentUser.userId,
        });
    };

    const handleFollowButtonClick = async () => {
        following ? setUnfollowDialogOpen(true) : await followUser();
    };

    const handleSubscribeButtonClick = () => {
        subscribed ? setUnsubscribeDialogOpen(true) : setSubscribeDialogOpen(true);
    };

    const buttonStyle: CSSProperties = {
        height: '30px',
        marginRight: isMobile ? '10px' : '1vw',
        borderRadius: '10px',
    };

    const buttonTextStyle: CSSProperties = {
        fontSize: isMobile ? '12px' : '15px',
    };

    const donateButtonStyle: CSSProperties = {
        background: 'linear-gradient(45deg, #FC0398, #FF6138)',
        boxShadow: '3px 3px 2px 0px rgba(255, 105, 135, .3)',
        color: 'white',
    };

    return (
        <Box display="flex" marginLeft={isMobile ? '0px' : '20px'}>
            {props.userStreamingData && (
                <Box>
                    {isUserOwner ? (
                        <Box>
                            {isStreamActive ? (
                                <LoadingButton
                                    style={{ ...buttonStyle, backgroundColor: palette['red'] }}
                                    isLoading={false}
                                    onClick={() => setEndStreamDialogOpen(true)}
                                    value={<Typography style={buttonTextStyle}> End Stream </Typography>}
                                />
                            ) : (
                                <Box position="relative">
                                    {!currentUser.isVerified ||
                                        (currentUserPrivateData.streamers.every((streamer) => !streamer.isVerified) && (
                                            <Tooltip
                                                placement="top"
                                                title={
                                                    !currentUser.isVerified
                                                        ? 'Please verify your email address before you can start streaming.'
                                                        : 'Your application to become a streamer is currently under review.'
                                                }
                                            >
                                                <Box width="100%" height="30px" position="absolute"></Box>
                                            </Tooltip>
                                        ))}
                                    <Button
                                        style={
                                            !currentUser.isVerified ||
                                            currentUserPrivateData.streamers.every((streamer) => !streamer.isVerified)
                                                ? { ...buttonStyle, backgroundColor: 'gray' }
                                                : {
                                                      ...buttonStyle,
                                                      backgroundColor: palette['secondary'],
                                                  }
                                        }
                                        onClick={() => setStaringStreamDialogOpen(true)}
                                        disabled={
                                            !currentUser.isVerified ||
                                            currentUserPrivateData.streamers.every((streamer) => !streamer.isVerified)
                                        }
                                    >
                                        <Typography style={buttonTextStyle}> Start streaming </Typography>
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    ) : (
                        <Box display="flex">
                            <Button
                                style={{
                                    ...buttonStyle,
                                    backgroundColor: palette['primary_l'],
                                    padding: isMobile ? '0px' : '5px 14px',
                                }}
                                onClick={() => {
                                    currentUser.isAnonymous
                                        ? setAuthenticationDialogOpen(true)
                                        : handleFollowButtonClick();
                                }}
                            >
                                <Typography style={buttonTextStyle}> {following ? 'Unfollow' : 'Follow'} </Typography>
                            </Button>
                            <Button
                                style={{
                                    ...buttonStyle,
                                    border: `2px solid ${palette['secondary']}`,
                                }}
                                onClick={() => {
                                    currentUser.isAnonymous
                                        ? setAuthenticationDialogOpen(true)
                                        : handleSubscribeButtonClick();
                                }}
                            >
                                {subscribed ? (
                                    <Box display="flex" style={{ alignItems: 'center' }}>
                                        <Typography style={{ ...buttonTextStyle, color: palette['secondary'] }}>
                                            Subscribed
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Typography
                                        style={{ ...buttonTextStyle, color: palette['secondary'], fontWeight: 700 }}
                                    >
                                        Subscribe
                                    </Typography>
                                )}
                            </Button>
                            <div ref={donationButtonRef}>
                                <Button
                                    style={{ ...buttonStyle, ...donateButtonStyle }}
                                    aria-controls="customized-menu"
                                    aria-haspopup="true"
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                        currentUser.isAnonymous
                                            ? setAuthenticationDialogOpen(true)
                                            : handleDonationClick()
                                    }
                                >
                                    <Typography id="donationButton" style={{ ...buttonTextStyle, fontWeight: 700 }}>
                                        Donate
                                    </Typography>
                                    <PinterestIcon style={{ fontSize: '20px', color: 'white', marginLeft: '1px' }} />
                                </Button>
                            </div>
                        </Box>
                    )}
                </Box>
            )}

            {isStreamActive && (
                <Box display="flex" margin="5px 10px 0px 0px">
                    <PersonIcon style={{ color: palette['red'] }} />
                    <Typography style={{ margin: '2px 0px 0px 5px', color: palette['red'] }}>
                        {getNumberInThousands(props.currentStream.viewersCount as number)}
                    </Typography>
                </Box>
            )}

            <AuthenticationDialog open={authenticationDialogOpen} setDialogState={setAuthenticationDialogOpen} />

            {isUserOwner && (
                <StartingStreamDialog
                    open={startingStreamDialogOpen}
                    setDialogState={setStaringStreamDialogOpen}
                    currentStream={props.currentStream}
                />
            )}
            {isUserOwner && (
                <StreamEndConfirmationDialog open={endStreamDialogOpen} setDialogState={setEndStreamDialogOpen} />
            )}

            {!currentUser.isAnonymous && (
                <UnfollowDialog
                    open={unfollowDialogOpen}
                    setDialogState={setUnfollowDialogOpen}
                    user={props.user}
                    setFollowing={setFollowing}
                />
            )}
            {!currentUser.isAnonymous && (
                <SubscribeDialog
                    isOpen={subscribeDialogOpen}
                    setDialogState={setSubscribeDialogOpen}
                    user={props.user}
                    userStreamingData={props.userStreamingData}
                />
            )}
            {!currentUser.isAnonymous && (
                <UnsubscribeDialog
                    isOpen={unsubscribeDialogOpen}
                    setDialogState={setUnsubscribeDialogOpen}
                    user={props.user}
                    userStreamingData={props.userStreamingData}
                    subscription={subscription as SubscriptionUser}
                />
            )}

            {!currentUser.isAnonymous && (
                <DonationDialog
                    open={donationDialogOpen}
                    setOpen={setDonationDialogOpen}
                    user={props.user}
                    donationDialogX={donationDialogX as number}
                    donationDialogY={donationDialogY as number}
                    subscriptionBadge={subscription && props.userStreamingData.subscription.badgeUrl}
                />
            )}
        </Box>
    );
};

export default ProfileHeaderButtons;
