import React, { CSSProperties, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { StreamPublicData } from '../../../models/Stream';
import { useMainWindowHost } from '../../../contexts/MainWindowHostContext';
import { Link } from 'react-router-dom';
import PinterestIcon from '@material-ui/icons/Pinterest';
import LensRoundedIcon from '@material-ui/icons/LensRounded';
import { palette } from '../../../styles/theme';
import { smoothShadow } from '../../../constants/Constants';
import { UserPublicData, UserStreamingData } from '../../../models/User';
import FirestoreUsers from '../../../firestore/FirestoreUsers';
import styles from '../../../styles/styles.module.css';
import { getNumberInThousands } from '../../../helpers/helpers';

interface WelcomePageActiveStreamProps {
    activeStream: StreamPublicData;
}

const WelcomePageActiveStream = (props: WelcomePageActiveStreamProps) => {
    const firestoreUsers = new FirestoreUsers();

    const { isMobile } = useMainWindowHost();
    const [activeStreamOwner, setActiveStreamOwner] = useState<UserPublicData & UserStreamingData>();

    useEffect(() => {
        if (!props.activeStream) return;

        const setActiveOwner = async () => {
            const ownerPublic = await firestoreUsers.getUserPublicInfo(props.activeStream.ownerId as string);
            const ownerStreamging = await firestoreUsers.getUserStreamingData(props.activeStream.ownerId as string);
            setActiveStreamOwner({ ...ownerPublic, ...ownerStreamging });
        };

        setActiveOwner();
    }, [props.activeStream]);

    const streamCardStyle: CSSProperties = {
        textDecoration: 'none',
        borderRadius: '20px',
        backgroundColor: palette['blue'],
        boxShadow: smoothShadow,
        margin: isMobile ? '0px 3vw 30px 0px' : '0px 50px 30px 0px',
        width: '25vw',
        minWidth: '200px',
        maxWidth: '300px',
        maxHeight: isMobile ? '180px' : '260px',
        height: isMobile ? '180px' : '260px',
    };

    const streamTagsContainerStyle: CSSProperties = {
        display: 'flex',
        overflow: 'hidden',
        borderRadius: '20px',
        marginTop: '5px',
    };

    const streamContentTagStyle: CSSProperties = {
        backgroundColor: palette['primary'],
        borderRadius: '20px',
        padding: '0px 10px',
        marginRight: '5px',
    };

    const streamScoreStyle: CSSProperties = {
        display: 'inline-flex',
        alignItems: 'center',
        borderRadius: '20px',
        border: `1px solid ${palette['primary']}`,
        padding: '1px 6px',
        height: isMobile ? '18px' : '22px',
    };

    const viewersCountStyle: CSSProperties = {
        alignItems: 'center',
        display: 'inline-flex',
        backgroundColor: 'rgba(41, 53, 78, 0.7)',
        borderRadius: '20px',
        padding: '1px 10px',
    };

    const lensIconStyle: CSSProperties = {
        fontSize: '15px',
        color: palette['red'],
        margin: '3px 5px 2px 0px',
    };

    const offlineStreamBoxStyle: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: isMobile ? '5px' : '10px',
        height: '100%',
        boxSizing: 'border-box',
    };

    const offlineInformationBoxStyle: CSSProperties = {
        width: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: palette['primary'],
        whiteSpace: 'nowrap',
        padding: isMobile ? '5px' : '5px 10px 10px 10px',
        boxSizing: 'border-box',
    };

    const displayUsernameStyle: CSSProperties = {
        fontSize: isMobile ? '15px' : '20px',
        fontWeight: 'bold',
    };

    const pointsLogoStyle: CSSProperties = {
        color: palette['text_p'],
        fontSize: '20px',
    };

    const amountReveivedStyle: CSSProperties = {
        marginRight: '2px',
        lineHeight: 1,
    };

    return (
        <Box style={streamCardStyle} className={styles.invisibleScroll}>
            <Link to={`/${activeStreamOwner?.username}`} style={{ textDecoration: 'none' }}>
                {props.activeStream.active ? (
                    <Box>
                        <Box style={{ height: '0px', position: 'relative', top: '10px', left: '10px' }}>
                            <Box style={viewersCountStyle}>
                                <LensRoundedIcon style={lensIconStyle} />
                                <Typography style={{ color: palette['red'] }}>
                                    {getNumberInThousands(props.activeStream.viewersCount as number)}
                                </Typography>
                            </Box>
                        </Box>
                        <img
                            src={props.activeStream.thumbnailUrl}
                            style={{
                                width: '100%',
                                borderRadius: '20px',
                            }}
                        />
                        <Box style={{ padding: isMobile ? '0px 5px 5px 5px' : '0px 10px 10px 5px' }}>
                            <Box
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <img
                                    src={activeStreamOwner?.profilePictureUrl}
                                    style={{ width: '7vw', maxWidth: '50px', minWidth: '30px' }}
                                />
                                <Box marginLeft={isMobile ? '5px' : '10px'} width="100%">
                                    <Box display="flex" justifyContent="space-between">
                                        <Typography style={displayUsernameStyle}>
                                            {activeStreamOwner?.displayUsername}
                                        </Typography>
                                        <Box style={streamScoreStyle}>
                                            <Typography style={amountReveivedStyle}>
                                                {getNumberInThousands(activeStreamOwner?.monthlyScore as number)}
                                            </Typography>
                                            <PinterestIcon style={pointsLogoStyle} />
                                        </Box>
                                    </Box>
                                    <Typography
                                        noWrap
                                        style={{
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            maxWidth: '225px',
                                            fontSize: isMobile ? '12px' : '15px',
                                            lineHeight: isMobile ? '12px' : '15px',
                                        }}
                                    >
                                        {props.activeStream.title}
                                    </Typography>
                                    <Box style={streamTagsContainerStyle}>
                                        {props.activeStream.content.map((el, idx) => (
                                            <Box key={idx} style={streamContentTagStyle}>
                                                <Typography>{el}</Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                ) : (
                    <Box style={offlineStreamBoxStyle}>
                        <img src={activeStreamOwner?.profilePictureUrl} width={isMobile ? '60px' : '100px'} />
                        <Box display="flex" alignItems="center">
                            <Typography style={displayUsernameStyle}>
                                {activeStreamOwner?.displayUsername}&nbsp;
                            </Typography>
                            <Box style={streamScoreStyle}>
                                <Typography style={amountReveivedStyle}>
                                    {getNumberInThousands(activeStreamOwner?.monthlyScore as number)}
                                </Typography>
                                <PinterestIcon style={pointsLogoStyle} />
                            </Box>
                        </Box>
                        <Box style={offlineInformationBoxStyle}>
                            <Typography style={{ fontWeight: 'bold', alignSelf: 'center' }}>
                                Currently offline
                            </Typography>
                            <Box>
                                <Typography style={{ color: palette['secondary'], lineHeight: 1 }}>
                                    Previous stream :&nbsp;
                                </Typography>
                                <Typography style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {props.activeStream.title}
                                </Typography>
                            </Box>
                            {/* <Typography
                                style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    fontSize: isMobile ? '12px' : '15px',
                                    lineHeight: isMobile ? '12px' : '16px',
                                }}
                            >
                                {props.activeStream.description}
                            </Typography> */}
                            <Box style={{ ...streamTagsContainerStyle, marginTop: isMobile ? '0px' : '5px' }}>
                                {props.activeStream.content.map((el, idx) => (
                                    <Box
                                        key={idx}
                                        style={{ ...streamContentTagStyle, backgroundColor: palette['primary_l'] }}
                                    >
                                        <Typography>{el}</Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                )}
            </Link>
        </Box>
    );
};

export default WelcomePageActiveStream;
