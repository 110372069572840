import React, { CSSProperties, useState } from 'react';
import { Box, Button, FormHelperText, IconButton, Typography } from '@material-ui/core';
import { palette } from '../../../../styles/theme';
import CloseIcon from '@material-ui/icons/Close';
import ProfileBannerEditorDialog from '../dialogs/ProfileBannerEditorDialog';
import { darkShadow } from '../../../../constants/Constants';
import { useMainWindowHost } from '../../../../contexts/MainWindowHostContext';

interface BannerProps {
    bannerWidth: number;
    bannerHeight: number;
    bannerUrl: string;
    baseBannerUrl: string;
    setBannerUrl: (profilePictureUrl: string) => void;
}

const Banner = (props: BannerProps) => {
    const { isMobile } = useMainWindowHost();

    const [openEditorDialog, setOpenEditorDialog] = useState(false);
    const [invalidImage, setInvalidImage] = useState(false);
    const [uneditedBannerUrl, setUneditedBannerUrl] = useState('');

    const handleNewBannerPicture = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const img: File = event.target.files[0];

            if (!img.type.startsWith('image') || img.size > 6000000) {
                setInvalidImage(true);
                return;
            }

            setInvalidImage(false);
            setUneditedBannerUrl(URL.createObjectURL(img));
            setOpenEditorDialog(true);
            event.target.value = '';
        }
    };

    const updateBannerButtonStyle: CSSProperties = {
        margin: isMobile ? '0px 0px 10px 0px' : '0px 0px 0px 40px',
        backgroundColor: palette['primary'],
        borderRadius: '20px',
        padding: '5px 10px',
    };

    const cancelBannerUpdateButton: CSSProperties = {
        float: 'right',
        color: palette['text_s'],
        fontSize: '20px',
        padding: '0',
        visibility: props.bannerUrl !== props.baseBannerUrl ? 'visible' : 'hidden',
    };

    const bannerStyle: CSSProperties = {
        width: isMobile ? '100%' : props.bannerWidth,
        borderRadius: '20px',
        boxShadow: darkShadow,
    };

    const noBannerStyle: CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '30vw',
        maxHeight: '180px',
    };

    const updateBannerButton = (
        <Button component="label" style={updateBannerButtonStyle}>
            Update banner
            <input
                accept="image/*"
                type="file"
                style={{ display: 'none' }}
                onChange={(event) => {
                    handleNewBannerPicture(event as React.ChangeEvent<HTMLInputElement>);
                }}
            />
        </Button>
    );

    return (
        <Box display="flex" flexWrap="wrap" marginBottom={isMobile ? '20px' : '60px'} alignItems="center">
            {isMobile && (
                <Box width="100%" display="flex" justifyContent="space-between">
                    {updateBannerButton}
                    <IconButton
                        style={cancelBannerUpdateButton}
                        onClick={() => props.setBannerUrl(props.baseBannerUrl)}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            )}

            {props.bannerUrl ? (
                <Box>
                    {!isMobile && (
                        <IconButton
                            style={cancelBannerUpdateButton}
                            onClick={() => props.setBannerUrl(props.baseBannerUrl)}
                        >
                            <CloseIcon />
                        </IconButton>
                    )}
                    <img src={props.bannerUrl} style={bannerStyle} />
                    <FormHelperText
                        style={{ color: palette['secondary'], visibility: invalidImage ? 'visible' : 'hidden' }}
                    >
                        *File must be of type image and be 6MB or less.
                    </FormHelperText>
                </Box>
            ) : (
                <Box
                    bgcolor="primary.main"
                    style={{
                        ...bannerStyle,
                        ...noBannerStyle,
                    }}
                >
                    <Typography>You do not have a banner yet!</Typography>
                </Box>
            )}
            {!isMobile && updateBannerButton}

            <ProfileBannerEditorDialog
                open={openEditorDialog}
                setDialogState={setOpenEditorDialog}
                profileBannerUrl={uneditedBannerUrl}
                setProfileBannerUrl={props.setBannerUrl}
                bannerWidth={props.bannerWidth}
                bannerHeight={props.bannerHeight}
            />
        </Box>
    );
};

export default Banner;
