import { Box, Link, Typography } from '@material-ui/core';
import React from 'react';
import { useAuth } from '../../contexts/AuthContext';

const VerifiedBar = () => {
    const { verifyEmailAddress } = useAuth();

    return (
        <Box
            style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#651432',
                justifyContent: 'center',
                padding: '0px 10px',
            }}
        >
            <Typography style={{ fontSize: '14px' }}>
                Check your email to verify your account. Then refresh the page.{' '}
                {
                    <Link
                        onClick={verifyEmailAddress}
                        color="inherit"
                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    >
                        Click here to resend
                    </Link>
                }
            </Typography>
        </Box>
    );
};

export default VerifiedBar;
