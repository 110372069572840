import { Box, Tooltip, Typography } from '@material-ui/core';
import React, { CSSProperties } from 'react';
import { colorSelection } from '../../../../constants/Constants';
import { Message } from '../../../../models/Chat';
import { palette } from '../../../../styles/theme';

interface TipRequestProps {
    message: Message;
}

const TipRequest = (props: TipRequestProps) => {
    const donationNameTitle: CSSProperties = {
        display: 'inline',
        fontSize: '18px',
        fontWeight: 600,
    };

    const donationPointsTitle: CSSProperties = {
        display: 'inline',
        fontWeight: 700,
        fontSize: '20px',
    };

    const chatMessageStyle: CSSProperties = {
        fontSize: '16px',
        wordWrap: 'break-word',
        display: 'inline',
    };

    const badgeStyle: CSSProperties = {
        width: '15px',
        height: '15px',
        marginRight: '5px',
        marginBottom: '-2px',
        display: 'inline',
    };

    const mainContainerStyle: CSSProperties = {
        backgroundColor: palette['primary_d'],
        margin: '10px 0px',
        padding: '10px',
        borderRadius: '15px',
        boxShadow: '2px 2px 10px rgba(0,0,0,0.7)',
    };

    const secondaryContainerStyle: CSSProperties = {
        backgroundColor: palette['primary_l'],
        margin: '10px 0px',
        padding: '10px',
        borderRadius: '15px',
    };

    return (
        <Tooltip title={props.message.time ? new Date(props.message.time.toDate()).toLocaleTimeString() : ''}>
            <Box style={mainContainerStyle}>
                <Box>
                    {props.message.badgeUrl && (
                        <img src={props.message.badgeUrl} style={{ ...badgeStyle, display: 'inline' }} />
                    )}
                    <Typography style={{ ...donationNameTitle, color: colorSelection[props.message.color] }}>
                        {props.message.username}&nbsp;
                    </Typography>
                    <Typography style={donationNameTitle}>tipped</Typography>
                </Box>
                <Box>
                    <Typography style={{ ...donationPointsTitle, color: colorSelection[props.message.color] }}>
                        {props.message.points}&nbsp;
                    </Typography>
                    <Typography style={donationPointsTitle}>Points for </Typography>
                </Box>
                <Box style={secondaryContainerStyle}>
                    <Typography style={chatMessageStyle}>{props.message.messages[0]}</Typography>
                </Box>
            </Box>
        </Tooltip>
    );
};

export default TipRequest;
