import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/database';

const app = firebase.apps.length
    ? firebase.app()
    : firebase.initializeApp({
          apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
          authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
          databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
          projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
          storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
          messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
          appId: process.env.REACT_APP_FIREBASE_APP_ID,
          measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
      });

// if (location.hostname === 'localhost') {
//     app.auth().useEmulator('http://localhost:9099');
//     app.firestore().useEmulator('localhost', 8080);
//     app.functions().useEmulator('192.168.0.2', 5001);
//     app.database().useEmulator('localhost', 9000);
//     app.storage().useEmulator('localhost', 9199);
// }

export const auth = app.auth();
export const storage = app.storage();
export const firestore = app.firestore();
export const functions = app.functions();
export const database = app.database();

export default app;
