import { Button, Dialog, DialogActions, DialogTitle, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useAuth } from '../../../../contexts/AuthContext';
import FirestoreLiveStreams from '../../../../firestore/FirestoreLiveStreams';
import { LoadingButton } from '../../../../styles/CustomComponents';

interface StreamEndConfirmationDialogProps {
    setDialogState: (isOpen: boolean) => void;
    open: boolean;
}

const StreamEndConfirmationDialog = (props: StreamEndConfirmationDialogProps) => {
    const { currentUser } = useAuth();
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        props.setDialogState(false);
    };

    const endStream = async (): Promise<void> => {
        setLoading(true);

        const firestoreLiveStreams: FirestoreLiveStreams = new FirestoreLiveStreams();

        await firestoreLiveStreams.deactivateStream(currentUser.userId);
        handleClose();

        setLoading(false);
    };

    return (
        <Dialog open={props.open} onClose={handleClose} PaperProps={{ style: { borderRadius: '10px' } }}>
            <DialogTitle>
                <Typography color="secondary" style={{ fontWeight: 700 }}>
                    Do you really want to end your stream?
                </Typography>
            </DialogTitle>

            <DialogActions>
                <LoadingButton onClick={() => endStream()} isLoading={loading} value="Yes" />
                <Button onClick={handleClose}>No</Button>
            </DialogActions>
        </Dialog>
    );
};

export default StreamEndConfirmationDialog;
