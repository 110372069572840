import { AxiosResponse } from 'axios';

export const executeResponseAction = (res: AxiosResponse | string, fail: () => void, success: () => void): void => {
    typeof res === 'string' || res.status < 200 || res.status >= 300 ? fail() : success();
};

export function timeSince(date: Date) {
    const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + ' years';
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + ' months';
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + ' days';
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + ' hours';
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + ' minutes';
    }

    return Math.floor(seconds) + ' seconds';
}

export function getNumberInThousands(amount: number) {
    if (!amount) return 0;
    if (amount < 1000) return amount;

    return `${(amount / 1000).toFixed(2)}k`;
}

export function getParamsFromUrl(url: string) {
    const params = url.split('&');

    const res = new Map<string, string>();

    for (const param of params) {
        const splitted = param.split('=');
        if (splitted.length < 2) continue;

        res.set(splitted[0], splitted[1]);
    }

    return res;
}
