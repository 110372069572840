import { firestore, functions } from '../Firebase';
import { StreamPublicData } from '../models/Stream';
import firebase from 'firebase/app';
import { UserViewer } from '../models/User';
import { firestoreCollections } from '../constants/Constants';
import { Message } from '../models/Chat';

export default class FirestoreLiveStreams {
    private readonly publicCollection = firestore.collection(firestoreCollections.livestreamsPublic);

    async createNewStream(userId: string, username: string, streamPublicData: StreamPublicData) {
        await this.publicCollection.doc(userId).set({
            ownerId: userId,
            ...streamPublicData,
        });

        const createLivestream = functions.httpsCallable('apiVideoFunctions-createLivestream');
        await createLivestream({ userId: userId, username: username });
    }

    async resetStreamKey(username: string, userId: string) {
        const createLivestream = functions.httpsCallable('apiVideoFunctions-createLivestream');
        await createLivestream({ userId: userId, username: username });
    }

    async setThumbnail(streamId: string, userId: string) {
        const generateThumbnail = functions.httpsCallable('apiVideoFunctions-generateThumbnail');
        await generateThumbnail({ userId: userId, streamId: streamId });
    }

    async setLivestreamInfo(streamInfo: StreamPublicData, userId: string) {
        await this.publicCollection.doc(userId).update(streamInfo);
    }

    async activateStream(streamId: string): Promise<StreamPublicData> {
        await this.publicCollection.doc(streamId).update({
            active: true,
            startTime: firebase.firestore.FieldValue.serverTimestamp(),
        });

        return (await this.publicCollection.doc(streamId).get()).data() as StreamPublicData;
    }

    async deactivateStream(streamId: string): Promise<void> {
        await this.publicCollection.doc(streamId).update({
            active: false,
        });
    }

    async addNewChatMessage(streamId: string, message: Message): Promise<void> {
        const time = firebase.firestore.FieldValue.serverTimestamp();
        message.time = time as firebase.firestore.Timestamp;
        await this.publicCollection.doc(streamId).collection(firestoreCollections.chat).add(message);
    }

    async addSubMessageToDonation(data: Message, chatElementId: string, streamId: string): Promise<void> {
        data.time = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
        this.publicCollection.doc(streamId).collection(firestoreCollections.chat).doc(chatElementId).update(data);
    }

    async addSelfToStreamViewers(streamId: string, user: UserViewer): Promise<void> {
        await this.publicCollection.doc(streamId).collection(firestoreCollections.viewers).doc(user.userId).set(user);
    }

    async removeSelfToStreamViewers(streamId: string, userId: string): Promise<void> {
        await this.publicCollection.doc(streamId).collection(firestoreCollections.viewers).doc(userId).delete();
    }
}
