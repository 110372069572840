import { Box, FormHelperText, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { CSSProperties, useState } from 'react';
import { Input } from '../../../styles/CustomComponents';
import { palette } from '../../../styles/theme';
import { SignUpFormFields } from './AuthenticationDialog';
import styles from '../../../styles/styles.module.css';

interface SignUpFormProps {
    setEmailAlreadyUsed: (emailAlreadyUsed: boolean) => void;
    setInvalidEmailFormat: (invalidEmailFormat: boolean) => void;
    setUsernameNotAvailable: (usernameNotAvailable: boolean) => void;
    setSignUpForm: React.Dispatch<React.SetStateAction<SignUpFormFields>>;
    signUpForm: SignUpFormFields;
    emailAlreadyUsed: boolean;
    invalidEmailFormat: boolean;
    usernameNotAvailable: boolean;
}

const SignUpForm = (props: SignUpFormProps) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordsDontMatch, setPasswordsDontMatch] = useState(false);
    const [passwordTooShort, setPasswordTooShort] = useState(false);
    const [usernameTooShort, setUsernameTooShort] = useState(false);

    const handleUsernameChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const re = /^[A-Za-z0-9_]+$/;
        if (event.target.value.length <= 20 && (event.target.value === '' || re.test(event.target.value))) {
            props.setSignUpForm((prev) => ({ ...prev, username: event.target.value }));
            setUsernameTooShort(event.target.value.length > 0 && event.target.value.length < 4);
            props.setUsernameNotAvailable(false);
        }
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        if (event.target.value.length <= 50) {
            const newPassword = event.target.value;
            props.setSignUpForm((prev) => ({ ...prev, password: newPassword }));
            setPasswordTooShort(newPassword.length > 0 && newPassword.length < 6);
            setPasswordsDontMatch(props.signUpForm.passwordConfirm !== newPassword);
        }
    };

    const handlePasswordConfirmChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        if (event.target.value.length <= 50) {
            props.setSignUpForm((prev) => ({ ...prev, passwordConfirm: event.target.value }));
            setPasswordsDontMatch(props.signUpForm.password !== event.target.value);
        }
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        props.setSignUpForm((prev) => ({ ...prev, email: event.target.value }));
        props.setInvalidEmailFormat(false);
        props.setEmailAlreadyUsed(false);
    };

    const dialogContentTextStyle: CSSProperties = {
        margin: '0',
        fontSize: '16px',
        fontWeight: 600,
        color: palette['text_p'],
        marginTop: '8px',
    };

    const textFieldsStyle: CSSProperties = {
        width: '100%',
    };

    const visibilityIconStyle: CSSProperties = {
        cursor: 'pointer',
        color: palette['text_p'],
    };

    return (
        <Box>
            <Typography style={dialogContentTextStyle}>Username</Typography>
            <Input
                style={textFieldsStyle}
                autoComplete="none"
                type="text"
                value={props.signUpForm.username}
                onChange={(event) => handleUsernameChange(event)}
            />
            <FormHelperText
                style={{
                    color: palette['red'],
                    visibility: props.usernameNotAvailable || usernameTooShort ? 'visible' : 'hidden',
                }}
            >
                {props.usernameNotAvailable
                    ? '*This username is already used by another account'
                    : '*Username must be between 4 and 20 characters'}
            </FormHelperText>

            <Typography style={dialogContentTextStyle}>Password</Typography>
            <Input
                style={textFieldsStyle}
                type={showPassword ? 'text' : 'password'}
                value={props.signUpForm.password}
                onChange={(event) => handlePasswordChange(event)}
                endIcon={
                    showPassword ? (
                        <VisibilityOff style={visibilityIconStyle} onClick={() => setShowPassword(!showPassword)} />
                    ) : (
                        <Visibility style={visibilityIconStyle} onClick={() => setShowPassword(!showPassword)} />
                    )
                }
            />
            <FormHelperText style={{ color: palette['red'], visibility: passwordTooShort ? 'visible' : 'hidden' }}>
                *Password must be between 6 and 50 characters
            </FormHelperText>

            <Typography style={dialogContentTextStyle}>Confirm Password</Typography>

            <Input
                style={textFieldsStyle}
                type={showConfirmPassword ? 'text' : 'password'}
                value={props.signUpForm.passwordConfirm}
                onChange={(event) => handlePasswordConfirmChange(event)}
                endIcon={
                    showConfirmPassword ? (
                        <VisibilityOff
                            style={visibilityIconStyle}
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        />
                    ) : (
                        <Visibility
                            style={visibilityIconStyle}
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        />
                    )
                }
            />
            <FormHelperText style={{ color: palette['red'], visibility: passwordsDontMatch ? 'visible' : 'hidden' }}>
                *Passwords do not match
            </FormHelperText>

            <Typography style={dialogContentTextStyle}>Email</Typography>
            <Input
                style={textFieldsStyle}
                autoComplete="email"
                type="email"
                onChange={(event) => handleEmailChange(event)}
            />
            <FormHelperText
                style={{ color: palette['red'], visibility: props.invalidEmailFormat ? 'visible' : 'hidden' }}
            >
                *Invalid email format
            </FormHelperText>
            {props.emailAlreadyUsed && (
                <FormHelperText style={{ color: palette['red'] }}>
                    *This email is already used by another account
                </FormHelperText>
            )}
            <Box style={{ textAlign: 'center', marginTop: '10px' }} className={styles.inline}>
                <Typography style={{ fontSize: '13px' }}>
                    By clicking Sign Up, you are indicating that you have read and acknowledged the&nbsp;
                </Typography>
                <Typography
                    style={{
                        fontSize: '13px',
                        textDecoration: 'underline',
                        color: palette['secondary'],
                        cursor: 'pointer',
                    }}
                >
                    Terms of Service
                </Typography>
                <Typography style={{ fontSize: '13px' }}>.</Typography>
            </Box>
        </Box>
    );
};

export default SignUpForm;
