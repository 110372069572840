import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, Typography } from '@material-ui/core';
import { CompletedPaypalOrder } from '../../../../../models/Transaction';
import FirestoreTransactions from '../../../../../firestore/FirestoreTransactions';
import { palette } from '../../../../../styles/theme';
import { paypalTransactionTypes } from '../../../../../constants/Constants';
import { CSSProperties } from '@material-ui/styles';
import FirestoreUsers from '../../../../../firestore/FirestoreUsers';
import { Link } from 'react-router-dom';
import { LoadingPage } from '../../../../../styles/CustomComponents';
import { useMainWindowHost } from '../../../../../contexts/MainWindowHostContext';
import { useAuth } from '../../../../../contexts/AuthContext';

const WalletTab = () => {
    const firestoreTransactions: FirestoreTransactions = new FirestoreTransactions();
    const firestoreUsers: FirestoreUsers = new FirestoreUsers();

    const { isMobile } = useMainWindowHost();
    const { currentUserPrivateData } = useAuth();

    const [transactions, setTransactions] = useState<CompletedPaypalOrder[]>();
    const [subscriptions, setSubscriptions] = useState<CompletedPaypalOrder[]>();
    const [subscriptionUsers, setSubscriptionUsers] = useState<Map<string, string>>();

    const [loadingTransactions, setLoadingTransactions] = useState(true);
    const [loadingSubscriptionUsers, setLoadingSubscriptionUsers] = useState(true);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function getTransactions() {
            if (!currentUserPrivateData.transactions) return;

            const newTransactions = [];
            const newSubscriptions = [];
            for (const transactionId of currentUserPrivateData.transactions) {
                const transaction = (await firestoreTransactions.getTransaction(transactionId)) as CompletedPaypalOrder;

                transaction?.type === paypalTransactionTypes.subscription
                    ? newSubscriptions.push(transaction)
                    : newTransactions.push(transaction);
            }

            setTransactions(newTransactions);
            setSubscriptions(newSubscriptions);
            setLoadingTransactions(false);
        }

        getTransactions();
    }, [currentUserPrivateData.transactions]);

    useEffect(() => {
        async function getSubscriptionUsers() {
            if (!subscriptions) return;

            const newSubscriptionUsers = new Map<string, string>();
            for (const subscription of subscriptions) {
                const subscriptionUsername = (
                    await firestoreUsers.getUserPublicInfo(subscription.subscribedToId as string)
                ).displayUsername;
                newSubscriptionUsers.set(subscription.paypalId, subscriptionUsername);
            }

            setSubscriptionUsers(newSubscriptionUsers);
            setLoadingSubscriptionUsers(false);
        }

        getSubscriptionUsers();
    }, [subscriptions]);

    useEffect(() => {
        setLoading(loadingSubscriptionUsers || loadingTransactions);
    }, [loadingSubscriptionUsers, loadingTransactions]);

    const transactionBoxStyle: CSSProperties = {
        backgroundColor: palette['primary'],
        borderRadius: '10px',
        padding: isMobile ? '10px 20px' : '15px 40px',
        marginBottom: '20px',
        width: isMobile ? 'auto' : '40%',
        minWidth: isMobile ? '0px' : '300px',
    };

    const dividerStyle: CSSProperties = {
        backgroundColor: palette['primary'],
        height: '2px',
        marginBottom: '25px',
    };

    return loading ? (
        <Box height="50vh">
            <LoadingPage />
        </Box>
    ) : (
        <Box>
            <Typography> Balance </Typography>
            <Divider style={dividerStyle} />
            <Box style={{ ...transactionBoxStyle, marginBottom: '50px' }}>
                <Typography>Points received from donations : {currentUserPrivateData.pointsAmount} points</Typography>
                <Typography>Amount received from subscriptions : ${currentUserPrivateData.pointsAmount}</Typography>
                <Box display="flex" alignItems="center" marginTop="20px" justifyContent="space-between">
                    <Typography>Your balance : ${currentUserPrivateData.balance}</Typography>
                    <Button style={{ backgroundColor: palette['secondary'], height: '25px' }}>Claim</Button>
                </Box>
            </Box>

            <Typography> Subscriptions </Typography>
            <Divider style={dividerStyle} />
            <Box marginBottom="50px">
                {subscriptions && subscriptions.length ? (
                    subscriptions.map((subscription, idx) => (
                        <Box key={idx} style={transactionBoxStyle}>
                            <Box>
                                <Typography style={{ marginBottom: '15px' }}>
                                    {subscription.createTime?.toDate().toDateString()},{' '}
                                    {subscription.createTime?.toDate().toLocaleTimeString()}
                                </Typography>
                                <Typography display="inline">Subscribed to </Typography>
                                <Link
                                    to={`/${subscriptionUsers?.get(subscription.paypalId)}`}
                                    style={{ textDecoration: 'none' }}
                                >
                                    <Typography display="inline" color="secondary">
                                        {subscriptionUsers?.get(subscription.paypalId)}
                                    </Typography>
                                </Link>
                                <Typography>Price: ${subscription.price}/month</Typography>
                            </Box>
                        </Box>
                    ))
                ) : (
                    <Box style={transactionBoxStyle}>
                        <Typography>You do not have any transactions</Typography>
                    </Box>
                )}
            </Box>

            <Typography> Transaction </Typography>
            <Divider style={dividerStyle} />
            {transactions && transactions.length ? (
                transactions.map((transaction, idx) => (
                    <Box key={idx} style={transactionBoxStyle}>
                        <Box>
                            <Typography style={{ marginBottom: '15px' }}>
                                {transaction.createTime?.toDate().toDateString()},{' '}
                                {transaction.createTime?.toDate().toLocaleTimeString()}
                            </Typography>
                            <Typography display="inline">Subscribed to </Typography>
                            <Link
                                to={`/${subscriptionUsers?.get(transaction.paypalId)}`}
                                style={{ textDecoration: 'none' }}
                            >
                                <Typography display="inline" color="secondary">
                                    {subscriptionUsers?.get(transaction.paypalId)}
                                </Typography>
                            </Link>
                            <Typography>Price: ${transaction.price}/month</Typography>
                        </Box>
                    </Box>
                ))
            ) : (
                <Box style={transactionBoxStyle}>
                    <Typography>You do not have any transactions</Typography>
                </Box>
            )}
        </Box>
    );
};

export default WalletTab;
