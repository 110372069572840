import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { palette } from '../../../styles/theme';

const Contact = () => {
    return (
        <Box>
            <Typography>
                For inqueries of any type by either users, clients or partners, please contact us at{' '}
                <a href="mailto:contact@playerz.tv" style={{ color: palette['secondary'] }}>
                    contact@playerz.tv
                </a>
                .
            </Typography>
        </Box>
    );
};

export default Contact;
