import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { Box, Button, Divider, IconButton, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import SearchBarComponent from './SearchBar';
import { Link } from 'react-router-dom';
import AuthenticationDialog from './authentication/AuthenticationDialog';
import { useAuth } from '../../contexts/AuthContext';
import { palette } from '../../styles/theme';
import StoreDialog from './store_page/StoreDialog';
import { firestore } from '../../Firebase';
import { firestoreCollections, notificationReplacements } from '../../constants/Constants';
import { Notification } from '../../models/User';
import PinterestIcon from '@material-ui/icons/Pinterest';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import FirestoreUsers from '../../firestore/FirestoreUsers';
import { getNumberInThousands, timeSince } from '../../helpers/helpers';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useMainWindowHost } from '../../contexts/MainWindowHostContext';
import SearchIcon from '@material-ui/icons/Search';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import InfoIcon from '@material-ui/icons/Info';
import playerz_logo from '../../images/playerz_logo.svg';

const NavBarComponent = () => {
    const firestoreUsers: FirestoreUsers = new FirestoreUsers();
    const { currentUser, currentUserPrivateData, currentUserStreamingData, signOut } = useAuth();
    const { isMobile } = useMainWindowHost();

    const [openAuthenticationModal, setOpenAuthenticationModal] = useState(false);
    const [openStoreModal, setOpenStoreModal] = useState(false);
    const [searchBarVisible, setSearchBarVisible] = useState(!isMobile);
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [notificationAnchorEl, setNotificationAnchorEl] = useState<null | HTMLElement>(null);
    const [unseenNotificationCount, setUnseenNotificationCount] = useState(0);
    const [filledNotifications, setFilledNotifications] = useState<Notification[]>();

    const profilePictureRef = useRef<HTMLDivElement>(null);

    const [notifications, setNotifications] = useState<Notification[]>();

    useEffect(() => {
        if (!currentUser) return;

        const sub = firestore
            .collection(firestoreCollections.userPrivate)
            .doc(currentUser.userId)
            .collection(firestoreCollections.notifications)
            .orderBy('timestamp', 'desc')
            .limit(20)
            .onSnapshot((snap) => {
                setNotifications(snap.docs.map((doc) => doc.data() as Notification));
            });

        return sub;
    }, [currentUser]);

    useEffect(() => {
        setSearchBarVisible(!isMobile);
    }, [isMobile]);

    useEffect(() => {
        if (!notifications) return;

        async function setNotificationsUserInfo(notifications: Notification[]) {
            const newNotifications = [];
            for (const notif of notifications) {
                const newNotification = Object.assign({}, notif);

                const user = await firestoreUsers.getUserPublicInfo(notif.userId);
                if (!user) continue;

                newNotification.profilePictureUrl = user.profilePictureUrl;
                newNotification.link = newNotification.link.replace(notificationReplacements.username, user.username);
                newNotification.message = newNotification.message.replace(
                    notificationReplacements.displayUsername,
                    user.displayUsername,
                );

                newNotifications.push(newNotification);
            }

            setFilledNotifications(newNotifications);
        }

        setNotificationsUserInfo(notifications);
        setUnseenNotificationCount(notifications.filter((notif) => !notif.seen).length);
    }, [notifications]);

    const signUserOut = async (): Promise<void> => {
        await signOut();
        setMenuAnchorEl(null);
    };

    const handleNotificationIconClick = async (): Promise<void> => {
        setNotificationAnchorEl(profilePictureRef.current);
        setUnseenNotificationCount(0);
        await firestoreUsers.setAllNotificationsToSeen(currentUser.userId);
    };

    const notificationCountButtonStyle: CSSProperties = {
        minWidth: '8px',
        width: '8px',
        height: '10px',
        borderRadius: '10px',
        backgroundColor: palette['red'],
        margin: '5px 10px 0px -15px',
    };

    const profileIconsStyle: CSSProperties = {
        fontSize: '30',
    };

    const profilePictureStyle: CSSProperties = {
        height: '35px',
        width: '35px',
        borderRadius: '20px',
    };

    const profilePictureNotificationStyle: CSSProperties = {
        height: '45px',
        width: '45px',
        borderRadius: '15px',
    };

    const buyButtonStyle: CSSProperties = {
        background: 'linear-gradient(30deg, #FC0398, #FF6138)',
        border: 0,
        borderRadius: 10,
        boxShadow: '4px 4px 6px 0px rgba(254, 55, 82, .3)',
        color: 'white',
        height: '30px',
        padding: isMobile ? '0px' : '5px',
        marginLeft: isMobile ? '0px' : '15px',
    };

    const linkStyle: CSSProperties = {
        textDecoration: 'none',
    };

    const navBarStyle: CSSProperties = {
        display: 'flex',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        padding: '0px 20px',
        alignItems: 'center',
        position: 'fixed',
        top: '5px',
        zIndex: 100,
        backgroundColor: palette['primary_l'],
        borderRadius: '20px',
        boxShadow: '5px 5px 8px rgba(0, 0, 0, 0.4)',
        height: isMobile ? '45px' : '55px',
        width: isMobile ? '100%' : 'calc(100% - 20px)',
        margin: isMobile ? '0px' : '0px 10px',
    };

    const logoStyle: CSSProperties = {
        color: palette['secondary'],
        fontWeight: 800,
        fontSize: '27px',
        marginRight: '5px',
    };

    const searchBarContainerStyle: CSSProperties = {
        width: isMobile ? '100%' : '20%',
        display: 'flex',
        alignItems: 'center',
    };

    const centerSearchBar: CSSProperties = {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
    };

    const menuStyle: CSSProperties = {
        top: '345px',
        margin: isMobile ? '35px 0px 0px 15px' : '45px 0px 0px 6px',
        width: '200px',
    };

    return (
        <Box style={navBarStyle}>
            {isMobile ? (
                <Link to="/" style={{}}>
                    <Typography style={logoStyle}>P</Typography>
                </Link>
            ) : (
                <img src={playerz_logo} style={{ height: '80%' }} />
            )}
            <Box style={isMobile ? searchBarContainerStyle : { ...searchBarContainerStyle, ...centerSearchBar }}>
                {isMobile && searchBarVisible && (
                    <ArrowForwardIosIcon
                        style={{ color: palette['text_p'], fontSize: '15px', margin: '0px 0px 0px 50px' }}
                        onClick={() => setSearchBarVisible(false)}
                    />
                )}
                {searchBarVisible && <SearchBarComponent />}
            </Box>

            {isMobile && !searchBarVisible && (
                <SearchIcon
                    style={{ color: palette['text_p'] }}
                    onClick={() => setSearchBarVisible(!searchBarVisible)}
                />
            )}

            {isMobile && currentUser.isAnonymous && !searchBarVisible && (
                <Link to="/help/about">
                    <InfoIcon style={{ color: palette['text_p'], margin: '4px 0px 0px 8px' }} />
                </Link>
            )}

            {currentUser && (
                <Box>
                    {(!searchBarVisible || !isMobile) && (
                        <Box display="flex" alignItems="center">
                            {!currentUser.isAnonymous && (
                                <Box display="flex">
                                    <IconButton
                                        style={{ color: palette['text_p'], padding: isMobile ? '8px' : '10px' }}
                                        onClick={() => handleNotificationIconClick()}
                                    >
                                        <NotificationsIcon fontSize="small" />
                                    </IconButton>
                                    {unseenNotificationCount > 0 && (
                                        <Button style={notificationCountButtonStyle}>
                                            <Typography style={{ fontSize: '10px' }}>
                                                {unseenNotificationCount}
                                            </Typography>
                                        </Button>
                                    )}
                                </Box>
                            )}

                            {currentUserPrivateData && (
                                <Tooltip title="Buy Points">
                                    <Button
                                        disableElevation
                                        style={buyButtonStyle}
                                        onClick={() => {
                                            setOpenStoreModal(true);
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                paddingRight: '3px',
                                                fontSize: isMobile ? '16px' : '18px',
                                                fontWeight: 500,
                                            }}
                                        >
                                            {currentUserPrivateData.pointsAmount == 0
                                                ? 'Get'
                                                : getNumberInThousands(currentUserPrivateData.pointsAmount)}
                                        </Typography>
                                        <PinterestIcon style={{ color: palette['text_p'] }}></PinterestIcon>
                                    </Button>
                                </Tooltip>
                            )}

                            <div
                                ref={profilePictureRef}
                                onClick={(event) => {
                                    currentUser.isAnonymous
                                        ? setOpenAuthenticationModal(true)
                                        : setMenuAnchorEl(event.currentTarget);
                                }}
                                style={{ borderRadius: '40px', marginLeft: isMobile ? '8px' : '15px' }}
                            >
                                <IconButton style={{ padding: '0px' }}>
                                    {currentUser.profilePictureUrl ? (
                                        <img src={currentUser.profilePictureUrl} style={profilePictureStyle} />
                                    ) : (
                                        <AccountCircle color="secondary" style={profileIconsStyle} />
                                    )}
                                </IconButton>
                            </div>
                        </Box>
                    )}

                    <Menu
                        anchorEl={menuAnchorEl}
                        keepMounted
                        open={Boolean(menuAnchorEl)}
                        onClose={() => setMenuAnchorEl(null)}
                        PaperProps={{
                            style: menuStyle,
                        }}
                    >
                        <Box padding="8px 16px" display="flex" alignItems="center">
                            {currentUser.profilePictureUrl ? (
                                <img
                                    src={currentUser.profilePictureUrl}
                                    style={{ ...profilePictureStyle, marginRight: '10px' }}
                                />
                            ) : (
                                <AccountCircle color="secondary" style={profileIconsStyle} />
                            )}
                            <Box>
                                <Typography style={{ fontWeight: 'bold' }}>{currentUser?.displayUsername}</Typography>
                            </Box>
                        </Box>
                        <Divider variant="middle" style={{ backgroundColor: palette['primary'] }} />
                        <Link
                            to={currentUserStreamingData ? `/${currentUser.username}` : '/settings/becomeastreamer'}
                            style={linkStyle}
                        >
                            <MenuItem
                                onClick={() => setMenuAnchorEl(null)}
                                style={{
                                    backgroundColor: palette['secondary'],
                                    borderRadius: '10px',
                                    margin: '5px 15px',
                                    height: '30px',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography style={{ fontWeight: 'bold' }}>
                                    {currentUserStreamingData ? 'Start streaming' : 'Become a streamer'}
                                </Typography>
                            </MenuItem>
                        </Link>
                        <Divider variant="middle" style={{ backgroundColor: palette['primary'] }} />

                        <Link to={`/${currentUser?.username}`} style={linkStyle}>
                            <MenuItem onClick={() => setMenuAnchorEl(null)}>
                                <PersonIcon style={{ marginRight: '10px' }} />
                                <Typography>Your channel</Typography>
                            </MenuItem>
                        </Link>
                        <Link to="/settings/account" style={linkStyle}>
                            <MenuItem onClick={() => setMenuAnchorEl(null)}>
                                <SettingsIcon style={{ marginRight: '10px' }} />
                                <Typography>Settings</Typography>
                            </MenuItem>
                        </Link>
                        {isMobile && (
                            <Link to="/help/about" style={linkStyle}>
                                <MenuItem onClick={() => setMenuAnchorEl(null)}>
                                    <InfoIcon style={{ marginRight: '10px' }} />
                                    <Typography>About Playerz</Typography>
                                </MenuItem>
                            </Link>
                        )}
                        <Link to="/" style={linkStyle}>
                            <MenuItem onClick={() => signUserOut()}>
                                <ExitToAppIcon style={{ marginRight: '10px' }} />
                                <Typography>Log Out</Typography>
                            </MenuItem>
                        </Link>
                    </Menu>

                    <Menu
                        anchorEl={notificationAnchorEl}
                        keepMounted
                        open={Boolean(notificationAnchorEl)}
                        onClose={() => setNotificationAnchorEl(null)}
                        PaperProps={{
                            style: { ...menuStyle, width: '400px', position: 'fixed' },
                        }}
                    >
                        <Box>
                            <Typography
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: '18px',
                                    margin: '0px 10px 10px 20px',
                                    color: palette['secondary'],
                                }}
                            >
                                Notifications
                            </Typography>
                            <Divider
                                variant="middle"
                                style={{ marginBottom: '10px', backgroundColor: palette['primary_d'] }}
                            />
                        </Box>
                        {filledNotifications?.length ? (
                            filledNotifications?.map((notif, idx) => (
                                <Link key={idx} to={notif.link} style={{ ...linkStyle, display: 'flex' }}>
                                    <MenuItem onClick={() => setNotificationAnchorEl(null)}>
                                        <img src={notif.profilePictureUrl} style={profilePictureNotificationStyle} />
                                        <Box marginLeft="10px" whiteSpace="break-spaces">
                                            <Typography style={{ lineHeight: 1 }}>{notif.message}</Typography>
                                            <Typography style={{ fontSize: '11px', color: palette['secondary'] }}>
                                                {`${timeSince(notif.timestamp?.toDate() as Date)} ago`}
                                            </Typography>
                                        </Box>
                                    </MenuItem>
                                </Link>
                            ))
                        ) : (
                            <Box textAlign="center" padding="0px 10px 0px 10px">
                                <SentimentVeryDissatisfiedIcon color="secondary" style={{ fontSize: '35px' }} />
                                <Typography> You do not have any notifications at the moment! </Typography>
                            </Box>
                        )}
                    </Menu>

                    <StoreDialog setDialogState={setOpenStoreModal} open={openStoreModal} />
                    <AuthenticationDialog setDialogState={setOpenAuthenticationModal} open={openAuthenticationModal} />
                </Box>
            )}
        </Box>
    );
};

export default NavBarComponent;
