import { Box, CircularProgress, Input, InputAdornment, Tooltip, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { CSSProperties, useState } from 'react';
import { palette } from '../../../../../styles/theme';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useMainWindowHost } from '../../../../../contexts/MainWindowHostContext';

interface StartYourStreamTabProps {
    streamKey: string;
    streamManifestStatus: boolean;
    broadcasting: boolean;
    handleResetStreamkey: () => void;
}

const StartYourStreamTab = (props: StartYourStreamTabProps) => {
    const { isMobile } = useMainWindowHost();

    const [showStreamKey, setShowStreamKey] = useState(false);
    const [copiedStreamKey, setCopiedStreamKey] = useState(false);
    const [copiedServerUrl, setCopiedServerUrl] = useState(false);

    const streamKeyTextStyle: CSSProperties = {
        marginRight: '10px',
        flexGrow: 1,
        marginLeft: isMobile ? '0px' : '10px',
        width: isMobile ? '100%' : 'auto',
    };

    const inlineTextBoxStyle: CSSProperties = {
        display: 'flex',
        marginTop: '20px',
    };

    const waitingBoxStyle: CSSProperties = {
        margin: '80px 0px 60px 0px',
        textAlign: 'center',
    };

    const waitinProgressgStyle: CSSProperties = {
        color: palette['text_p'],
        marginTop: '10px',
    };

    return (
        <Box>
            <Box style={{}}>
                {props.streamManifestStatus ? (
                    <Box>
                        {props.broadcasting ? (
                            <Box style={waitingBoxStyle}>
                                <Typography style={{ fontSize: '18px', fontWeight: 500 }}>
                                    Your stream is ready!
                                </Typography>
                            </Box>
                        ) : (
                            <Box style={waitingBoxStyle}>
                                <Typography>We are closing your previous stream.</Typography>
                                <Box>
                                    <Typography style={{ display: 'inline' }}>
                                        Please wait a few minutes or&nbsp;
                                    </Typography>
                                    <Typography
                                        style={{
                                            display: 'inline',
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                            color: palette['secondary'],
                                            fontWeight: 600,
                                        }}
                                        onClick={() => {
                                            props.handleResetStreamkey();
                                        }}
                                    >
                                        reset your stream key.
                                    </Typography>
                                </Box>

                                <CircularProgress size={20} style={waitinProgressgStyle} />
                            </Box>
                        )}
                    </Box>
                ) : (
                    <Box>
                        {props.broadcasting ? (
                            <Box style={waitingBoxStyle}>
                                <Typography>We have detected your broadcast.</Typography>
                                <Typography> Your stream will be ready soon.</Typography>
                                <CircularProgress size={20} style={waitinProgressgStyle} />
                            </Box>
                        ) : (
                            <Box>
                                <Typography style={inlineTextBoxStyle}>
                                    To start your stream, use the following stream key and server URL in your
                                    broadcasting software.
                                </Typography>
                                <Box style={{ display: isMobile ? 'block' : 'flex', marginTop: '20px' }}>
                                    <Box display="flex">
                                        <Typography>Your</Typography>&nbsp;
                                        <Typography style={{ fontWeight: 600, color: palette['secondary'] }}>
                                            stream key
                                        </Typography>
                                        &nbsp;
                                        <Typography>is :</Typography>
                                    </Box>
                                    <Input
                                        style={streamKeyTextStyle}
                                        type={showStreamKey ? 'text' : 'password'}
                                        value={props.streamKey}
                                        readOnly={true}
                                        endAdornment={
                                            <Box style={{ display: 'flex' }}>
                                                <InputAdornment position="end">
                                                    {showStreamKey ? (
                                                        <Visibility
                                                            style={{ cursor: 'pointer' }}
                                                            color="secondary"
                                                            onClick={() => {
                                                                setShowStreamKey(!showStreamKey);
                                                            }}
                                                        />
                                                    ) : (
                                                        <VisibilityOff
                                                            style={{ cursor: 'pointer' }}
                                                            color="secondary"
                                                            onClick={() => {
                                                                setShowStreamKey(!showStreamKey);
                                                            }}
                                                        />
                                                    )}
                                                </InputAdornment>
                                                <InputAdornment position="end">
                                                    <Tooltip title={copiedStreamKey ? 'Copied!' : 'Copy to clipboard'}>
                                                        <FileCopyIcon
                                                            style={{ cursor: 'pointer' }}
                                                            color="secondary"
                                                            onClick={() => {
                                                                navigator.clipboard.writeText(
                                                                    props.streamKey as string,
                                                                );
                                                                setCopiedStreamKey(true);
                                                            }}
                                                        ></FileCopyIcon>
                                                    </Tooltip>
                                                </InputAdornment>
                                            </Box>
                                        }
                                    />
                                </Box>
                                <Box style={inlineTextBoxStyle}>
                                    <Typography>The</Typography>&nbsp;
                                    <Typography style={{ fontWeight: 600, color: palette['secondary'] }}>
                                        server URL
                                    </Typography>
                                    &nbsp;
                                    <Typography>is :</Typography>&nbsp;
                                    {!isMobile && (
                                        <Input
                                            style={{ ...streamKeyTextStyle, marginBottom: '20px' }}
                                            type={'text'}
                                            value="rtmp://broadcast.api.video/s"
                                            readOnly={true}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <Tooltip title={copiedServerUrl ? 'Copied!' : 'Copy to clipboard'}>
                                                        <FileCopyIcon
                                                            style={{ cursor: 'pointer' }}
                                                            color="secondary"
                                                            onClick={() => {
                                                                navigator.clipboard.writeText(
                                                                    'rtmp://broadcast.api.video/s',
                                                                );
                                                                setCopiedServerUrl(true);
                                                            }}
                                                        ></FileCopyIcon>
                                                    </Tooltip>
                                                </InputAdornment>
                                            }
                                        />
                                    )}
                                </Box>
                                {isMobile && (
                                    <Typography style={{ marginBottom: '20px' }}>
                                        rtmps://global-live.mux.com:443/app
                                    </Typography>
                                )}
                                <Typography style={{ display: 'inline' }}>We recommend using </Typography>&nbsp;
                                <Typography
                                    style={{
                                        textDecoration: 'underline',
                                        color: palette['secondary'],
                                        cursor: 'pointer',
                                        display: 'inline',
                                    }}
                                >
                                    OBS
                                </Typography>
                                <Typography style={{ display: 'inline' }}>. Select</Typography>&nbsp;
                                <Typography style={{ color: palette['secondary'], fontWeight: 600, display: 'inline' }}>
                                    api.video
                                </Typography>
                                &nbsp;
                                <Typography style={{ display: 'inline' }}>in the service list. </Typography>
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default StartYourStreamTab;
