import { Box, Button, DialogContent, Divider, Typography } from '@material-ui/core';
import React from 'react';
import { palette } from '../../styles/theme';
import FavoriteIcon from '@material-ui/icons/Favorite';

interface NewOrderEnded {
    setClose: () => void;
    isOrderSuccessful: boolean;
}

const OrderEnded = (props: NewOrderEnded) => {
    return (
        <>
            <DialogContent>
                {props.isOrderSuccessful ? (
                    <Box>
                        <Typography
                            style={{
                                fontSize: '26px',
                                fontWeight: 600,
                                textAlign: 'center',
                                margin: '120px 0px 30px 0px',
                            }}
                        >
                            Purchase completed
                        </Typography>
                        <Divider style={{ height: '2px', backgroundColor: palette['primary_d'] }} />
                        <Box style={{ justifyContent: 'center', display: 'flex', margin: '30px 0px 120px 0px' }}>
                            <Typography
                                style={{
                                    fontSize: '20px',
                                    fontWeight: 600,
                                    color: palette['secondary'],
                                }}
                            >
                                Thank you
                            </Typography>
                            <FavoriteIcon style={{ color: palette['secondary'], paddingLeft: '10px' }} />
                        </Box>
                        <Box style={{ display: 'flex', marginBottom: '10px', justifyContent: 'center' }}>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    props.setClose();
                                }}
                                style={{ backgroundColor: palette['softDarkBlue'] }}
                            >
                                <Typography>Close</Typography>
                            </Button>
                        </Box>
                    </Box>
                ) : (
                    <Box>
                        <Typography
                            style={{
                                fontSize: '26px',
                                fontWeight: 600,
                                textAlign: 'center',
                                margin: '120px 0px 30px 0px',
                            }}
                        >
                            There was a problem with your order. Please contact support.
                        </Typography>
                        <Button
                            variant="contained"
                            onClick={() => {
                                props.setClose();
                            }}
                            style={{ backgroundColor: palette['softDarkBlue'] }}
                        >
                            <Typography>Close</Typography>
                        </Button>
                    </Box>
                )}
            </DialogContent>
        </>
    );
};

export default OrderEnded;
