import React, { useRef } from 'react';
import { Box } from '@material-ui/core';
import { StreamPublicData } from '../../../../models/Stream';
import { UserPublicData, UserStreamingData } from '../../../../models/User';
import ProfileHeader from './profile_header/ProfileHeader';
import ProfileTabs from './tabs/ProfileTabs';
import { darkShadow } from '../../../../constants/Constants';
import { useMainWindowHost } from '../../../../contexts/MainWindowHostContext';
import { Message } from '../../../../models/Chat';
import { NotFoundPage } from '../../../../styles/CustomComponents';

interface ProfilePageProps {
    currentStream: StreamPublicData;
    user: UserPublicData;
    userStreamingData: UserStreamingData;
    chatMessages: Message[];
    banned: boolean;
}

const ProfilePage = (props: ProfilePageProps) => {
    const { isComputer, isMobile } = useMainWindowHost();

    const profileRef = useRef<HTMLDivElement>(null);
    const isStreamActive = props.currentStream?.active;

    return props.user ? (
        <div style={{ height: '100%', scrollMargin: '20px' }} ref={profileRef}>
            {(!isStreamActive || props.banned) && props.user?.profileBannerUrl && (
                <img
                    style={{
                        maxWidth: '1300px',
                        width: '95%',
                        borderRadius: '20px',
                        display: 'block',
                        margin: isMobile ? '10px auto' : '30px auto',
                        boxShadow: darkShadow,
                        padding: '10px',
                    }}
                    src={props.user?.profileBannerUrl}
                />
            )}
            <Box
                style={{
                    margin: '10px auto 20px auto',
                    maxWidth: '1200px',
                    height: '100%',
                }}
            >
                <Box margin={isComputer ? '0px 1vw' : '0px 10px'} height="100%">
                    <ProfileHeader
                        user={props.user}
                        userStreamingData={props.userStreamingData}
                        profileRef={profileRef}
                        currentStream={props.currentStream}
                        chatMessages={props.chatMessages}
                        banned={props.banned}
                    />
                    <ProfileTabs
                        user={props.user}
                        currentStream={props.currentStream}
                        userStreamingData={props.userStreamingData}
                    />
                </Box>
            </Box>
        </div>
    ) : (
        <Box height="100%" width="100%">
            <NotFoundPage msg="This user does not exist" />
        </Box>
    );
};

export default ProfilePage;
