import { Box, Divider, Tooltip, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import styles from '../../../../styles/styles.module.css';
import { CSSProperties } from '@material-ui/styles';
import { useAuth } from '../../../../contexts/AuthContext';
import { Message } from '../../../../models/Chat';
import AuthenticationDialog from '../../../nav_bar/authentication/AuthenticationDialog';
import FirestoreLiveStreams from '../../../../firestore/FirestoreLiveStreams';
import { UserPublicData, UserStreamingData } from '../../../../models/User';
import SubscribeDialog from '../dialogs/subscriptions/SubscribeDialog';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import { palette } from '../../../../styles/theme';
import { useMainWindowHost } from '../../../../contexts/MainWindowHostContext';
import ChatDonationMessage from './ChatDonationMessage';
import TipRequest from './TipRequest';
import { colorSelection } from '../../../../constants/Constants';

interface ChatComponentProps {
    streamId: string;
    canWatchStream: boolean;
    badgeUrl: string;
    user: UserPublicData;
    userStreamingData: UserStreamingData;
    messages: Message[];
}

const ChatComponent = (props: ChatComponentProps) => {
    const { isComputer, isMobile } = useMainWindowHost();
    const firestoreLiveStreams: FirestoreLiveStreams = new FirestoreLiveStreams();

    const { currentUser, currentUserPublicData } = useAuth();
    const [openAuthenticationModal, setOpenAuthenticationModal] = useState(false);
    const [openSubscribeDialog, setOpenSubscribeDialog] = useState(false);
    const [isChatCollapsed, setIsChatCollapsed] = useState(!isComputer && !isMobile);

    useEffect(() => {
        setIsChatCollapsed(!isComputer && !isMobile);
    }, [isComputer, isMobile]);

    const sendMessage = async (event: React.KeyboardEvent<HTMLSpanElement>): Promise<void> => {
        if (event.key === 'Enter') {
            event.preventDefault();

            if (currentUser.isAnonymous) {
                setOpenAuthenticationModal(true);
                return;
            }

            if (!props.canWatchStream) {
                setOpenSubscribeDialog(true);
                return;
            }

            if (props.streamId) {
                const message: string = (event.target as HTMLSpanElement).innerText;
                if (!message) return;

                (event.target as HTMLSpanElement).innerHTML = '';
                await firestoreLiveStreams.addNewChatMessage(props.streamId, {
                    username: currentUser.displayUsername as string,
                    messages: [message],
                    type: 'message',
                    badgeUrl: props.badgeUrl,
                    userId: currentUser.userId,
                    color: currentUserPublicData.color,
                });
            } else {
                alert('There was an error while sending your message, please try again later');
            }
        }
    };

    const displayChatElement = (message: Message) => {
        switch (message.type) {
            case 'message':
                return (
                    <Box>
                        {message.badgeUrl && <img src={message.badgeUrl} style={badgeStyle} />}
                        <Typography
                            style={{ ...chatMessageStyle, fontWeight: 600, color: colorSelection[message.color] }}
                        >
                            {message.username}
                        </Typography>
                        <Tooltip title={message.time ? new Date(message.time.toDate()).toLocaleTimeString() : ''}>
                            <Typography style={chatMessageStyle}> : {message.messages[0]} </Typography>
                        </Tooltip>
                    </Box>
                );
            case 'donation':
                return <ChatDonationMessage message={message} streamId={props.streamId} />;
            case 'request':
                return <TipRequest message={message} />;
        }
    };

    const badgeStyle: CSSProperties = {
        width: '15px',
        height: '15px',
        marginRight: '5px',
        marginBottom: '-2px',
    };

    const chatBoxStyle: CSSProperties = {
        display: 'flex',
        flexDirection: 'column-reverse',
        paddingLeft: '15px',
        paddingRight: '15px',
        overflowY: 'auto',
    };

    const chatMessageStyle: CSSProperties = {
        fontSize: '16px',
        wordWrap: 'break-word',
        display: 'inline',
    };

    const chatStyle: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        height: '99%',
        width: isMobile ? '100%' : '20vw',
        minWidth: '200px',
        maxWidth: isMobile ? '95vw' : '300px',
        borderRadius: '20px',
        boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.4)',
    };

    const chatCollapseIconStyle: CSSProperties = {
        position: 'relative',
        left: isChatCollapsed ? '-50px' : '0px',
        color: palette['text_p'],
        cursor: 'pointer',
    };

    return (
        <Box
            style={{
                position: 'relative',
                zIndex: 1,
                height: '100%',
                width: isChatCollapsed ? '0' : 'auto',
                padding: isChatCollapsed || isMobile ? '0px' : '0px 10px',
                marginLeft: isChatCollapsed ? '10px' : '0px',
            }}
        >
            <Box bgcolor="primary.light" style={chatStyle}>
                <Box style={{ display: 'flex', padding: '5px 10px', position: 'relative', textAlign: 'center' }}>
                    {!isMobile && (
                        <Box onClick={() => setIsChatCollapsed(!isChatCollapsed)}>
                            {isChatCollapsed ? (
                                <FirstPageIcon style={chatCollapseIconStyle} />
                            ) : (
                                <LastPageIcon style={chatCollapseIconStyle} />
                            )}
                        </Box>
                    )}
                    <Typography
                        style={{
                            position: isMobile ? 'relative' : 'absolute',
                            left: '50%',
                            transform: 'translateX(-50%)',
                        }}
                    >
                        Chat
                    </Typography>
                </Box>
                <Divider style={{ backgroundColor: palette['primary'] }} variant="middle" />
                <Box height="100%" style={chatBoxStyle} className={styles.invisibleScroll}>
                    {props.messages &&
                        props.messages.map((message: Message, idx: number) => (
                            <Box key={idx}>{displayChatElement(message)}</Box>
                        ))}
                </Box>
                <Box display="flex">
                    {currentUser.isVerified ? (
                        <span
                            className={styles.textarea}
                            role="textbox"
                            contentEditable={true}
                            onKeyDown={async (event) => await sendMessage(event)}
                        />
                    ) : (
                        <Tooltip title="You need to verify your account before you can use the chat.">
                            <span className={styles.textarea} role="textbox" contentEditable={false} />
                        </Tooltip>
                    )}
                </Box>

                <AuthenticationDialog setDialogState={setOpenAuthenticationModal} open={openAuthenticationModal} />
                <SubscribeDialog
                    setDialogState={setOpenSubscribeDialog}
                    isOpen={openSubscribeDialog}
                    user={props.user}
                    userStreamingData={props.userStreamingData}
                />
            </Box>
        </Box>
    );
};

export default ChatComponent;
