import React, { useEffect, useState } from 'react';
import { Box, Dialog, Typography, Button, Divider, DialogTitle, DialogContent } from '@material-ui/core';
import BecomeAStreamerTab from '../become_a_streamer_tab/BecomeAStreamerTab';
import { palette } from '../../../../../styles/theme';
import { useMainWindowHost } from '../../../../../contexts/MainWindowHostContext';
import { useAuth } from '../../../../../contexts/AuthContext';
import { CSSProperties } from '@material-ui/styles';
import styles from '../../../../../styles/styles.module.css';
import AnimateHeight from 'react-animate-height';

const StreamersTab = () => {
    const { isMobile } = useMainWindowHost();
    const { currentUserPrivateData } = useAuth();

    const [becomeStreamerDialogOpen, setBecomeStreamerDialogOpen] = useState(false);
    const [streamersDocumentsVisible, setStreamersDocumentsVisible] = useState<boolean[]>([]);

    useEffect(() => {
        const newStreamersDocumentsVisible = [];
        for (let i = 0; i < currentUserPrivateData.streamers.length; i++) {
            newStreamersDocumentsVisible.push(false);
        }

        setStreamersDocumentsVisible(newStreamersDocumentsVisible);
    }, [currentUserPrivateData]);

    const photosStyle: CSSProperties = {
        // height: isMobile ? '180px' : '300px',
        margin: '10px 20px 10px 0px',
        borderRadius: '10px',
        maxWidth: '150px',
        height: '100%',
        boxShadow: '5px 5px 5px rgba(0,0,0,0.4)',
    };

    const addStreamerMessageStyle: CSSProperties = {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '20px',
        padding: '10px 20px',
        backgroundColor: palette['primary'],
        marginBottom: '30px',
    };

    return (
        <Box>
            <Box style={addStreamerMessageStyle}>
                <Box className={styles.inline}>
                    <Typography>Remember, every person that appears in your livestreams must be&nbsp;</Typography>
                    <Typography style={{ color: palette['secondary'] }}>18 years of age or older.</Typography>
                    <br />
                    <Typography>You can&nbsp;</Typography>
                    <Typography style={{ color: palette['secondary'] }}>
                        add as many streamers as you like to your channel
                    </Typography>
                    <Typography>, but we still need to verify that they are of legal age.</Typography>
                </Box>
                <Button
                    style={{ backgroundColor: palette['secondary'], marginTop: isMobile ? '10px' : '0px' }}
                    onClick={() => setBecomeStreamerDialogOpen(true)}
                >
                    Add a streamer
                </Button>
            </Box>

            <Typography>Streamers</Typography>
            <Divider style={{ height: '2px', backgroundColor: palette['primary'] }} />
            {currentUserPrivateData.streamers.map((streamer, idx) => (
                <Box key={idx} margin="20px 0px">
                    <li style={{ color: palette['text_p'] }}>
                        <Box className={styles.inline} style={{ display: 'inline' }}>
                            <Typography className={styles.unselectable} style={{ fontWeight: 'bold' }}>
                                {streamer.name}&nbsp;
                            </Typography>
                            <Typography
                                className={styles.unselectable}
                                style={{ color: palette['text_s'], cursor: 'pointer', fontSize: '14px' }}
                                onClick={() => setStreamersDocumentsVisible((prev) => ({ ...prev, [idx]: !prev[idx] }))}
                            >
                                ({streamersDocumentsVisible[idx] ? 'hide' : 'show'} documents)
                            </Typography>
                        </Box>
                    </li>
                    {!streamer.isVerified && (
                        <Typography style={{ color: palette['secondary'] }}>
                            *{streamer.name}&apos;s informations has not yet been verified
                        </Typography>
                    )}
                    <AnimateHeight
                        height={streamersDocumentsVisible[idx] ? 'auto' : 0}
                        duration={300}
                        animateOpacity={true}
                    >
                        <Box display="flex" flexWrap="wrap">
                            <img src={streamer.idUrl} style={photosStyle} />
                            <img src={streamer.userWithIdUrl} style={photosStyle} />
                        </Box>
                    </AnimateHeight>
                </Box>
            ))}

            <Dialog
                open={becomeStreamerDialogOpen}
                onClose={() => setBecomeStreamerDialogOpen(false)}
                fullWidth
                maxWidth="lg"
                PaperProps={{ style: { margin: '10px', width: '100%' } }}
            >
                <DialogTitle style={{ padding: isMobile ? '10px 10px 0px 30px' : '16px 24px' }}>
                    Add a streamer
                </DialogTitle>
                <DialogContent className={styles.invisibleScroll}>
                    <BecomeAStreamerTab setDialogOpen={setBecomeStreamerDialogOpen} />
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default StreamersTab;
