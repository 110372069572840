import {
    Box,
    Button,
    CircularProgress,
    createStyles,
    Tab,
    Tabs,
    Theme,
    Typography,
    withStyles,
} from '@material-ui/core';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { palette } from './theme';
import StarIcon from '@material-ui/icons/Star';
import styles from './styles.module.css';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';

interface LoadingButtonProps {
    isLoading: boolean;
    value: string | JSX.Element;
    onClick: () => void;
    style?: CSSProperties;
    disabled?: boolean;
    loadingStyle?: CSSProperties;
}

export const LoadingButton = (props: LoadingButtonProps) => {
    return (
        <Button onClick={() => props.onClick()} disabled={props.isLoading || props.disabled} style={props.style}>
            {!props.isLoading && props.value}
            {props.isLoading && <CircularProgress style={props.loadingStyle} size={20} />}
        </Button>
    );
};

interface InputProps {
    style?: CSSProperties;
    autoComplete?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value?: string;
    placeholder?: string;
    readOnly?: boolean;
    type?: string;
    endIcon?: JSX.Element;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    autoFocus?: boolean;
    inline?: boolean;
}

export const Input = (props: InputProps) => {
    const endIconRef = useRef<HTMLDivElement>(null);
    const [padding, setPadding] = useState('3px 10px 5px 10px');

    useEffect(() => {
        if (!endIconRef.current) return;

        setPadding(`3px ${endIconRef.current?.children[0].clientWidth + 20}px 5px 10px`);
    }, [endIconRef]);

    const style: CSSProperties = {
        backgroundColor: palette['lightPurple'],
        border: `solid 3px ${palette['primary']}`,
        padding: padding,
        borderRadius: '10px',
        outline: 'none',
        color: palette['text_p'],
        fontSize: '15px',
        font: 'inherit',
        fontFamily: 'Mulish, sans-serif',
    };

    return (
        <Box display={props.inline ? 'inline' : 'flex'} alignItems="center" width="100%">
            <input
                autoFocus={props.autoFocus}
                style={{ ...style, ...props.style }}
                autoComplete={props.autoComplete}
                onChange={props.onChange}
                value={props.value}
                placeholder={props.placeholder}
                readOnly={props.readOnly}
                type={props.type}
                onKeyDown={props.onKeyDown}
            />
            {props.endIcon && (
                <div ref={endIconRef} style={{ margin: '5px 10px 0px -35px' }}>
                    {props.endIcon}
                </div>
            )}
        </Box>
    );
};

interface TextAreaProps {
    style?: CSSProperties;
    onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    value?: string;
}

export const TextArea = (props: TextAreaProps) => {
    const style: CSSProperties = {
        backgroundColor: palette['lightPurple'],
        border: `solid 3px ${palette['primary']}`,
        padding: '5px 10px',
        borderRadius: '10px',
        outline: 'none',
        color: palette['text_p'],
        fontSize: '15px',
        font: 'inherit',
        resize: 'none',
    };

    return <textarea style={{ ...style, ...props.style }} onChange={props.onChange} value={props.value} />;
};

interface SelectProps {
    options: string[];
    value?: string;
    style?: CSSProperties;
    defaultValue?: string;
    ref?: React.RefObject<HTMLSelectElement>;
    optionsStyle?: CSSProperties;
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const Select = React.forwardRef((props: SelectProps, ref) => {
    const style: CSSProperties = {
        backgroundColor: palette['lightPurple'],
        border: `solid 3px ${palette['primary']}`,
        padding: '5px 10px',
        borderRadius: '20px',
        color: palette['text_p'],
        outline: 'none',
    };

    const optionsStyle: CSSProperties = {
        backgroundColor: palette['lightPurple'],
        color: palette['text_p'],
    };

    return (
        <select
            style={{ ...style, ...props.style }}
            defaultValue={props.defaultValue}
            ref={ref as React.RefObject<HTMLSelectElement>}
            onChange={props.onChange}
            value={props.value}
        >
            {props.options.map((option, idx) => (
                <option value={option} key={idx} style={{ ...optionsStyle, ...props.optionsStyle }}>
                    {option}
                </option>
            ))}
        </select>
    );
});

Select.displayName = 'Select';

interface NotFoundPageProps {
    msg: string;
}

export const NotFoundPage = (props: NotFoundPageProps) => {
    const sadIconStyle: CSSProperties = {
        fontSize: '60px',
        marginRight: '20px',
    };

    const centered: CSSProperties = {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    };

    return (
        <Box width="100%" height="100%">
            <Box style={centered} paddingTop="100px">
                <SentimentVeryDissatisfiedIcon style={sadIconStyle} color="secondary" />
                <Typography variant="h4"> Oops, there was a problem! </Typography>
            </Box>
            <Box style={centered}>
                <Typography variant="h5" style={{ padding: '20px' }}>
                    {props.msg}
                </Typography>
            </Box>
        </Box>
    );
};

export const LoadingPage = () => {
    const style: CSSProperties = {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
    };

    return (
        <Box style={style}>
            <CircularProgress size={40} style={{ color: palette['text_s'], margin: 'auto' }} />
        </Box>
    );
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: string | number;
    value: string | number;
}

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box> {children} </Box>}
        </div>
    );
}

export const AntTabs = withStyles({
    root: {
        borderBottom: `2px solid ${palette['primary']}`,
    },
    indicator: {
        backgroundColor: palette['text_p'],
    },
})(Tabs);

export const AntTabs_l = withStyles({
    root: {
        borderBottom: `2px solid ${palette['primary_l']}`,
    },
    indicator: {
        backgroundColor: palette['text_p'],
    },
})(Tabs);

export const AntTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            textTransform: 'none',
            minWidth: 72,
            fontWeight: 400,
            fontSize: '18px',
            marginRight: theme.spacing(4),
            '&:hover': {
                color: palette['secondary'],
                opacity: 1,
            },
            '&$selected': {
                color: palette['secondary'],
            },
            '&:focus': {
                color: palette['secondary'],
            },
        },
        selected: {},
    }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

interface StyledTabProps {
    label: string;
}

export function a11yProps(index: number | string) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

interface StarProps {
    style?: CSSProperties;
    borderStyle?: CSSProperties;
    tier?: number;
}

const getSubscriptionTierColor = (tier: number) => {
    tier = Number(tier);
    switch (tier) {
        case 1:
            return '#CB600F';
        case 2:
            return '#A8A8A8';
        case 3:
            return '#EF9712';
    }
};

export const SubscriptionStar = (props: StarProps) => {
    return (
        <Box style={{}}>
            <StarIcon
                style={{
                    ...props.style,
                    position: 'absolute',
                    ...props.borderStyle,
                }}
            />
            <StarIcon
                style={{
                    ...props.style,
                    color: 'white',
                    position: 'absolute',
                }}
            />
            {props.tier && (
                <StarIcon
                    className={styles.gradientStar}
                    style={{
                        ...props.style,
                        color: getSubscriptionTierColor(props.tier),
                    }}
                />
            )}
        </Box>
    );
};
