import { Avatar, Box, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import React from 'react';
import { Subscription } from '../../../../../models/User';
import { palette } from '../../../../../styles/theme';

import { darkShadow } from '../../../../../constants/Constants';
import { useMainWindowHost } from '../../../../../contexts/MainWindowHostContext';
import DOMPurify from 'dompurify';
import LensIcon from '@material-ui/icons/Lens';

interface SubscribeDialogContentProps {
    subscriptionBannerUrl: string;
    profilePictureUrl: string;
    displayUsername: string;
    subscription: Subscription;
    subscribe?: () => void;
}

const SubscriptionDialogContent = (props: SubscribeDialogContentProps) => {
    const { isMobile } = useMainWindowHost();

    const createMarkup = (value: string) => {
        return {
            __html: DOMPurify.sanitize(value),
        };
    };

    const titleStyle: CSSProperties = {
        display: 'inline',
        fontSize: '24px',
        fontWeight: 600,
    };

    const bannerStyle: CSSProperties = {
        width: '98%',
        borderRadius: '25px',
        display: 'block',
        margin: 'auto',
        opacity: 0.8,
        boxShadow: darkShadow,
    };

    const profilePictureStyle: CSSProperties = {
        width: '10vw',
        height: '10vw',
        maxWidth: 65,
        maxHeight: 65,
        border: `3px solid ${palette['primary_l']}`,
        padding: '1px',
    };

    const displayNameStyle: CSSProperties = {
        fontSize: isMobile ? '18px' : '28px',
        fontWeight: 700,
        textShadow: '-2px 2px 6px black',
        paddingLeft: '7px',
    };

    const previewStyle: CSSProperties = {
        fontFamily: 'Mulish, sans-serif',
        color: palette['text_p'],
        padding: '0px',
        flexGrow: 1,
    };

    const supportStyle: CSSProperties = {
        display: 'inline',
        fontSize: '18px',
        fontWeight: 700,
    };

    const bulletStyle: CSSProperties = {
        marginTop: '5px',
        marginRight: '4px',
        fontSize: '11px',
        marginLeft: '2x',
    };

    const benefitsTextStyle: CSSProperties = {
        fontSize: '15px',
        fontWeight: 400,
    };

    const benefitsStyle: CSSProperties = {
        marginTop: '7px',
        display: 'flex',
    };

    return (
        <Box>
            <DialogTitle style={{ paddingBottom: '6px' }}>
                <Typography style={titleStyle} color="secondary">
                    Subscribe&nbsp;
                </Typography>
                <Typography style={titleStyle}>to&nbsp;</Typography>
                {!props.subscriptionBannerUrl && <Typography style={titleStyle}>{props.displayUsername}</Typography>}
            </DialogTitle>
            <DialogContent>
                {props.subscriptionBannerUrl && (
                    <Box style={{ position: 'relative' }}>
                        <img style={bannerStyle} src={props.subscriptionBannerUrl} />
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                position: 'absolute',
                                top: 10,
                                left: 10,
                            }}
                        >
                            <Avatar src={props.profilePictureUrl} style={profilePictureStyle} />
                            <Typography color="secondary" style={displayNameStyle}>
                                {props.displayUsername}
                            </Typography>
                        </Box>
                    </Box>
                )}

                <Box
                    marginTop="20px"
                    style={{
                        display: isMobile ? 'block' : 'flex',
                        backgroundColor: palette['softDarkBlue'],
                        padding: '10px 15px 10px 20px',
                        borderRadius: '20px',
                    }}
                >
                    <Box style={{ width: isMobile ? 'auto' : '55%' }}>
                        <Box>
                            <Typography style={supportStyle}>Support </Typography>
                            <Typography style={{ ...supportStyle, color: palette['secondary'], fontWeight: 700 }}>
                                {props.displayUsername}
                            </Typography>
                            <Typography style={supportStyle}> to get:</Typography>
                        </Box>
                        <Box>
                            {/* <Box style={benefitsStyle}>
                                <LensIcon style={bulletStyle} />
                                <Typography style={benefitsTextStyle}>
                                    Private messages with {props.displayUsername}
                                </Typography>
                            </Box>
                            <Box style={benefitsStyle}>
                                <LensIcon style={bulletStyle} />
                                <Typography style={benefitsTextStyle}>
                                    Access to {props.displayUsername} stories
                                </Typography>
                            </Box> */}
                            <Box style={benefitsStyle}>
                                <LensIcon style={bulletStyle} />
                                <Typography style={benefitsTextStyle}>Access to sub only stream</Typography>
                            </Box>
                            <Box style={benefitsStyle}>
                                <LensIcon style={bulletStyle} />
                                <Typography style={benefitsTextStyle}>Custom chat icon:</Typography>
                                <img
                                    style={{
                                        maxWidth: 35,
                                        maxHeight: 25,
                                        marginLeft: '4px',
                                        position: 'relative',
                                        top: '-2px',
                                    }}
                                    src={props.subscription.badgeUrl}
                                />
                            </Box>
                            <Box style={benefitsStyle}>
                                <LensIcon style={bulletStyle} />
                                <Typography style={benefitsTextStyle}>... And a lot more to come!</Typography>
                            </Box>
                        </Box>
                        {!isMobile && (
                            <Box style={{ marginTop: '5px' }}>
                                <Typography style={{ fontSize: '18px' }}>
                                    For US${props.subscription.monthlyFee}/month
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    <Box
                        style={{
                            backgroundColor: palette['primary_l'],
                            width: isMobile ? 'auto' : '45%',
                            borderRadius: '15px',
                            padding: '5px 10px',
                            margin: '5px 0px',
                        }}
                    >
                        <Box
                            className="ql-editor"
                            dangerouslySetInnerHTML={createMarkup(props.subscription.benefits)}
                            style={previewStyle}
                        />
                    </Box>
                    {isMobile && (
                        <Box style={{ marginTop: '5px' }}>
                            <Typography style={{ fontSize: '18px' }}>
                                For US${props.subscription.monthlyFee}/month
                            </Typography>
                        </Box>
                    )}
                </Box>
                <Box>
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '20px',
                            fontSize: '20px',
                        }}
                    >
                        <Box
                            style={{
                                cursor: 'pointer',
                                backgroundColor: palette['secondary'],
                                padding: isMobile ? '0px 5px' : '7px 20px',
                                borderRadius: '10px',
                            }}
                            onClick={() => {
                                if (props.subscribe) {
                                    props.subscribe();
                                }
                            }}
                        >
                            <Typography style={{ fontSize: '20px', fontWeight: 700 }}>Subscribe</Typography>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
        </Box>
    );
};

export default SubscriptionDialogContent;
