import React, { CSSProperties } from 'react';
import { Box, DialogContent, DialogTitle, List, ListItemText, Typography } from '@material-ui/core';
import { IPointsPackage } from '../../../models/Transaction';
import { LoadingPage } from '../../../styles/CustomComponents';
import { palette } from '../../../styles/theme';
import { useMainWindowHost } from '../../../contexts/MainWindowHostContext';

interface NewStoreDialog {
    pointsPackages: IPointsPackage[];
    setSelectedPackage: (selectedPackage: IPointsPackage) => void;
}
function ProductSelection(props: NewStoreDialog) {
    const { isMobile } = useMainWindowHost();

    const dialogTitleStyle: CSSProperties = {
        fontSize: isMobile ? '20px' : '26px',
        display: 'inline',
        fontWeight: 600,
    };

    const dialogSubtitleStyle: CSSProperties = {
        fontSize: isMobile ? '18px' : '22px',
        display: 'inline',
        fontWeight: 600,
    };

    const packageStyle: CSSProperties = {
        backgroundColor: palette['softDarkBlue'],
        borderRadius: '25px',
        margin: isMobile ? '10px 0px' : '15px 20px',
        paddingLeft: '10px',
        cursor: 'pointer',
    };

    return (
        <>
            <DialogTitle>
                <Typography style={dialogTitleStyle}>Give</Typography>&nbsp;
                <Typography style={{ ...dialogTitleStyle, color: palette['secondary'] }}>Points</Typography>
                <Typography style={dialogTitleStyle}>&nbsp;to streamers!</Typography>
            </DialogTitle>
            <DialogContent>
                <Typography style={{ fontWeight: 600, fontSize: isMobile ? '18px' : '22px' }}>
                    Select a package
                </Typography>
                <Box>
                    {false ? (
                        <LoadingPage />
                    ) : (
                        <List component="nav" aria-label="main mailbox folders">
                            {props.pointsPackages.map((product: IPointsPackage) => {
                                return (
                                    <Box
                                        key={product.productId}
                                        style={packageStyle}
                                        onClick={() => props.setSelectedPackage(product)}
                                    >
                                        <Box
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                padding: isMobile ? '2px 8px' : '8px 16px',
                                            }}
                                        >
                                            <ListItemText
                                                primary={product.mainText}
                                                primaryTypographyProps={{
                                                    style: { fontSize: isMobile ? '16px' : '19px' },
                                                }}
                                                secondary={product.discountText}
                                                secondaryTypographyProps={{
                                                    color: 'textPrimary',
                                                    style: { opacity: 0.5, lineHeight: isMobile ? '1' : 'auto' },
                                                }}
                                            />
                                            <Box
                                                style={{
                                                    backgroundColor: palette['secondary'],
                                                    color: palette['text_p'],
                                                    fontSize: '16px',
                                                    borderRadius: '14px',
                                                    padding: isMobile ? '5px' : '14px',
                                                }}
                                            >
                                                <Typography>${product.price}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                );
                            })}
                        </List>
                    )}
                </Box>
                <Box style={{ textAlign: 'center', marginBottom: '20px' }}>
                    <Typography style={dialogSubtitleStyle}>What are&nbsp;</Typography>
                    <Typography
                        style={{
                            ...dialogSubtitleStyle,
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            color: palette['secondary'],
                        }}
                    >
                        Points
                    </Typography>
                    <Typography style={dialogSubtitleStyle}>&nbsp;?</Typography>
                </Box>
            </DialogContent>
        </>
    );
}

export default ProductSelection;
