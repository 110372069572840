import React, { CSSProperties } from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import { palette } from '../../../styles/theme';
import { useMainWindowHost } from '../../../contexts/MainWindowHostContext';

const About = () => {
    const { isMobile } = useMainWindowHost();

    const dividersStyle: CSSProperties = {
        backgroundColor: palette['text_p'],
        marginBottom: '20px',
    };
    return (
        <Box>
            <Typography style={{ fontSize: '30px', fontWeight: 600, display: 'inline' }}>Welcome to </Typography>
            <Typography style={{ fontSize: '30px', fontWeight: 600, color: palette['secondary'], display: 'inline' }}>
                Playerz
            </Typography>
            <Divider style={dividersStyle} />

            <Box style={{ display: isMobile ? 'auto' : 'flex', width: '100%' }}>
                <Box style={{ width: isMobile ? 'block' : '60%' }}>
                    <Typography>Playerz.tv is here to create a new community for adult intertainment.</Typography>
                    <br />
                    <Typography>
                        This platform is a free and available space where people can get together and share as they
                        want. blablabli
                    </Typography>
                    <Typography>Blablabla</Typography>
                </Box>
                {/* <Box
                    style={{
                        width: isMobile ? 'auto' : '40%',
                        backgroundColor: palette['primary_d'],
                        borderRadius: '20px',
                        padding: '20px 15px',
                    }}
                >
                    <Typography>Playerz.tv is the propriety of </Typography>
                    <Typography>Meunier & Naud-Dulude S.E.N.C</Typography>
                    <Typography>610 Sanford, Saint-Lambert, QC</Typography>
                    <Typography>Saint-Lambert, QC J4P 2Y6</Typography>
                    <Typography>Canada</Typography>
                </Box> */}
            </Box>
        </Box>
    );
};

export default About;
