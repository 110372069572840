import { Box, Checkbox, DialogContentText, Typography } from '@material-ui/core';
import React, { CSSProperties, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { palette } from '../../../../../styles/theme';
import { StreamPublicData, StreamInformationForm } from '../../../../../models/Stream';
import { Input } from '../../../../../styles/CustomComponents';
import { useMainWindowHost } from '../../../../../contexts/MainWindowHostContext';

interface StreamInformationTabProps {
    currentStream: StreamPublicData;
    setStreamInformationForm: React.Dispatch<React.SetStateAction<StreamInformationForm>>;
    streamInformationForm: StreamInformationForm;
}

const StreamInformationTab = (props: StreamInformationTabProps) => {
    const { isMobile } = useMainWindowHost();

    const [contentValue, setContentValue] = useState('');

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (event.target.value.length <= 50) {
            props.setStreamInformationForm((prev) => ({ ...prev, streamName: event.target.value }));
        }
    };

    const handleContentInputEnter = (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (event.key === 'Enter' && props.streamInformationForm.streamContent?.length < 3) {
            props.setStreamInformationForm((prev) => ({
                ...prev,
                streamContent: prev.streamContent.concat([contentValue]),
            }));

            setContentValue('');
        }
    };

    const handleContentInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (event.target.value.length <= 15) {
            setContentValue(event.target.value);
        }
    };

    const dialogContentTextStyle: CSSProperties = {
        margin: '0',
        fontWeight: 600,
        color: palette['text_p'],
    };

    const textFieldsStyle: CSSProperties = {
        width: '100%',
        marginBottom: '23px',
    };

    const contentBoxStyle: CSSProperties = {
        display: 'flex',
        borderRadius: '10px',
        padding: '0px 10px',
        margin: '0px 10px 5px 0px',
        alignItems: 'center',
        backgroundColor: palette['primary'],
    };

    const removeContentIconStyle: CSSProperties = {
        color: palette['text_s'],
        fontSize: '15px',
        marginLeft: '5px',
        cursor: 'pointer',
    };

    return (
        <Box>
            <DialogContentText style={{ ...dialogContentTextStyle, marginTop: '23px' }}>Stream name:</DialogContentText>
            <Input
                style={textFieldsStyle}
                onChange={(event) => handleNameChange(event)}
                value={props.streamInformationForm.streamName}
                type="text"
            />

            <DialogContentText style={dialogContentTextStyle}>
                Tags (press enter to add, 3 tags maximum):
            </DialogContentText>
            <Input
                style={{ ...textFieldsStyle, marginBottom: '5px' }}
                onKeyDown={(event) => handleContentInputEnter(event)}
                onChange={(event) => handleContentInputChange(event)}
                value={contentValue}
            />
            <Box display="flex" marginBottom="20px" flexWrap="wrap">
                {props.streamInformationForm.streamContent &&
                    props.streamInformationForm.streamContent.map((el, idx) => (
                        <Box key={idx} style={contentBoxStyle}>
                            <Typography>{el}</Typography>
                            <CloseIcon
                                style={removeContentIconStyle}
                                onClick={() =>
                                    props.setStreamInformationForm((prev) => ({
                                        ...prev,
                                        streamContent: prev.streamContent.filter((_, i) => i !== idx),
                                    }))
                                }
                            />
                        </Box>
                    ))}
            </Box>

            <Box display={isMobile ? 'block' : 'flex'} alignItems="center">
                <Box display="flex" alignItems="center">
                    <Checkbox
                        checked={props.streamInformationForm.isSubscribersOnly}
                        style={{ margin: '3px 0px 3px -10px' }}
                        onChange={(event) =>
                            props.setStreamInformationForm((prev) => ({
                                ...prev,
                                isSubscribersOnly: event.target.checked,
                            }))
                        }
                    />
                    <Typography style={{ color: palette['secondary'], fontWeight: 700 }}>Subscribers</Typography>&nbsp;
                    <Typography> only stream</Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default StreamInformationTab;
