import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormHelperText,
    Tab,
    Tabs,
    Typography,
} from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { a11yProps, LoadingButton, TabPanel } from '../../../../../styles/CustomComponents';
import { palette } from '../../../../../styles/theme';
import {
    defaultStreamInformationForm,
    StreamPublicData,
    StreamInformationForm,
    StreamPrivateData,
} from '../../../../../models/Stream';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useAuth } from '../../../../../contexts/AuthContext';
import FireStoreLiveStreams from '../../../../../firestore/FirestoreLiveStreams';
import { useMainWindowHost } from '../../../../../contexts/MainWindowHostContext';
import StreamInformationTab from './StreamInformationTab';
import StartYourStreamTab from './StartingStreamTab';
import ActivateStreamTab from './ActivateStreamTab';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { firestore } from '../../../../../Firebase';
import { firestoreCollections } from '../../../../../constants/Constants';

interface NewStreamDialogProps {
    open: boolean;
    setDialogState: (isOpen: boolean) => void;
    currentStream: StreamPublicData;
}

const StartingStreamDialog = (props: NewStreamDialogProps) => {
    const firestoreLiveStreams: FireStoreLiveStreams = new FireStoreLiveStreams();

    const { currentUser, currentUserPublicData } = useAuth();
    const { isMobile } = useMainWindowHost();
    const [value, setValue] = React.useState(0);

    const [loading, setLoading] = useState(false);
    const [processCompleted, setProcessCompleted] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const [buttonValue, setButtonValue] = useState('Next');

    const [streamInformationForm, setStreamInformationForm] = useState<StreamInformationForm>({
        ...defaultStreamInformationForm,
        isSubscribersOnly: false,
    });

    const [streamPrivateData] = useDocumentData<StreamPrivateData>(
        firestore.collection(firestoreCollections.livestreamsPrivate).doc(currentUser.userId),
    );

    useEffect(() => {
        if (processCompleted) setButtonValue('Close');
        else if (value === 2) setButtonValue('Activate stream');
        else setButtonValue('Next');
    }, [value, processCompleted]);

    useEffect(() => {
        if (!props.currentStream) return;

        setStreamInformationForm({
            streamName: props.currentStream.title,
            // streamDescription: props.currentStream.description,
            streamContent: props.currentStream.content,
            isSubscribersOnly: props.currentStream.subscribersOnly,
        });
    }, [props.currentStream]);

    useEffect(() => {
        if (!streamPrivateData) return;

        if (value === 1) {
            if (streamPrivateData.apiVideoReady && streamPrivateData.broadcasting) {
                setButtonDisabled(false);
                setLoading(false);
            } else if (!(streamPrivateData.apiVideoReady || streamPrivateData.broadcasting)) {
                setLoading(true);
                setButtonDisabled(true);
            } else {
                setButtonDisabled(true);
                setLoading(false);
            }
        }
        [];
    }, [streamPrivateData, value]);

    useEffect(() => {
        if (props.open) {
            setValue(0);
            setProcessCompleted(false);
            setLoading(false);
        }
    }, [props.open]);

    useEffect(() => {
        if (value === 0) {
            setButtonDisabled(
                streamInformationForm.streamName === '' ||
                    // streamInformationForm.streamDescription === '' ||
                    !streamInformationForm.streamContent.length,
            );
        }
    }, [streamInformationForm]);

    const handleClose = () => {
        props.setDialogState(false);
    };

    const handleNextButtonClicked = async (): Promise<void> => {
        if (processCompleted) {
            finishProcess();
            return;
        }

        switch (value) {
            case 0:
                setStreamInfo();
                break;
            case 1:
                setValue(2);
                break;
            case 2:
                activateStream();
                break;
        }
    };

    const setStreamInfo = async (): Promise<void> => {
        setButtonDisabled(true);
        setLoading(true);

        const streamPublicData: StreamPublicData = {
            title: streamInformationForm.streamName,
            content: streamInformationForm.streamContent,
            subscribersOnly: streamInformationForm.isSubscribersOnly as boolean,
        };

        if (props.currentStream == undefined) {
            await firestoreLiveStreams.createNewStream(
                currentUser.userId,
                currentUserPublicData.displayUsername,
                streamPublicData,
            );
        } else {
            await firestoreLiveStreams.setLivestreamInfo(streamPublicData, currentUser.userId);
        }
        setLoading(false);
        setValue(1);
    };

    const activateStream = async (): Promise<void> => {
        setLoading(true);
        await firestoreLiveStreams.activateStream(props.currentStream?.ownerId as string);
        await firestoreLiveStreams.setThumbnail(streamPrivateData?.streamId as string, currentUser.userId);

        setProcessCompleted(true);
        setLoading(false);
    };

    const resetStreamkey = () => {
        firestoreLiveStreams.resetStreamKey(currentUserPublicData.displayUsername, currentUser.userId);
    };

    const finishProcess = (): void => {
        handleClose();
    };

    const tabsStyle: CSSProperties = {
        width: '33%',
    };

    const dialogSubtitleStyle: CSSProperties = {
        fontSize: '25px',
        fontWeight: 700,
        display: 'inline',
    };

    const tabStyle: CSSProperties = {
        fontWeight: isMobile ? 500 : 700,
        lineHeight: '1',
    };

    const loadingButtonStyle: CSSProperties = {
        backgroundColor: palette['primary_d'],
        borderRadius: '20px',
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
        marginRight: '5px',
        marginBottom: '5px',
        padding: '6px 13px',
    };

    return (
        <Dialog open={props.open} onClose={handleClose} PaperProps={{ style: { width: '600px' } }}>
            <DialogTitle>
                <Typography style={dialogSubtitleStyle}>Start&nbsp;</Typography>
                <Typography style={{ ...dialogSubtitleStyle, color: palette['secondary'] }}>streaming</Typography>
            </DialogTitle>
            {!processCompleted && (
                <Box>
                    <Tabs
                        style={{ marginBottom: '0px', marginLeft: '15px', marginRight: '15px' }}
                        value={value}
                        onChange={(event, newValue) => setValue(newValue)}
                        indicatorColor="secondary"
                        textColor="secondary"
                        TabIndicatorProps={{ style: { backgroundColor: palette['text_p'] } }}
                    >
                        <Tab
                            label={
                                <Box
                                    style={{ ...tabStyle, color: value === 0 ? palette['text_p'] : palette['text_s'] }}
                                >
                                    Setup your stream
                                </Box>
                            }
                            disabled={value !== 0}
                            {...a11yProps(0)}
                            style={tabsStyle}
                        ></Tab>
                        <Tab
                            label={
                                <Box
                                    style={{ ...tabStyle, color: value === 1 ? palette['text_p'] : palette['text_s'] }}
                                >
                                    Sart your stream
                                </Box>
                            }
                            disabled={value !== 1}
                            {...a11yProps(1)}
                            style={tabsStyle}
                        ></Tab>
                        <Tab
                            label={
                                <Box
                                    style={{ ...tabStyle, color: value === 2 ? palette['text_p'] : palette['text_s'] }}
                                >
                                    Activate
                                </Box>
                            }
                            disabled={value !== 2}
                            {...a11yProps(2)}
                            style={tabsStyle}
                        ></Tab>
                    </Tabs>
                    <Divider variant="middle" style={{ height: '2px', backgroundColor: palette['primary_d'] }} />
                </Box>
            )}
            <DialogContent style={{ padding: isMobile ? '0px 20px' : '0px 40px 20px 40px' }}>
                {processCompleted ? (
                    <Box style={{ textAlign: 'center' }}>
                        <Typography variant="h5" style={{ margin: '10px' }}>
                            Congratulations!
                        </Typography>
                        <Typography> Your stream is now available to viewers </Typography>
                        <CheckCircleIcon color="secondary" style={{ fontSize: '100px' }} />
                    </Box>
                ) : (
                    <Box>
                        <TabPanel value={value} index={0}>
                            <StreamInformationTab
                                currentStream={props.currentStream}
                                streamInformationForm={streamInformationForm}
                                setStreamInformationForm={setStreamInformationForm}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <StartYourStreamTab
                                streamKey={streamPrivateData?.streamKey as string}
                                streamManifestStatus={streamPrivateData?.apiVideoReady as boolean}
                                broadcasting={streamPrivateData?.broadcasting as boolean}
                                handleResetStreamkey={resetStreamkey}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <ActivateStreamTab
                                playbackURL={props.currentStream?.playbackUrl as string}
                                thumbnailUrl={props.currentStream?.thumbnailUrl as string}
                            />
                        </TabPanel>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                {loading && value === 1 && (
                    <FormHelperText style={{ color: palette['secondary'], marginLeft: '14px', fontWeight: 600 }}>
                        *Waiting for your stream to be activated. This may take a few seconds...
                    </FormHelperText>
                )}
                <LoadingButton
                    value={buttonValue}
                    onClick={() => handleNextButtonClicked()}
                    isLoading={loading}
                    disabled={buttonDisabled}
                    loadingStyle={{ color: palette['text_p'] }}
                    style={loadingButtonStyle}
                />
            </DialogActions>
        </Dialog>
    );
};

export default StartingStreamDialog;
