import React, { useEffect, useState } from 'react';
import { Box, Dialog, Typography } from '@material-ui/core';
import { palette } from '../../../styles/theme';
import FirestoreTransactions from '../../../firestore/FirestoreTransactions';
import { IPointsPackage, ProductInformation } from '../../../models/Transaction';
import CompleteOrderDialog from '../../helper/CompleteOrderContent';
import OrderEnded from '../../helper/OrderEnded';
import PinterestIcon from '@material-ui/icons/Pinterest';
import ProductSelection from './ProductSelection';
import { useMainWindowHost } from '../../../contexts/MainWindowHostContext';

interface NewStoreDialog {
    open: boolean;
    setDialogState: (isOpen: boolean) => void;
}

const StoreDialog = (props: NewStoreDialog) => {
    const { isMobile } = useMainWindowHost();

    const firestoreTransactions: FirestoreTransactions = new FirestoreTransactions();
    const [pointsPackages, setPointsPackages] = useState([]);
    const [state, setState] = useState(0);
    const [orderSuccessful, setOrderSuccessful] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState<IPointsPackage>();

    // useEffect(() => {
    //     const getPackages = async () => {
    //         setPointsPackages(await firestoreTransactions.getPointsPackages());
    //     };
    //     getPackages();
    // }, []);

    // useEffect(() => {
    //     if (props.open) {
    //         setState(0);
    //         const payPalSdk = document.getElementById('payPalSdk') as HTMLScriptElement;
    //         payPalSdk.src =
    //             'https://www.paypal.com/sdk/js?client-id=AVXgn-q6aQNNnKx6Q38pVZCuax1xJPXGZ_Ob_064RFI8pwVfZKXxl7YRS4qt0FbnykqC0xHzuzRKa3Bp';
    //     }
    // }, [props.open]);

    const setDialogContent = () => {
        switch (state) {
            case 0: {
                return <ProductSelection pointsPackages={pointsPackages} setSelectedPackage={onProductSelection} />;
            }
            case 1: {
                const element = (
                    <Box style={{ display: 'flex', padding: '10px 0px' }}>
                        <PinterestIcon
                            style={{
                                fontSize: isMobile ? '50px' : '60px',
                                color: palette['secondary'],
                                marginRight: '10px',
                            }}
                        />
                        <Box style={{ margin: 'auto 0px' }}>
                            <Typography style={{ fontSize: isMobile ? '16px' : '19px', fontWeight: 600 }}>
                                {selectedPackage?.mainText}
                            </Typography>
                            <Typography style={{ opacity: 0.5, lineHeight: isMobile ? '1' : 'auto' }}>
                                {selectedPackage?.discountText}
                            </Typography>
                        </Box>
                    </Box>
                );
                const productInfo: ProductInformation = { display: element, product: selectedPackage, type: 'order' };
                return (
                    <CompleteOrderDialog
                        selectedProduct={productInfo}
                        setTransactionEnded={onTransactionEnded}
                        setTransactionSuccess={onTransactionSuccess}
                    />
                );
            }
            case 2: {
                if (orderSuccessful) {
                    return <OrderEnded isOrderSuccessful={orderSuccessful} setClose={handleClose} />;
                }
            }
        }
    };

    const onProductSelection = (selectedPackage: IPointsPackage) => {
        setSelectedPackage(selectedPackage);
        setState(1);
    };

    const onTransactionSuccess = (isSuccessful: boolean) => {
        setOrderSuccessful(isSuccessful);
    };

    const onTransactionEnded = () => {
        setState(2);
    };

    const handleClose = () => {
        props.setDialogState(false);
    };

    return (
        <Dialog
            open={props.open}
            PaperProps={{
                style: {
                    width: '600px',
                    margin: '18px',
                },
            }}
            onClose={handleClose}
        >
            {setDialogContent()}
        </Dialog>
    );
};

export default StoreDialog;
