import { Box, Divider, Input, Tooltip, Typography } from '@material-ui/core';
import React, { CSSProperties, useState } from 'react';
import { useAuth } from '../../../../contexts/AuthContext';
import FirestoreLiveStreams from '../../../../firestore/FirestoreLiveStreams';
import { Message } from '../../../../models/Chat';
import { palette } from '../../../../styles/theme';
import { colorSelection } from '../../../../constants/Constants';

interface ChatDonationMessageProps {
    message: Message;
    streamId: string;
}

const ChatDonationMessage = (props: ChatDonationMessageProps) => {
    const firestoreLiveStreams: FirestoreLiveStreams = new FirestoreLiveStreams();
    const { currentUser } = useAuth();

    const [subMessage, setSubMessage] = useState('');

    const donationNameTitle: CSSProperties = {
        display: 'inline',
        fontSize: '18px',
        fontWeight: 600,
    };

    const donationPointsTitle: CSSProperties = {
        display: 'inline',
        fontWeight: 800,
        fontSize: '22px',
    };

    const chatMessageStyle: CSSProperties = {
        fontSize: '15px',
        wordWrap: 'break-word',
        display: 'inline',
    };

    const badgeStyle: CSSProperties = {
        width: '15px',
        height: '15px',
        marginRight: '5px',
        marginBottom: '-2px',
        display: 'inline',
    };

    const mainContainerStyle: CSSProperties = {
        backgroundColor: palette['primary_d'],
        margin: '10px 0px',
        padding: '10px',
        borderRadius: '15px',
        boxShadow: '2px 2px 10px rgba(0,0,0,0.7)',
    };

    return (
        <Tooltip
            title={
                props.message.time && props.message.time.toDate
                    ? new Date(props.message.time.toDate()).toLocaleTimeString()
                    : ''
            }
        >
            <Box style={mainContainerStyle}>
                <Box>
                    {props.message.badgeUrl && (
                        <img src={props.message.badgeUrl} style={{ ...badgeStyle, display: 'inline' }} />
                    )}
                    <Typography style={{ ...donationNameTitle, color: colorSelection[props.message.color] }}>
                        {props.message.username}&nbsp;
                    </Typography>
                    <Typography style={donationNameTitle}>gifted</Typography>
                </Box>
                <Box>
                    <Typography style={{ ...donationPointsTitle, color: colorSelection[props.message.color] }}>
                        {props.message.points}&nbsp;
                    </Typography>
                    <Typography style={donationPointsTitle}>Points!</Typography>
                </Box>
                <Box>
                    {props.message.messages.length > 0 && (
                        <Box>
                            {props.message.messages.map((_, idx: number) => (
                                <Box key={idx}>
                                    <Divider
                                        style={{
                                            backgroundColor: colorSelection[props.message.color],
                                            height: '2px',
                                            margin: '10px 0px',
                                        }}
                                    />
                                    <Typography style={chatMessageStyle}>
                                        <span
                                            style={{
                                                width: '10px',
                                                height: '10px',
                                                backgroundColor: palette['primary_l'],
                                                padding: '3px',
                                                borderRadius: 3,
                                                color: 'white',
                                            }}
                                        >
                                            {idx + 1}/3:
                                        </span>
                                        {props.message.messages[idx]}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    )}

                    {currentUser.displayUsername === props.message.username && props.message.messages.length < 3 && (
                        <Box>
                            <Divider
                                style={{
                                    backgroundColor: colorSelection[props.message.color],
                                    height: '2px',
                                    margin: '10px 0px',
                                }}
                            />
                            <Input
                                style={{
                                    backgroundColor: palette['primary_l'],
                                    width: '100%',
                                    borderRadius: '10px',
                                    padding: '5px 10px',
                                    marginBottom: '10px',
                                }}
                                disableUnderline={true}
                                placeholder="Send another message..."
                                multiline
                                value={subMessage}
                                onChange={(event) => {
                                    setSubMessage(event.target.value);
                                }}
                            />
                            <Box style={{ display: 'flex' }}>
                                <Box
                                    style={{
                                        padding: '2px 10px',
                                        backgroundColor: palette['secondary'],
                                        borderRadius: '10px',
                                        width: 'fit-content',
                                        marginLeft: 'auto',
                                        cursor: 'pointer',
                                    }}
                                    onClick={async () => {
                                        if (subMessage == '') {
                                            return;
                                        }
                                        props.message.messages.push(subMessage);
                                        await firestoreLiveStreams.addSubMessageToDonation(
                                            props.message,
                                            props.message.id as string,
                                            props.streamId,
                                        );
                                        setSubMessage('');
                                    }}
                                >
                                    <Typography>Send</Typography>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
        </Tooltip>
    );
};

export default ChatDonationMessage;
