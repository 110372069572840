import { Box, Typography } from '@material-ui/core';
import React, { CSSProperties, SetStateAction, useState } from 'react';
import { useMainWindowHost } from '../../../../../contexts/MainWindowHostContext';
import { TipOption } from '../../../../../models/User';
import { Input } from '../../../../../styles/CustomComponents';
import { palette } from '../../../../../styles/theme';
import TipMenuOption from '../../../../helper/TipMenuOption';

interface TipMenuSetupProps {
    setTipMenu: React.Dispatch<SetStateAction<TipOption[]>>;
    tipMenu: TipOption[];
}

const TipMenuSetup = (props: TipMenuSetupProps) => {
    const { isMobile } = useMainWindowHost();

    const [pointsCost, setPointsCost] = useState('');
    const [reward, setReward] = useState('');

    const handleAddTipOption = () => {
        if (pointsCost && reward) {
            const newTipOption: TipOption = {
                points: pointsCost as unknown as number,
                reward: reward,
            };
            props.setTipMenu(
                [...props.tipMenu, newTipOption].sort((a, b) => {
                    return a.points - b.points;
                }),
            );
            setReward('');
            setPointsCost('');
        }
    };

    const handleRemoveTipOption = (index: number) => {
        props.setTipMenu((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)]);
    };

    const titlesStyle: CSSProperties = {
        display: 'inline',
        fontSize: '16px',
        fontWeight: 'bold',
    };
    return (
        <Box
            style={{
                backgroundColor: palette['primary'],
                minHeight: '100px',
                borderRadius: '15px',
                padding: '10px',
            }}
        >
            <Box>
                <Typography style={{ ...titlesStyle }}>Setup your&nbsp;</Typography>
                <Typography style={{ ...titlesStyle, color: palette['secondary'] }}>Tip</Typography>
                <Typography style={{ ...titlesStyle }}>&nbsp;Menu</Typography>
            </Box>
            <Box
                style={{
                    marginTop: '5px',
                    backgroundColor: palette['primary_l'],
                    borderRadius: '20px',
                    padding: '4px 10px',
                    marginBottom: '5px',
                }}
            >
                <Box style={{ display: isMobile ? 'block' : 'flex', alignItems: 'center' }}>
                    <Typography>Cost:</Typography>
                    <Input
                        value={pointsCost}
                        onChange={(event) => {
                            if (event.target.value === '' || /^[0-9\b]+$/.test(event.target.value)) {
                                setPointsCost(event.target.value);
                            }
                        }}
                        style={{ margin: isMobile ? '0px' : '0px 10px 0px 5px', width: '100px' }}
                    />
                    <Typography>Reward:</Typography>
                    <Box display="flex" alignItems="center">
                        <Input
                            value={reward}
                            onChange={(event) => setReward(event.target.value)}
                            style={{
                                margin: isMobile ? '0px 10px 0px 0px' : '0px 10px 0px 5px',
                                width: isMobile ? '100%' : 'auto',
                            }}
                        />
                        <Box
                            style={{
                                borderRadius: '10px',
                                backgroundColor: palette['secondary'],
                                padding: '3px 10px',
                                cursor: 'pointer',
                            }}
                            onClick={handleAddTipOption}
                        >
                            <Typography>Add</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Typography>You have {props.tipMenu.length} tip options set up</Typography>
            {props.tipMenu.length > 0 && (
                <Box
                    style={{
                        paddingTop: '9px',
                        backgroundColor: palette['primary_l'],
                        borderRadius: '20px',
                        padding: '4px 10px',
                    }}
                >
                    <TipMenuOption tipMenu={props.tipMenu} handleDeleteOption={handleRemoveTipOption} />
                </Box>
            )}
        </Box>
    );
};

export default TipMenuSetup;
