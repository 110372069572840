import React, { CSSProperties, useEffect, useState } from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import { LoadingButton } from '../../../../../styles/CustomComponents';
import { palette } from '../../../../../styles/theme';
import { useAuth } from '../../../../../contexts/AuthContext';
import { storage } from '../../../../../Firebase';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PublicInformation from './PublicInformation';
import FirestoreUsers from '../../../../../firestore/FirestoreUsers';
import PrivateInformation from './PrivateInformation';
import isEqual from 'lodash.isequal';
import ProfilePicture from './ProfilePicture';
import Banner from '../Banner';
import { useMainWindowHost } from '../../../../../contexts/MainWindowHostContext';
import { profileBannerRatio } from '../../../../../constants/Constants';

const AccountTab = () => {
    const firestoreUsers: FirestoreUsers = new FirestoreUsers();

    const { currentUser, updateProfilePicture, updateUsername, currentUserPublicData } = useAuth();
    const { isMobile } = useMainWindowHost();

    const [buttonLoading, setButtonLoading] = useState(false);
    const [usernameAvailable, setUsernameAvailable] = useState(true);
    const [profileUpdated, setProfileUpdated] = useState(false);

    const [userPublicInfo, setUserPublicInfo] = useState(currentUserPublicData);

    const [profilePictureUrl, setProfilePictureUrl] = useState(userPublicInfo.profilePictureUrl);
    const [profileBannerUrl, setProfileBannerUrl] = useState(userPublicInfo.profileBannerUrl);

    const [processCompleted, setProcessCompleted] = useState(false);

    useEffect(() => {
        setProfileUpdated(
            userPublicInfo.displayUsername.length >= 4 &&
                (!isEqual(userPublicInfo, currentUserPublicData) ||
                    profileBannerUrl != currentUserPublicData.profileBannerUrl ||
                    profilePictureUrl !== currentUserPublicData.profilePictureUrl),
        );
    }, [userPublicInfo, profilePictureUrl, profileBannerUrl]);

    useEffect(() => {
        setProfilePictureUrl(userPublicInfo.profilePictureUrl);
        setProfileBannerUrl(userPublicInfo.profileBannerUrl);
    }, [currentUserPublicData.profilePictureUrl, currentUserPublicData.profileBannerUrl]);

    const validateNewInfo = async (): Promise<boolean> => {
        if (userPublicInfo.displayUsername?.toLowerCase() === currentUser.displayUsername?.toLowerCase()) return true;

        const re = /^[A-Za-z0-9]+$/;
        if (
            userPublicInfo.displayUsername.length < 4 ||
            userPublicInfo.displayUsername.length > 20 ||
            !re.test(userPublicInfo.displayUsername)
        )
            return false;

        const usernameAvailable = await firestoreUsers.isUsernameAvailable(userPublicInfo.displayUsername);
        setUsernameAvailable(usernameAvailable);

        return usernameAvailable;
    };

    const storeProfilePicture = async (): Promise<string> => {
        if (profilePictureUrl === currentUserPublicData.profilePictureUrl) return '';

        let url = '';
        try {
            const profilePictureFile = await fetch(profilePictureUrl).then((r) => r.blob());
            if (profilePictureFile.size > 4000000) return '';

            await storage
                .ref(`users/${currentUser.userId}/profile.jpg`)
                .put(profilePictureFile)
                .then(async (res) => {
                    url = await res.ref.getDownloadURL();
                    await updateProfilePicture(url);
                });
        } catch (e) {
            console.log(e.message);
        }

        return url;
    };

    const storeProfileBanner = async (): Promise<string> => {
        if (profileBannerUrl === currentUserPublicData.profileBannerUrl) return '';

        let url = '';
        try {
            const profileBannerFile = await fetch(profileBannerUrl).then((r) => r.blob());
            if (profileBannerFile.size > 6000000) return '';

            await storage
                .ref(`users/${currentUser.userId}/banner.jpg`)
                .put(profileBannerFile)
                .then(async (res) => {
                    url = await res.ref.getDownloadURL();
                });
        } catch (e) {
            console.log(e.message);
        }

        return url;
    };

    const updateNewUsername = async (): Promise<void> => {
        if (userPublicInfo.displayUsername === currentUser.displayUsername) return;

        try {
            updateUsername(userPublicInfo.displayUsername);
        } catch (e) {
            console.log(e.message);
        }
    };

    const updateFirebaseProfile = async (): Promise<string[]> => {
        const profileUrl = await storeProfilePicture();
        const bannerUrl = await storeProfileBanner();
        await updateNewUsername();

        return [profileUrl, bannerUrl];
    };

    const updateProfile = async () => {
        setButtonLoading(true);

        if (!(await validateNewInfo())) {
            setButtonLoading(false);
            return;
        }

        const urls = await updateFirebaseProfile();

        const newProfilePictureUrl = urls[0] === '' ? userPublicInfo.profilePictureUrl : urls[0];
        const newProfileBannerUrl = urls[1] === '' ? userPublicInfo.profileBannerUrl : urls[1];
        setUserPublicInfo((prev) => ({
            ...prev,
            profilePictureUrl: newProfilePictureUrl,
            profileBannerUrl: newProfileBannerUrl,
        }));

        await firestoreUsers.updateUserPublicProfile({
            ...userPublicInfo,
            profilePictureUrl: newProfilePictureUrl,
            profileBannerUrl: newProfileBannerUrl,
        });

        setProcessCompleted(true);

        setTimeout(() => {
            setProcessCompleted(false);
            setButtonLoading(false);
            setProfileUpdated(false);
        }, 3000);
    };

    const informationContainerStyle: CSSProperties = {
        minHeight: '180px',
        marginTop: '30px',
    };

    const dividerStyle: CSSProperties = {
        backgroundColor: palette['primary'],
        marginBottom: '25px',
        height: '2px',
    };

    const saveChangesButtonStyle: CSSProperties = {
        position: 'absolute',
        bottom: isMobile ? (currentUser.isVerified ? '10px' : '40px') : '30px',
        right: isMobile ? '10px' : '60px',
    };

    return (
        <Box style={{ marginBottom: '60px' }}>
            <Box style={informationContainerStyle}>
                <Typography style={{ fontWeight: 'bold' }}> Public information </Typography>
                <Divider style={dividerStyle} />
                <ProfilePicture profilePictureUrl={profilePictureUrl} setProfilePictureUrl={setProfilePictureUrl} />
                <Banner
                    bannerWidth={isMobile ? window.innerWidth - 30 : 684}
                    bannerHeight={isMobile ? (window.innerWidth - 30) * profileBannerRatio : 684 * profileBannerRatio}
                    baseBannerUrl={currentUserPublicData.profileBannerUrl}
                    bannerUrl={profileBannerUrl}
                    setBannerUrl={setProfileBannerUrl}
                />
                <PublicInformation
                    user={userPublicInfo}
                    setUser={setUserPublicInfo}
                    usernameAvailable={usernameAvailable}
                    setUsernameAvailable={setUsernameAvailable}
                />
            </Box>
            <Box style={informationContainerStyle}>
                <Typography style={{ fontWeight: 'bold' }}> Private information </Typography>
                <Divider style={dividerStyle} />
                <PrivateInformation />
            </Box>
            <Box style={{ ...saveChangesButtonStyle, display: 'flex' }}>
                {!processCompleted && (
                    <LoadingButton
                        style={{ backgroundColor: palette['secondary'], borderRadius: '20px' }}
                        value="Save changes"
                        disabled={!profileUpdated}
                        isLoading={buttonLoading}
                        onClick={() => {
                            updateProfile();
                        }}
                    />
                )}
                {processCompleted && (
                    <CheckCircleIcon
                        color="secondary"
                        style={{ fontSize: '30px', marginLeft: '10px', marginTop: '4px' }}
                    />
                )}
            </Box>
        </Box>
    );
};

export default AccountTab;
