import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { hlsPlayerPages } from '../../../../../constants/Constants';
import HlsPlayer from '../../HlsPlayer';

interface ActivateStreamTabProps {
    playbackURL: string;
    thumbnailUrl: string;
}

const ActivateStreamTab = (props: ActivateStreamTabProps) => {
    return (
        <Box style={{ textAlign: 'center', marginTop: '20px' }}>
            <Typography style={{ marginBottom: '10px', fontSize: '18px' }}>If you see your stream</Typography>
            <HlsPlayer
                videoUrl={props.playbackURL}
                thumbnailUrl={props.thumbnailUrl}
                page={hlsPlayerPages.thumbnailPage}
            />
            <Typography style={{ fontSize: '20px', marginTop: '20px' }}> You are ready to start streaming </Typography>
        </Box>
    );
};

export default ActivateStreamTab;
