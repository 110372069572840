import React, { CSSProperties, useEffect, useState } from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import { Link, useHistory, useParams } from 'react-router-dom';
import { firestore, functions } from '../../../Firebase';
import { UserPublicData, UserSearchResult } from '../../../models/User';
import { LoadingPage } from '../../../styles/CustomComponents';
import { useMainWindowHost } from '../../../contexts/MainWindowHostContext';
import { palette } from '../../../styles/theme';
import FirestoreUsers from '../../../firestore/FirestoreUsers';
import AuthenticationDialog from '../../nav_bar/authentication/AuthenticationDialog';
import { firestoreCollections } from '../../../constants/Constants';
import { StreamPublicData } from '../../../models/Stream';
import WelcomePageActiveStream from '../welcome_page/WelcomePageActiveStream';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';

interface Params {
    query: string;
}

const SearchResultPage = () => {
    const firestoreUsers = new FirestoreUsers();
    const history = useHistory();

    const { query } = useParams<Params>();
    const { isMobile } = useMainWindowHost();

    const [searchUsers, setSearchUsers] = useState<UserPublicData[]>();
    const [searchStreamers, setSearchStreamers] = useState<StreamPublicData[]>();

    const [loading, setLoading] = useState(true);
    const [authenticationDialogOpen, setAuthenticationDialogOpen] = useState(false);

    useEffect(() => {
        const unsubscribe = history.listen(async () => {
            setLoading(true);
        });

        return unsubscribe;
    }, []);

    useEffect(() => {
        const getSearchResults = async () => {
            const searchQuery = functions.httpsCallable('searchEngineFunctions-searchQuery');
            const res: UserSearchResult[] = (await searchQuery({ query: query })).data;

            const newSearchStreamers = [];
            const newSearchUsers = [];

            for (const user of res) {
                const stream = await firestore
                    .collection(firestoreCollections.livestreamsPublic)
                    .doc(user.objectID)
                    .get();

                if (stream.exists) {
                    newSearchStreamers.push(stream.data() as StreamPublicData);
                } else {
                    const searchUser = await firestoreUsers.getUserPublicInfo(user.objectID);

                    if (!searchUser) continue;
                    newSearchUsers.push(searchUser);
                }
            }

            setSearchUsers(newSearchUsers);
            setSearchStreamers(newSearchStreamers);
            setLoading(false);
        };

        if (!query) return;
        getSearchResults();
    }, [query]);

    const searchResultStyle: CSSProperties = {
        width: 'fit-content',
        textAlign: 'center',
        margin: isMobile ? '0px 3vw 30px 0px' : '0px 50px 30px 0px',
    };

    const resultsContainerStyle: CSSProperties = {
        width: '95%',
        margin: isMobile ? '10px auto' : '30px auto',
    };

    const dividersStyle: CSSProperties = {
        backgroundColor: palette['primary'],
        height: '2px',
        marginBottom: isMobile ? '10px' : '20px',
    };

    return loading ? (
        <LoadingPage />
    ) : searchStreamers?.length === 0 && searchUsers?.length == 0 ? (
        <Box>
            <Box width="100%" height="100%">
                <Box textAlign="center" marginTop="60px">
                    <SentimentVeryDissatisfiedIcon style={{ fontSize: '50px' }} color="secondary" />
                    <Typography variant="h5" style={{ padding: '20px' }}>
                        No results!
                    </Typography>
                </Box>
            </Box>
        </Box>
    ) : (
        <Box style={resultsContainerStyle}>
            {searchStreamers && searchStreamers.length > 0 && (
                <Box>
                    <Typography style={{ fontWeight: 'bold' }}>Streamers</Typography>
                    <Divider style={dividersStyle} />
                    <Box display="flex" flexWrap={isMobile ? 'nowrap' : 'wrap'}>
                        {searchStreamers &&
                            searchStreamers.map((stream, idx) => (
                                <WelcomePageActiveStream activeStream={stream} key={idx} />
                            ))}
                    </Box>
                </Box>
            )}

            {searchUsers && searchUsers.length > 0 && (
                <Box>
                    <Typography style={{ fontWeight: 'bold' }}>Viewers</Typography>
                    <Divider style={dividersStyle} />
                    <Box display="flex" flexWrap={isMobile ? 'nowrap' : 'wrap'}>
                        {searchUsers.map((user, idx) => (
                            <Box key={idx} style={searchResultStyle}>
                                <Link to={`/${user.username}`} style={{ textDecoration: 'none' }}>
                                    <img src={user.profilePictureUrl} style={{ width: isMobile ? '50px' : '70px' }} />
                                    <Typography
                                        style={{
                                            lineHeight: 1,
                                            fontWeight: 'bold',
                                            fontSize: isMobile ? '15px' : '20px',
                                        }}
                                    >
                                        {user.username}
                                    </Typography>
                                </Link>
                            </Box>
                        ))}
                    </Box>
                </Box>
            )}

            <AuthenticationDialog open={authenticationDialogOpen} setDialogState={setAuthenticationDialogOpen} />
        </Box>
    );
};

export default SearchResultPage;
