import React, { CSSProperties, useState } from 'react';
import { Box, Button, FormHelperText, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useAuth } from '../../../../../contexts/AuthContext';
import { palette } from '../../../../../styles/theme';
import ProfilePictureEditorDialog from '../../dialogs/ProfilePictureEditorDialog';
import { useMainWindowHost } from '../../../../../contexts/MainWindowHostContext';

interface ProfilePictureProps {
    profilePictureUrl: string;
    setProfilePictureUrl: (profilePictureUrl: string) => void;
}

const ProfilePicture = (props: ProfilePictureProps) => {
    const { currentUser } = useAuth();
    const { isMobile } = useMainWindowHost();

    const [openEditorDialog, setOpenEditorDialog] = useState(false);
    const [invalidImage, setInvalidImage] = useState(false);
    const [uneditedProfilePictureUrl, setUneditedProfilePictureUrl] = useState('');

    const handleNewProfilePicture = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const img: File = event.target.files[0];

            if (!img.type.startsWith('image') || img.size > 4000000) {
                setInvalidImage(true);
                return;
            }

            setInvalidImage(false);
            setUneditedProfilePictureUrl(URL.createObjectURL(img));
            setOpenEditorDialog(true);
            event.target.value = '';
        }
    };

    const updateProfilePictureButtonStyle: CSSProperties = {
        marginLeft: isMobile ? '20px' : '40px',
        backgroundColor: palette['primary'],
        borderRadius: '20px',
        padding: '5px 10px',
    };

    const profileUpdateDiv: CSSProperties = {
        display: 'flex',
        alignItems: 'center',
    };

    const profilePictureStyle: CSSProperties = {
        height: '20vw',
        width: '20vw',
        maxHeight: '100px',
        maxWidth: '100px',
        borderRadius: '100%',
        border: `solid 3px ${palette['primary']}`,
    };

    const cancelProfilePictureUpdateButton: CSSProperties = {
        float: 'right',
        marginTop: '-5px',
        color: palette['text_s'],
        fontSize: '20px',
        padding: '0',
        visibility: props.profilePictureUrl !== currentUser.profilePictureUrl ? 'visible' : 'hidden',
    };

    return (
        <Box>
            <Box style={profileUpdateDiv}>
                <Box>
                    <IconButton
                        style={cancelProfilePictureUpdateButton}
                        onClick={() => props.setProfilePictureUrl(currentUser.profilePictureUrl as string)}
                    >
                        <CloseIcon />
                    </IconButton>
                    <img src={props.profilePictureUrl} style={profilePictureStyle} />
                </Box>
                <Button component="label" style={updateProfilePictureButtonStyle}>
                    Update profile picture
                    <input
                        accept="image/*"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={(event) => handleNewProfilePicture(event as React.ChangeEvent<HTMLInputElement>)}
                    />
                </Button>
            </Box>
            <FormHelperText
                style={{
                    color: palette['secondary'],
                    visibility: invalidImage ? 'visible' : 'hidden',
                    marginBottom: isMobile ? '10px' : '30px',
                }}
            >
                *File must be of type image and be 4MB or less.
            </FormHelperText>
            <ProfilePictureEditorDialog
                open={openEditorDialog}
                setDialogState={setOpenEditorDialog}
                profilePictureUrl={uneditedProfilePictureUrl}
                setProfilePictureUrl={props.setProfilePictureUrl}
            />
        </Box>
    );
};

export default ProfilePicture;
