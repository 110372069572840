import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from '@material-ui/core';
import React from 'react';
import { useAuth } from '../../../../contexts/AuthContext';
import { useMainWindowHost } from '../../../../contexts/MainWindowHostContext';
import FirestoreFollows from '../../../../firestore/FirestoreFollows';
import { UserPublicData } from '../../../../models/User';

interface UnfollowDialogProps {
    setDialogState: (isOpen: boolean) => void;
    setFollowing: (following: boolean) => void;
    open: boolean;
    user: UserPublicData;
}

const UnfollowDialog = (props: UnfollowDialogProps) => {
    const firestoreFollowers: FirestoreFollows = new FirestoreFollows();
    const { currentUser } = useAuth();
    const { isMobile } = useMainWindowHost();

    const unfollowUser = async () => {
        handleClose();
        props.setFollowing(false);

        await firestoreFollowers.removeFromFollowing(currentUser.userId, props.user.userId);
        await firestoreFollowers.removeFromFollowers(props.user.userId, currentUser.userId);
    };

    const handleClose = () => {
        props.setDialogState(false);
    };

    return (
        <Dialog open={props.open} onClose={handleClose} PaperProps={{ style: { borderRadius: '10px' } }}>
            <DialogTitle id="form-dialog-title">
                <Typography color="secondary" style={{ fontSize: '20px', lineHeight: '1' }}>
                    Do you really want to unfollow {props.user?.displayUsername}?
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText style={{ marginBottom: isMobile ? '10px' : '30px' }}>
                    You will no longer receive notifications from this user
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()}> Cancel </Button>
                <Button onClick={() => unfollowUser()} color="secondary">
                    Unfollow
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UnfollowDialog;
