import React, { CSSProperties, useEffect, useState } from 'react';
import { Box, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { StreamPublicData } from '../../models/Stream';
import LensRoundedIcon from '@material-ui/icons/LensRounded';
import { palette } from '../../styles/theme';
import { Link } from 'react-router-dom';
import PinterestIcon from '@material-ui/icons/Pinterest';
import { SubscriptionStar } from '../../styles/CustomComponents';
import { useMainWindowHost } from '../../contexts/MainWindowHostContext';
import FirestoreUsers from '../../firestore/FirestoreUsers';
import { UserPublicData, UserStreamingData } from '../../models/User';
import { getNumberInThousands } from '../../helpers/helpers';
import { useAuth } from '../../contexts/AuthContext';

interface SideBarActiveStreamProps {
    stream: StreamPublicData;
    isLeaderBoard: boolean;
    index?: number;
    subscription?: boolean;
}

const SideBarActiveStream = (props: SideBarActiveStreamProps) => {
    const firestoreUsers = new FirestoreUsers();

    const { isComputer } = useMainWindowHost();
    const { currentUser } = useAuth();

    const [hover, setHover] = useState('');
    const [streamOwner, setStreamOwner] = useState<UserPublicData>();
    const [streamOwnerStreamingData, setStreamOwnerStreamingData] = useState<UserStreamingData>();

    useEffect(() => {
        if (!props.stream) return;

        const setOwner = async () => {
            const owner = await firestoreUsers.getUserPublicInfo(props.stream.ownerId as string);
            const ownerStreamingInfo = await firestoreUsers.getUserStreamingData(props.stream.ownerId as string);
            setStreamOwner(owner);
            setStreamOwnerStreamingData(ownerStreamingInfo);
        };

        setOwner();
    }, [props.stream]);

    const thumbnailStyle: CSSProperties = {
        position: 'absolute',
        zIndex: 100000,
        left: `calc(${document.getElementById('sidebar')?.offsetWidth}px)`,
        width: '200px',
        height: '100px',
        borderRadius: '15px',
        border: `3px solid ${palette['primary_d']}`,
    };

    const profilePictureStyle: CSSProperties = {
        width: isComputer ? '35px' : '3vw',
        minWidth: '35px',
        borderRadius: '15px',
        margin: isComputer ? '0px' : '8px 0px',
    };

    const linkStyle: CSSProperties = {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        color: 'inherit',
    };

    const pointBoxStyle: CSSProperties = {
        height: '20px',
        display: 'flex',
        alignItems: 'center',
        padding: '3px 4px 3px 5px',
        borderRadius: '20px',
        backgroundColor: palette['primary'],
        marginRight: '-5px',
    };

    const starStyle: CSSProperties = {
        position: 'absolute',
        left: 0,
        top: 2,
        fontSize: '20px',
    };

    return (
        <Link to={`/${streamOwner?.username}`} style={linkStyle}>
            <ListItem
                button
                style={{
                    padding: '0px 8px',
                    backgroundColor: props.isLeaderBoard
                        ? (props.index as number) % 2 === 0
                            ? palette['lightPurple']
                            : ''
                        : '',
                }}
                onMouseOver={() => {
                    setHover(streamOwner?.username as string);
                }}
                onMouseLeave={() => {
                    setHover('');
                }}
            >
                <ListItemIcon style={{ minWidth: '0px', marginRight: '10px' }}>
                    <Box>
                        <img src={streamOwner?.profilePictureUrl} style={profilePictureStyle} />
                        {props.subscription && (
                            <SubscriptionStar
                                style={starStyle}
                                tier={3}
                                borderStyle={{ fontSize: '31px', color: palette['primary_l'], left: -5, top: -3 }}
                            />
                        )}
                    </Box>
                </ListItemIcon>
                <ListItemText
                    primaryTypographyProps={{ style: { fontWeight: 'bold', lineHeight: 1 } }}
                    style={{ whiteSpace: 'nowrap', overflowX: 'hidden' }}
                    primary={streamOwner?.displayUsername}
                    secondaryTypographyProps={{
                        style: {
                            fontSize: '14px',
                            color: palette['text_s'],
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            noWrap: true,
                        },
                    }}
                    secondary={
                        props.stream.active &&
                        !props.stream.bannedUsernameList?.includes(currentUser.displayUsername as string) ? (
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                {props.isLeaderBoard && (
                                    <LensRoundedIcon
                                        style={{ fontSize: '10px', color: palette['red'], marginRight: '3px' }}
                                    />
                                )}
                                {props.stream.title}
                            </span>
                        ) : (
                            'Offline'
                        )
                    }
                />
                {props.isLeaderBoard ? (
                    <Box style={pointBoxStyle}>
                        <Typography style={{ marginRight: '3px', fontWeight: 'lighter', fontSize: '15px' }}>
                            {getNumberInThousands(streamOwnerStreamingData?.monthlyScore as number)}
                        </Typography>
                        <PinterestIcon style={{ color: palette['text_p'], fontSize: '22px' }} />
                    </Box>
                ) : (
                    <Box display="flex" alignItems="center">
                        <LensRoundedIcon style={{ fontSize: '10px', color: palette['red'] }} />
                        <Typography style={{ marginLeft: '4px', color: palette['text_s'], fontSize: 15 }}>
                            {getNumberInThousands(props.stream.viewersCount as number)}
                        </Typography>
                    </Box>
                )}
                {isComputer && props.stream.active && hover === streamOwner?.username && (
                    <img src={props.stream.thumbnailUrl} style={thumbnailStyle} />
                )}
            </ListItem>
        </Link>
    );
};

export default SideBarActiveStream;
