import React, { CSSProperties, useEffect, useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { useAuth } from '../../../contexts/AuthContext';
import FirestoreUsers from '../../../firestore/FirestoreUsers';
import { Referral } from '../../../models/User';
import { a11yProps, AntTab, AntTabs, Input, LoadingButton, TabPanel } from '../../../styles/CustomComponents';
import '../../../styles/styles.module.css';
import { palette } from '../../../styles/theme';
import SignInForm from './SignInForm';
import SignUpForm from './SignUpForm';
import { useHistory, useLocation } from 'react-router';

interface AuthenticationDialogProps {
    setDialogState: (isOpen: boolean) => void;
    open: boolean;
    referral?: Referral;
}

export interface SignUpFormFields {
    username: string;
    password: string;
    passwordConfirm: string;
    email: string;
}

const defaultSignUpForm = {
    username: '',
    password: '',
    passwordConfirm: '',
    email: '',
};

const AuthenticationDialog = (props: AuthenticationDialogProps) => {
    const firestoreUsers: FirestoreUsers = new FirestoreUsers();
    const history = useHistory();
    const location = useLocation();

    const { signUp, signInWithUsername, isEmailAlreadyUsed, resetPassword } = useAuth();

    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [resetSent, setResetSent] = useState(false);

    const [resetPasswordState, setResetPasswordState] = useState(false);

    const [signUpFieldsFilled, setSignUpFieldsFilled] = useState(false);
    const [signInFieldsFilled, setSignInFieldsFilled] = useState(false);

    const [invalidSignInInfo, setInvalidSignInInfo] = useState(false);

    const [invalidEmailFormat, setInvalidEmailFormat] = useState(false);
    const [emailAlreadyUsed, setEmailAlreadyUsed] = useState(false);
    const [usernameNotAvailable, setUsernameNotAvailable] = useState(false);

    const [signUpForm, setSignUpForm] = useState<SignUpFormFields>(defaultSignUpForm);

    useEffect(() => {
        setSignUpFieldsFilled(
            signUpForm.password !== '' &&
                signUpForm.passwordConfirm !== '' &&
                signUpForm.username !== '' &&
                signUpForm.email !== '' &&
                signUpForm.password.length >= 6 &&
                signUpForm.password === signUpForm.passwordConfirm,
        );

        setSignInFieldsFilled(signUpForm.password !== '' && signUpForm.username !== '');
        setInvalidSignInInfo(false);
    }, [signUpForm]);

    useEffect(() => {
        if (props.open) {
            setResetPasswordState(false);
            setResetSent(false);
        }
    }, [props.open]);

    const handleTabChange = (newValue: number) => {
        setValue(newValue);
        resetFields();
    };

    const handleClose = () => {
        props.setDialogState(false);
        resetFields();
    };

    const resetFields = () => {
        setSignUpForm(defaultSignUpForm);
        setInvalidEmailFormat(false);
        setEmailAlreadyUsed(false);
        setUsernameNotAvailable(false);
    };

    const checkIfEmailAlreadyUsed = async (): Promise<boolean> => {
        try {
            const alreadyUsed: boolean = await isEmailAlreadyUsed(signUpForm.email);
            setEmailAlreadyUsed(alreadyUsed);
            return alreadyUsed;
        } catch (e) {
            e.code === 'auth/invalid-email'
                ? setInvalidEmailFormat(true)
                : alert('Error while signing up, please try again later');
            return true;
        }
    };

    const checkIfUsernameAlreadyUsed = async (): Promise<boolean> => {
        const usernameAvailable = await firestoreUsers.isUsernameAvailable(signUpForm.username);
        setUsernameNotAvailable(!usernameAvailable);

        return usernameAvailable;
    };

    const handleSignUp = async (): Promise<void> => {
        setLoading(true);

        const emailUsed = await checkIfEmailAlreadyUsed();
        const usernameAvailable = await checkIfUsernameAlreadyUsed();

        if (!usernameAvailable || emailUsed) {
            setLoading(false);
            return;
        }
        try {
            if (props.referral && location.pathname.endsWith('/referral')) {
                const locLength = location.pathname.length;
                history.push(location.pathname.substring(0, locLength - '/referral'.length));
            }
            await signUp(signUpForm.email, signUpForm.password, signUpForm.username, props.referral);
            handleClose();
        } catch (e) {
            e.code === 'auth/invalid-email' ? setInvalidEmailFormat(true) : alert(e.message);
            setLoading(false);
        }
    };

    const handleSignIn = async (): Promise<void> => {
        setLoading(true);

        try {
            await signInWithUsername(signUpForm.username, signUpForm.password);
            handleClose();
        } catch (e) {
            setInvalidSignInInfo(true);
            setLoading(false);
        }
    };

    const dialogSubtitleStyle: CSSProperties = {
        fontSize: '25px',
        fontWeight: 700,
    };

    const buttonTextStyle: CSSProperties = {
        fontSize: '20px',
        fontWeight: 700,
        opacity: (value && signUpFieldsFilled) || (!value && signInFieldsFilled) ? 1 : 0.2,
    };

    const buttonResetPasswordTextStyle: CSSProperties = {
        fontSize: '20px',
        fontWeight: 700,
        opacity: email == '' || resetSent ? 0.2 : 1,
    };

    const dialogContentTextStyle: CSSProperties = {
        margin: '0',
        fontSize: '16px',
        fontWeight: 600,
        color: palette['text_p'],
    };

    const textFieldsStyle: CSSProperties = {
        width: '100%',
    };

    const loadingButtonStyle: CSSProperties = {
        backgroundColor:
            (value && signUpFieldsFilled) || (!value && signInFieldsFilled)
                ? palette['secondary']
                : palette['softDarkBlue'],

        borderRadius: '10px',
        height: '36px',
        margin: '25px 0px 15px 0px',
        width: '100%',
    };

    return (
        <Dialog open={props.open} onClose={handleClose} PaperProps={{ style: { width: '500px' } }}>
            <DialogTitle>
                {value ? (
                    <Box style={{ display: 'flex' }}>
                        <Typography style={dialogSubtitleStyle}>Join</Typography>&nbsp;
                        <Typography color="secondary" style={dialogSubtitleStyle}>
                            Playerz
                        </Typography>
                        &nbsp;
                        <Typography style={dialogSubtitleStyle}>Today</Typography>
                    </Box>
                ) : (
                    <Box>
                        {resetPasswordState ? (
                            <Box style={{ display: 'flex' }}>
                                <Typography style={dialogSubtitleStyle}>Reset your </Typography>&nbsp;
                                <Typography color="secondary" style={dialogSubtitleStyle}>
                                    password
                                </Typography>
                            </Box>
                        ) : (
                            <Box style={{ display: 'flex' }}>
                                <Typography style={dialogSubtitleStyle}>Sign In to </Typography>
                                &nbsp;
                                <Typography color="secondary" style={dialogSubtitleStyle}>
                                    Playerz
                                </Typography>
                            </Box>
                        )}
                    </Box>
                )}
            </DialogTitle>
            <DialogContent>
                {resetPasswordState ? (
                    <Box>
                        <Typography style={dialogContentTextStyle}>Email</Typography>
                        <Input
                            style={textFieldsStyle}
                            autoComplete="none"
                            type="text"
                            value={email}
                            onChange={(event) => {
                                setEmail(event.target.value);
                                setResetSent(false);
                            }}
                        />
                        <LoadingButton
                            style={{
                                ...loadingButtonStyle,
                                backgroundColor: email && !resetSent ? palette['secondary'] : palette['softDarkBlue'],
                            }}
                            onClick={async () => {
                                await resetPassword(email);
                                setResetSent(true);
                            }}
                            disabled={email == '' || resetSent}
                            value={
                                resetSent ? (
                                    <Typography style={buttonResetPasswordTextStyle}>Sent</Typography>
                                ) : (
                                    <Typography style={buttonResetPasswordTextStyle}>Reset Password</Typography>
                                )
                            }
                            isLoading={loading}
                        />
                    </Box>
                ) : (
                    <Box>
                        <AntTabs
                            style={{ marginBottom: '30px' }}
                            value={value}
                            onChange={(event, newValue) => handleTabChange(newValue)}
                        >
                            <AntTab label="Sign In" {...a11yProps(0)} />
                            <AntTab label="Sign Up" {...a11yProps(1)} />
                        </AntTabs>

                        <TabPanel value={value} index={0}>
                            <SignInForm
                                setSignInForm={setSignUpForm}
                                signInForm={signUpForm}
                                invalidSignInInfo={invalidSignInInfo}
                                setResetPassword={setResetPasswordState}
                            />
                        </TabPanel>

                        <TabPanel value={value} index={1}>
                            <SignUpForm
                                setEmailAlreadyUsed={setEmailAlreadyUsed}
                                setInvalidEmailFormat={setInvalidEmailFormat}
                                setUsernameNotAvailable={setUsernameNotAvailable}
                                setSignUpForm={setSignUpForm}
                                signUpForm={signUpForm}
                                emailAlreadyUsed={emailAlreadyUsed}
                                invalidEmailFormat={invalidEmailFormat}
                                usernameNotAvailable={usernameNotAvailable}
                            />
                        </TabPanel>
                        <LoadingButton
                            style={loadingButtonStyle}
                            onClick={() => (value ? handleSignUp() : handleSignIn())}
                            disabled={value ? !signUpFieldsFilled : !signInFieldsFilled}
                            value={
                                value ? (
                                    <Typography style={buttonTextStyle}>Sign Up</Typography>
                                ) : (
                                    <Typography style={buttonTextStyle}>Sign In</Typography>
                                )
                            }
                            isLoading={loading}
                        ></LoadingButton>
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default AuthenticationDialog;
