import React, { CSSProperties, useState, useEffect } from 'react';
import { Box, Button, Dialog, Divider, Typography } from '@material-ui/core';
import { palette } from '../../../../../styles/theme';
import Banner from '../Banner';
import { LoadingButton } from '../../../../../styles/CustomComponents';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import isEqual from 'lodash.isequal';
import FirestoreUsers from '../../../../../firestore/FirestoreUsers';
import { storage } from '../../../../../Firebase';
import { useAuth } from '../../../../../contexts/AuthContext';
import SubscriptionDialogContent from '../../../streaming_view_page/dialogs/subscriptions/SubscriptionDialogContent';
import { useMainWindowHost } from '../../../../../contexts/MainWindowHostContext';
import { subscriptionBannerRatio } from '../../../../../constants/Constants';
import SubscriptionConfiguration from './SubscriptionConfiguration';

const SubscriptionsTab = () => {
    const firestoreUsers: FirestoreUsers = new FirestoreUsers();
    const { currentUser, currentUserStreamingData, currentUserPublicData, currentUserPrivateData } = useAuth();
    const { isMobile } = useMainWindowHost();

    const [processCompleted, setProcessCompleted] = useState(false);
    const [loading, setLoading] = useState(false);

    const [subscriptionsUpdated, setSubscriptionsUpdated] = useState(false);

    const [subscriptionBannerUrl, setSubscriptionBannerUrl] = useState(currentUserStreamingData?.subscriptionBannerUrl);
    const [subscription, setSubscription] = useState(currentUserStreamingData?.subscription);

    const [isPreviewOpen, setIsPreviewOpen] = useState(false);

    useEffect(() => {
        if (!currentUserStreamingData) return;

        setSubscriptionsUpdated(
            subscriptionBannerUrl !== currentUserStreamingData.subscriptionBannerUrl ||
                !isEqual(subscription, currentUserStreamingData.subscription),
        );
    }, [subscriptionBannerUrl, subscription]);

    const updateSubscriptionTier = async () => {
        setLoading(true);

        if (subscription.badgeUrl !== currentUserStreamingData.subscription.badgeUrl) {
            subscription.badgeUrl = await storeTierBadge(subscription.badgeUrl);
        }

        if (subscriptionBannerUrl !== currentUserStreamingData.subscriptionBannerUrl) {
            const url = await storeSubscriptionBanner();
            await firestoreUsers.updateUserSubscriptionBanner(currentUser.userId, url);
        }

        await firestoreUsers.updateUserSubscription(currentUser.userId, subscription);

        setProcessCompleted(true);

        setTimeout(() => {
            setProcessCompleted(false);
            setLoading(false);
            setSubscriptionsUpdated(false);
        }, 3000);
    };

    const storeSubscriptionBanner = async (): Promise<string> => {
        let url = '';
        try {
            const subscriptionBannerFile = await fetch(subscriptionBannerUrl).then((r) => r.blob());
            await storage
                .ref(`users/${currentUser.userId}/subscriptionBanner`)
                .put(subscriptionBannerFile)
                .then(async (res) => {
                    url = await res.ref.getDownloadURL();
                });
        } catch (e) {
            console.log(e.message);
        }

        return url;
    };

    const storeTierBadge = async (newBadgeUrl: string): Promise<string> => {
        let url = '';
        try {
            const badgeFile = await fetch(newBadgeUrl).then((r) => r.blob());
            if (badgeFile.size > 1000000) return '';

            await storage
                .ref(`users/${currentUser.userId}/SubscriptionBadge`)
                .put(badgeFile)
                .then(async (res) => {
                    url = await res.ref.getDownloadURL();
                });
        } catch (e) {
            console.log(e.message);
        }

        return url;
    };

    const handleClosePreview = () => {
        setIsPreviewOpen(false);
    };

    const dividerStyle: CSSProperties = {
        backgroundColor: palette['primary'],
        height: '2px',
    };

    const saveChangesButtonStyle: CSSProperties = {
        position: 'absolute',
        bottom: isMobile ? (currentUser.isVerified ? '10px' : '40px') : '30px',
        right: isMobile ? '10px' : '60px',
        display: 'flex',
    };

    const buttonStyle: CSSProperties = {
        backgroundColor: palette['secondary'],
        borderRadius: '20px',
    };

    const applicationReviewMessageStyle: CSSProperties = {
        borderRadius: '20px',
        padding: '10px 20px',
        backgroundColor: palette['primary'],
        marginBottom: '40px',
    };

    const applicationReviewTextStyle: CSSProperties = {
        color: palette['text_s'],
        lineHeight: '1',
    };

    return (
        <Box>
            {currentUserPrivateData.streamers.every((streamer) => !streamer.isVerified) && (
                <Box style={applicationReviewMessageStyle}>
                    <Typography
                        style={{
                            lineHeight: '1',
                            fontSize: '18px',
                            color: palette['secondary'],
                            marginBottom: '10px',
                        }}
                    >
                        Your application to become a streamer is currently under review.
                    </Typography>
                    <Typography style={applicationReviewTextStyle}>
                        You can still setup your description and subscriptions tabs, but you wont be able to start
                        streaming until your application is approved.
                    </Typography>
                </Box>
            )}
            <Box marginBottom="30px">
                <Typography style={{ fontWeight: 'bold' }}> Subscription banner </Typography>
                <Divider style={{ ...dividerStyle, marginBottom: '20px' }} />
                <Banner
                    baseBannerUrl={currentUserStreamingData.subscriptionBannerUrl}
                    bannerUrl={subscriptionBannerUrl}
                    setBannerUrl={setSubscriptionBannerUrl}
                    bannerWidth={isMobile ? window.innerWidth - 60 : 424}
                    bannerHeight={
                        isMobile ? (window.innerWidth - 60) * subscriptionBannerRatio : 424 * subscriptionBannerRatio
                    }
                />
                <Typography style={{ fontWeight: 'bold' }}> Your subscription </Typography>
                <Divider style={dividerStyle} />
                <Box fontStyle="italic">
                    <Typography style={{ marginBottom: '25px', fontSize: '14px', marginTop: '5px' }}>
                        Pro tip: To add emojis on windows 10, use{' '}
                        <span
                            style={{ backgroundColor: palette['primary_d'], padding: '2px 4px', borderRadius: '5px' }}
                        >
                            Win + ;
                        </span>
                    </Typography>
                </Box>

                <SubscriptionConfiguration subscription={subscription} setSubscription={setSubscription} />

                <Box style={saveChangesButtonStyle}>
                    <Button
                        onClick={() => {
                            setIsPreviewOpen(true);
                        }}
                        style={{ ...buttonStyle, marginRight: isMobile ? '10px' : '30px' }}
                    >
                        <Typography>Preview</Typography>
                    </Button>
                    {!processCompleted && (
                        <LoadingButton
                            style={buttonStyle}
                            value="Save changes"
                            disabled={!subscriptionsUpdated}
                            isLoading={loading}
                            onClick={() => {
                                updateSubscriptionTier();
                            }}
                        />
                    )}
                    {processCompleted && (
                        <CheckCircleIcon
                            color="secondary"
                            style={{ fontSize: '30px', marginLeft: '10px', marginTop: '4px' }}
                        />
                    )}
                </Box>
                <Dialog
                    open={isPreviewOpen}
                    onClose={handleClosePreview}
                    PaperProps={{ style: { margin: '18px', width: '600px' } }}
                >
                    <SubscriptionDialogContent
                        subscriptionBannerUrl={subscriptionBannerUrl}
                        profilePictureUrl={currentUserPublicData.profilePictureUrl}
                        displayUsername={currentUserPublicData.displayUsername}
                        subscription={subscription}
                    />
                </Dialog>
            </Box>
        </Box>
    );
};

export default SubscriptionsTab;
