import { Box, Tab, Typography } from '@material-ui/core';
import React, { CSSProperties, useEffect, useState } from 'react';
import { AntTabs_l, TabPanel } from '../../../../../styles/CustomComponents';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import { FollowUser, UserPublicData, UserStreamingData } from '../../../../../models/User';
import { Link } from 'react-router-dom';
import { palette } from '../../../../../styles/theme';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { firestore } from '../../../../../Firebase';
import { firestoreCollections, smoothShadow } from '../../../../../constants/Constants';
import LivestreamTab from './LivestreamTab';
import FirestoreUsers from '../../../../../firestore/FirestoreUsers';
import { StreamPublicData } from '../../../../../models/Stream';
import { useAuth } from '../../../../../contexts/AuthContext';
import ManageTab from './ManageTab';

interface ProfileTabsProps {
    user: UserPublicData;
    userStreamingData: UserStreamingData;
    currentStream: StreamPublicData;
}

const ProfileTabs = (props: ProfileTabsProps) => {
    const firestoreUsers: FirestoreUsers = new FirestoreUsers();

    const { currentUser } = useAuth();

    const [value, setValue] = useState(0);
    const [followUsers, setFollowUsers] = useState<UserPublicData[]>();
    const [follows] = useCollectionData<FollowUser>(
        firestore
            .collection(firestoreCollections.follows)
            .doc(props.user.userId)
            .collection(firestoreCollections.following),
    );

    useEffect(() => {
        async function getFollowUsers(follows: FollowUser[]) {
            const newFollowUsers = [];
            for (const follow of follows) {
                newFollowUsers.push(await firestoreUsers.getUserPublicInfo(follow.userId));
            }

            setFollowUsers(newFollowUsers);
        }

        if (!follows) return;

        getFollowUsers(follows as FollowUser[]);
    }, [follows]);

    const tabStyle: CSSProperties = {
        color: palette['text_p'],
    };

    const profilePictureStyle: CSSProperties = {
        width: '12vw',
        maxWidth: '80px',
    };

    const followProfileSyle: CSSProperties = {
        margin: '0px 3vw 1.5vw 2vw',
        textAlign: 'center',
        display: 'inline-block',
        textDecoration: 'none',
    };

    const mainContainerStyle: CSSProperties = {
        margin: '10px auto 10px auto',
        backgroundColor: palette['primary_d'],
        borderRadius: '20px',
        padding: '20px 10px 20px 10px',
        boxShadow: smoothShadow,
    };

    return (
        <Box style={mainContainerStyle}>
            <AntTabs_l value={value} onChange={(event, newValue) => setValue(newValue)} style={{ marginBottom: '1vw' }}>
                {props.userStreamingData && <Tab label="Description" style={tabStyle}></Tab>}
                <Tab label="Following" style={tabStyle}></Tab>
                {props.currentStream &&
                    props.currentStream.active &&
                    currentUser.userId === props.currentStream.ownerId && (
                        <Tab label="Stream management" style={tabStyle}></Tab>
                    )}
            </AntTabs_l>
            {props.userStreamingData && (
                <TabPanel value={value} index={0}>
                    <LivestreamTab streamerInfo={props.userStreamingData} currentStream={props.currentStream} />
                </TabPanel>
            )}
            <TabPanel value={value} index={props.userStreamingData ? 1 : 0}>
                <Box width="95%" display="flex" flexWrap="wrap" margin="auto">
                    {followUsers && followUsers.length > 0 ? (
                        followUsers.map((followUser) => (
                            <Link
                                to={`/${followUser.displayUsername.toLowerCase()}`}
                                key={followUser.userId}
                                style={followProfileSyle}
                            >
                                <img src={followUser.profilePictureUrl} style={profilePictureStyle} />
                                <Typography>{followUser.displayUsername}</Typography>
                            </Link>
                        ))
                    ) : (
                        <Box width="100%" textAlign="center">
                            <SentimentVeryDissatisfiedIcon color="secondary" style={{ fontSize: '50px' }} />
                            <Typography>{`${props.user.displayUsername} does not follow any channel!`}</Typography>
                        </Box>
                    )}
                </Box>
            </TabPanel>
            <TabPanel value={value} index={2}>
                {props.currentStream &&
                    props.currentStream.active &&
                    currentUser.userId === props.currentStream.ownerId && (
                        <ManageTab currentStream={props.currentStream} />
                    )}
            </TabPanel>
        </Box>
    );
};

export default ProfileTabs;
