import React, { CSSProperties, useState } from 'react';
import { Box, FormHelperText, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { palette } from '../../../styles/theme';
import { Input } from '../../../styles/CustomComponents';
import { SignUpFormFields } from './AuthenticationDialog';

interface SignInFormProps {
    setSignInForm: React.Dispatch<React.SetStateAction<SignUpFormFields>>;
    signInForm: SignUpFormFields;
    invalidSignInInfo: boolean;
    setResetPassword: (state: boolean) => void;
}

const SignInForm = (props: SignInFormProps) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleUsernameChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        props.setSignInForm((prev) => ({ ...prev, username: event.target.value }));
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        props.setSignInForm((prev) => ({ ...prev, password: event.target.value }));
    };

    const dialogContentTextStyle: CSSProperties = {
        margin: '0',
        fontSize: '16px',
        fontWeight: 600,
        color: palette['text_p'],
    };

    const visibilityIconStyle: CSSProperties = {
        cursor: 'pointer',
        color: palette['text_p'],
    };

    const textFieldsStyle: CSSProperties = {
        width: '100%',
    };

    return (
        <Box>
            <Typography style={dialogContentTextStyle}>Username or email</Typography>
            <Input
                style={textFieldsStyle}
                autoComplete="none"
                type="text"
                value={props.signInForm.username}
                onChange={(event) => handleUsernameChange(event)}
            />

            <Typography style={{ ...dialogContentTextStyle, marginTop: '20px' }}>Password</Typography>
            <Input
                style={textFieldsStyle}
                type={showPassword ? 'text' : 'password'}
                onChange={(event) => handlePasswordChange(event)}
                endIcon={
                    showPassword ? (
                        <Visibility style={visibilityIconStyle} onClick={() => setShowPassword(!showPassword)} />
                    ) : (
                        <VisibilityOff style={visibilityIconStyle} onClick={() => setShowPassword(!showPassword)} />
                    )
                }
            />
            <Typography
                style={{
                    color: palette['secondary'],
                    fontSize: '14px',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    width: 'fitContent',
                }}
                onClick={() => {
                    props.setResetPassword(true);
                }}
            >
                Reset Password
            </Typography>

            <FormHelperText
                style={{ color: palette['red'], visibility: props.invalidSignInInfo ? 'visible' : 'hidden' }}
            >
                *Invalid username or password{' '}
            </FormHelperText>
        </Box>
    );
};

export default SignInForm;
