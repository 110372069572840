import { Box } from '@material-ui/core';
import React, { CSSProperties, useContext, useEffect, useRef, useState } from 'react';
import NavBarComponent from '../components/nav_bar/NavBar';
import SideBarComponent from '../components/side_bar/SideBar';
import VerifiedBar from '../components/verified_bar/VerifiedBar';
import { useAuth } from './AuthContext';
import { Route, useLocation } from 'react-router-dom';
import { LoadingPage } from '../styles/CustomComponents';
import { useTheme } from './ThemeContext';
import { Overrides } from '@material-ui/core/styles/overrides';
import { palette } from '../styles/theme';

interface MainWindowHostProviderValue {
    setActiveStream: (activeStream: boolean) => void;
    isComputer: boolean;
    isMobile: boolean;
    isLandscape: boolean;
}

const MainWindowHostContext = React.createContext({} as MainWindowHostProviderValue);

export const useMainWindowHost = () => {
    return useContext(MainWindowHostContext);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MainWindowHostProvider: React.FC = ({ children }: any) => {
    const { updateThemeOverrides } = useTheme();
    const { currentUser, currentUserPrivateData, currentUserPublicData } = useAuth();
    const location = useLocation();

    const [darkBackground, setDarkBackground] = useState(false);
    const [isInfoMode, setIsInfoMode] = useState(false);
    const [activeStream, setActiveStream] = useState(false);
    const [isComputer, setIsComputer] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isLandscape, setIsLandscape] = useState(window.innerHeight < window.innerWidth);

    const mainContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const onWindowResize = () => {
            if (mainContainerRef.current?.offsetWidth) {
                setIsComputer(mainContainerRef.current?.offsetWidth > 1200);
                setIsMobile(mainContainerRef.current?.offsetWidth < 760);
            }
        };

        window.addEventListener('resize', onWindowResize);

        if (mainContainerRef.current?.offsetWidth) {
            setIsComputer(mainContainerRef.current?.offsetWidth > 1200);
            setIsMobile(mainContainerRef.current?.offsetWidth < 760);
        }
    }, [mainContainerRef]);

    useEffect(() => {
        const defaultOverrides: Overrides = {
            MuiMenuItem: {
                root: {
                    minHeight: '0px',
                },
            },
            MuiButton: { root: { height: '32px' } },
            MuiPaper: {
                rounded: { borderRadius: '30px' },
                root: { backgroundColor: palette['primary_l'] },
            },
        };

        const overrides: Overrides = isMobile
            ? {
                  ...defaultOverrides,
                  MuiButton: { root: { height: '25px' } },
                  MuiDialogTitle: { root: { padding: '10px 20px', lineHeight: '1' } },
                  MuiDialogContent: { root: { padding: '0px 20px' } },
                  MuiDialogActions: { root: { padding: '10px' } },
              }
            : defaultOverrides;

        updateThemeOverrides(overrides);
    }, [isMobile]);

    useEffect(() => {
        const locationWithoutDarkBackground = children.props.children?.filter((child: Route) =>
            Object.keys(child.props).includes('path'),
        );
        setIsInfoMode(location.pathname.startsWith('/help/'));
        setDarkBackground(
            !locationWithoutDarkBackground.map((loc: Route) => loc.props.path).includes(location.pathname),
        );
    }, [location]);

    useEffect(() => {
        window.addEventListener('resize', () => setIsLandscape(window.innerHeight < window.innerWidth));

        setIsLandscape(window.innerHeight < window.innerWidth);
    }, []);

    const value: MainWindowHostProviderValue = {
        setActiveStream,
        isComputer,
        isMobile,
        isLandscape,
    };

    const mainContentBoxStyle: CSSProperties = {
        boxSizing: 'border-box',
        MozBoxSizing: 'border-box',
        WebkitBoxSizing: 'border-box',
        paddingTop: isMobile ? '55px' : '70px',
        flexGrow: 1,
        height: '100%',
        maxWidth: '100%',
    };

    const backgroundStyle: CSSProperties = {
        height: '100%',
        width: '100%',
        backgroundSize: 'cover',
        backgroundImage: `${
            darkBackground ? 'linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), ' : ''
        }url(${'https://firebasestorage.googleapis.com/v0/b/playerz-e7a0f.appspot.com/o/background.png?alt=media&token=506e28ed-8e7b-48d4-9cc5-8b916254d03c'})`,
    };

    return (
        <MainWindowHostContext.Provider value={value}>
            <div style={backgroundStyle} ref={mainContainerRef}>
                <NavBarComponent />
                {currentUser && !currentUser.isAnonymous && !currentUser.isVerified && (
                    <Box style={{ position: 'absolute', bottom: 0, width: '100%', zIndex: 100000 }}>
                        <VerifiedBar />
                    </Box>
                )}
                <Box height="100%" display="flex">
                    {!isMobile && <SideBarComponent activeStream={activeStream} infoMode={isInfoMode} />}

                    <Box style={mainContentBoxStyle}>
                        {currentUser &&
                        (currentUser.isAnonymous ? true : currentUserPublicData && currentUserPrivateData) ? (
                            <Box style={{ position: 'relative', height: '100%' }}>{children}</Box>
                        ) : (
                            <LoadingPage />
                        )}
                    </Box>
                </Box>
            </div>
            )
        </MainWindowHostContext.Provider>
    );
};
