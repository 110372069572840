export const SERVER_URL = 'http://localhost:5000/';

export const hlsPlayerPages = {
    streamingPage: 'streamingPage',
    thumbnailPage: 'thumbnailPage',
    welcomePage: 'welcomePage',
};

export const firestoreCollections = {
    userPublic: 'users_public_data',
    userPrivate: 'users_private_data',
    userStreaming: 'users_streaming_data',
    transactions: 'paypal_transactions',
    livestreamsPublic: 'livestreams_public',
    livestreamsPrivate: 'livestreams_private',
    messages: 'messages',
    chat: 'chat',
    viewers: 'viewers',
    follows: 'follows',
    followers: 'followers',
    following: 'following',
    notifications: 'notifications',
    subscriptions: 'subscriptions',
    subscribers: 'subscribers',
    subscribedTo: 'subscribed_to',
};

export const paypalTransactionTypes = {
    order: 'order',
    subscription: 'subscription',
};

export const notificationReplacements = {
    username: '{username}',
    displayUsername: '{displayUsername}',
};

export const smoothShadow =
    '-2.8px 2.8px 2.2px rgba(0, 0, 0, 0.034), -6.7px 6.7px 5.3px rgba(0, 0, 0, 0.048), -12.5px 12.5px 10px rgba(0, 0, 0, 0.06)';

export const darkShadow = '-4px 4px 15px rgba(0, 0, 0, 0.7)';

export const mediumShadow = '-4px 4px 15px rgba(0, 0, 0, 0.4)';

export const defaultProfilePictureUrl =
    'https://firebasestorage.googleapis.com/v0/b/playerz-e7a0f.appspot.com/o/default_profile_picture.svg?alt=media&token=197cd881-0348-4a70-8b42-4411fe156985';

export const profileBannerRatio = 0.26;
export const subscriptionBannerRatio = 0.47;

export const colorSelection: { [color: string]: string } = {
    red: '#FE3752',
    purple: '#BD37FE',
    turquoise: '#37FEE3',
    blue: '#37C1FE',
    yellow: '#FFFF6B',
    pink: '#FF8BF0',
    orange: '#ff930f',
    green: '#37FE5F',
};

export const settingTabStreamer = ['account', 'description', 'subscription', 'streamers', 'wallet'];

export const settingTabViewer = ['account', 'becomeastreamer', 'wallet'];
