import { CSSProperties } from '@material-ui/styles';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { hlsPlayerPages, mediumShadow, smoothShadow } from '../../../constants/Constants';
import { useMainWindowHost } from '../../../contexts/MainWindowHostContext';
import styles from '../../../styles/styles.module.css';
import { palette } from '../../../styles/theme';
import Plyr, { Options } from 'plyr';
import Hls from 'hls.js';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { useResizeDetector } from 'react-resize-detector';

interface HlsPlayerProps {
    videoUrl: string;
    thumbnailUrl: string;
    page: string;
    cantWatchStream?: boolean;
    subscriptionTier?: number;
}

const HlsPlayer = (props: HlsPlayerProps) => {
    const { isMobile } = useMainWindowHost();

    const [loading, setLoading] = useState(true);

    const { width, height, ref } = useResizeDetector();
    const playerInstance = useRef<Plyr | null>(null);
    const [maxHeight, setMaxHeight] = useState('calc(100vh - 80px)');

    const playerStyles = new Map<string, CSSProperties>([
        [hlsPlayerPages.streamingPage, { position: 'relative', top: '0px', borderRadius: isMobile ? '0px' : '20px' }],
        [
            hlsPlayerPages.thumbnailPage,
            { position: 'relative', top: '0px', borderRadius: '20px', border: `3px solid ${palette['softDarkBlue']}` },
        ],
        [
            hlsPlayerPages.welcomePage,
            {
                boxShadow: isMobile ? `${smoothShadow}, -10px 10px #391732` : `${mediumShadow}, -30px 30px #391732`,
                borderRadius: '20px',
            },
        ],
    ]);

    document.addEventListener('fullscreenchange', () => {
        setMaxHeight(document.fullscreenElement !== null ? '100vh' : 'calc(100vh - 80px)');
    });

    useEffect(() => {
        if (!height || !width || !ref.current) return;

        if ((ref.current as HTMLVideoElement).readyState > 0) {
            setLoading(false);
        }

        const el = document.getElementsByClassName('plyr')[0] as HTMLElement;
        if (width / height > 1.78 && (ref.current as HTMLVideoElement).readyState > 0) {
            const newWidth = height * 1.777777;
            el.style.width = `${(newWidth / el.offsetWidth) * 100}%`;
        } else {
            el.style.width = '100%';
        }
    }, [height, ref]);

    useEffect(() => {
        if (!ref.current) return;

        ref.current.onplay = () => {
            if (!ref.current) return;
            ref.current.currentTime += 1000000;
        };
    }, [ref]);

    useLayoutEffect(() => {
        const options: Options = {
            autoplay: true,
            controls: ['play-large', 'play', 'mute', 'volume', 'captions', 'pip', 'airplay', 'fullscreen'],
        };
        playerInstance.current = new Plyr(ref.current as HTMLVideoElement, options);

        const hlsConfig = {
            startLevel: 3,
        };
        const hls = new Hls(hlsConfig);

        hls.loadSource(props.videoUrl);
        hls.attachMedia(ref.current);
        window.hls = hls;

        playerInstance.current?.on('loadedmetadata', () => {
            playerInstance.current?.play();
        });

        return () => {
            playerInstance.current?.destroy();
        };
    }, []);

    // useEffect(() => {
    //     // setToFullScreen();
    // }, [isMobile, isLandscape]);

    // const setToFullScreen = () => {
    //     if (videoPlayerRef.current?.requestFullscreen && isMobile && isLandscape) {
    //         console.log('full screen');
    //         videoPlayerRef.current?.requestFullscreen().catch((err) => console.log(err.message));
    //     } else if (document.exitFullscreen && document.fullscreenElement) {
    //         console.log('exiting full screen');
    //         document.exitFullscreen();
    //     }
    // };

    return (
        <div style={{ width: '100%', ...playerStyles.get(props.page) }}>
            {props.cantWatchStream && (
                <Box
                    style={{
                        position: 'relative',
                        top: `calc(${height}px / 2.5)`,
                        zIndex: 10,
                        textAlign: 'center',
                        height: '0',
                    }}
                >
                    <Typography>This content is for subscribers only</Typography>
                    <Typography>Click on the subscribe button to access this content</Typography>
                </Box>
            )}
            {loading && (
                <Box
                    style={{
                        position: 'relative',
                        top: `calc(${height}px / 2.5)`,
                        zIndex: 10,
                        textAlign: 'center',
                        height: '0',
                    }}
                >
                    <CircularProgress style={{ color: palette['text_s'] }} />
                </Box>
            )}
            <video
                ref={ref}
                autoPlay
                muted
                style={{
                    backgroundColor: palette['softDarkBlue'],
                    outline: 'none',
                    maxHeight: maxHeight,
                }}
                className={props.cantWatchStream ? styles.blurredVideo : ''}
            />
        </div>
    );
};

export default React.memo(HlsPlayer);
