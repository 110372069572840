import {
    Box,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    FormHelperText,
    Tab,
    Typography,
} from '@material-ui/core';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useAuth } from '../../../../contexts/AuthContext';
import { a11yProps, AntTabs, Input, LoadingButton, TabPanel } from '../../../../styles/CustomComponents';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { palette } from '../../../../styles/theme';

interface EmailModificationDialogProps {
    setDialogState: (isOpen: boolean) => void;
    open: boolean;
    setEmail: (email: string) => void;
    email: string;
}

const EmailModificationDialog = (props: EmailModificationDialogProps) => {
    const { updateEmail, isEmailAlreadyUsed, reauthenticateUser } = useAuth();
    const [value, setValue] = useState(0);

    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState('');
    const [emailConfirm, setEmailConfirm] = useState('');
    const [password, setPassword] = useState('');

    const [invalidEmailFormat, setInvalidEmailFormat] = useState(false);
    const [emailAlreadyUsed, setEmailAlreadyUsed] = useState(false);
    const [emailsDontMatch, setEmailsDontMatch] = useState(false);
    const [invalidPassword, setInvalidPassword] = useState(false);
    const [processCompleted, setProcessCompleted] = useState(false);

    useEffect(() => {
        setEmailsDontMatch(email !== emailConfirm);
    }, [email, emailConfirm]);

    useEffect(() => {
        if (props.open) {
            setValue(0);
        }
    }, [props.open]);

    const checkIfEmailAlreadyUsed = async (): Promise<boolean> => {
        try {
            const alreadyUsed: boolean = (await isEmailAlreadyUsed(email as string)) && email != props.email;
            setEmailAlreadyUsed(alreadyUsed);
            return alreadyUsed;
        } catch (e) {
            e.code === 'auth/invalid-email'
                ? setInvalidEmailFormat(true)
                : alert('Error while signing up, please try again later');
            return true;
        }
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setEmail(event.target.value);
        setEmailAlreadyUsed(false);
        setInvalidEmailFormat(false);
    };

    const handleEmailConfirmChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setEmailConfirm(event.target.value);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const handleClose = () => {
        props.setDialogState(false);

        setInvalidEmailFormat(false);
        setEmailAlreadyUsed(false);
        setProcessCompleted(false);
        setInvalidPassword(false);

        setEmail(props.email);
        setEmailConfirm(props.email);
    };

    const handleTabChange = (newValue: number) => {
        setValue(newValue);
        setPassword('');
        setInvalidPassword(false);
    };

    const handleButtonClick = async () => {
        if (processCompleted) {
            props.setEmail(email);
            handleClose();
            return;
        }

        setLoading(true);

        if (!value) {
            await validateNewEmail();
        } else {
            await updateToNewEmail();
        }

        setLoading(false);
    };

    const updateToNewEmail = async () => {
        try {
            await reauthenticateUser(props.email, password);
            await updateEmail(email);
            setProcessCompleted(true);
        } catch (e) {
            setInvalidPassword(true);
        }
    };

    const getButtonLabel = (): string => {
        if (processCompleted) {
            return 'Finish';
        }

        return value ? 'Confirm' : 'Next';
    };

    const validateNewEmail = async () => {
        const alreadyUsed = await checkIfEmailAlreadyUsed();
        if (!alreadyUsed) setValue(1);
    };

    const tabStyle: CSSProperties = {
        width: '50%',
    };

    const tabsStyle: CSSProperties = {
        marginLeft: '20px',
        marginRight: '20px',
    };

    return (
        <Dialog open={props.open} onClose={handleClose} PaperProps={{ style: { width: '100%', maxWidth: '450px' } }}>
            {processCompleted ? (
                <Box style={{ textAlign: 'center', marginTop: '50px' }}>
                    <Typography variant="h5" style={{ margin: '10px' }}>
                        Email successfully updated
                    </Typography>
                    <CheckCircleIcon color="secondary" style={{ fontSize: '100px' }} />
                </Box>
            ) : (
                <Box>
                    <DialogTitle id="form-dialog-title">
                        <Typography color="secondary" style={{ fontSize: '25px' }}>
                            {value ? 'Confirm your password' : 'Modify your email'}
                        </Typography>
                    </DialogTitle>
                    <AntTabs
                        value={value}
                        onChange={(event, newValue) => handleTabChange(newValue)}
                        style={{ ...tabsStyle, marginBottom: '40px' }}
                    >
                        <Tab label="Email modification" {...a11yProps(0)} style={tabStyle} />
                        <Tab label="Password confirmation" {...a11yProps(1)} style={tabStyle} disabled={value === 0} />
                    </AntTabs>

                    <TabPanel value={value} index={0}>
                        <Box style={{ ...tabsStyle, marginBottom: '20px' }}>
                            <DialogContentText style={{ margin: '0' }}>New email</DialogContentText>
                            <Input
                                type="email"
                                autoComplete="email"
                                onChange={(event) => handleEmailChange(event)}
                                style={{ width: '100%' }}
                            />
                            <FormHelperText
                                style={{
                                    color: palette['secondary'],
                                    visibility: invalidEmailFormat || emailAlreadyUsed ? 'visible' : 'hidden',
                                }}
                            >
                                {invalidEmailFormat
                                    ? '*Invalid email format'
                                    : '*This email is already used by another account'}
                            </FormHelperText>
                        </Box>
                        <Box style={tabsStyle}>
                            <DialogContentText style={{ margin: '0' }}>Confirm email</DialogContentText>
                            <Input
                                type="email"
                                autoComplete="email"
                                onChange={(event) => handleEmailConfirmChange(event)}
                                style={{ width: '100%' }}
                            />
                            <FormHelperText
                                style={{
                                    color: palette['secondary'],
                                    visibility: emailsDontMatch ? 'visible' : 'hidden',
                                }}
                            >
                                *Email adresses must match
                            </FormHelperText>
                        </Box>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Box style={tabsStyle}>
                            <DialogContentText style={{ margin: '0' }}>Password</DialogContentText>

                            <Input
                                type="password"
                                onChange={(event) => handlePasswordChange(event)}
                                style={{ width: '100%' }}
                            />
                            <FormHelperText
                                style={{
                                    color: palette['secondary'],
                                    visibility: invalidPassword ? 'visible' : 'hidden',
                                }}
                            >
                                *Invalid password
                            </FormHelperText>
                        </Box>
                    </TabPanel>
                </Box>
            )}
            <DialogActions>
                <LoadingButton
                    value={getButtonLabel()}
                    isLoading={loading}
                    onClick={async () => {
                        handleButtonClick();
                    }}
                    disabled={emailsDontMatch || email === props.email}
                />
            </DialogActions>
        </Dialog>
    );
};

export default EmailModificationDialog;
