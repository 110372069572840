import React, { CSSProperties } from 'react';
import { Box, Tab } from '@material-ui/core';
import { AntTabs, NotFoundPage, TabPanel } from '../../../styles/CustomComponents';
import { palette } from '../../../styles/theme';
import { useAuth } from '../../../contexts/AuthContext';
import AccountTab from './tabs/account_tab/AccountTab';
import styles from '../../../styles/styles.module.css';
import WalletTab from './tabs/wallet_tab/WalletTab';
import DescriptionTab from './tabs/description_tab/DescriptionTab';
import { useMainWindowHost } from '../../../contexts/MainWindowHostContext';
import SubscriptionsTab from './tabs/subscriptions_tab/SubscriptionsTab';
import BecomeAStreamerTab from './tabs/become_a_streamer_tab/BecomeAStreamerTab';
import { useHistory, useParams } from 'react-router-dom';
import StreamersTab from './tabs/add_streamer_tab/StreamersTab';
import { settingTabStreamer, settingTabViewer } from '../../../constants/Constants';

interface Params {
    tab: string;
}

const SettingsPage = () => {
    const { currentUser, currentUserStreamingData } = useAuth();
    const { isMobile } = useMainWindowHost();
    const { tab } = useParams<Params>();
    const history = useHistory();

    const tabStyle: CSSProperties = {
        color: palette['text_p'],
        fontWeight: 'bold',
    };

    const tabsStyle: CSSProperties = {
        margin: isMobile ? '10px 0px 20px 0px' : '30px 0px 20px 0px',
    };

    const mainDivStyle: CSSProperties = {
        padding: isMobile ? '10px' : '0px 60px 0px 60px',
        height: isMobile ? '100%' : '99%',
        backgroundColor: palette['primary_l'],
        borderRadius: isMobile ? '0px' : '20px',
        margin: isMobile ? '0px' : '0px 10px 10px 10px',
        boxShadow: '5px 5px 8px rgba(0, 0, 0, 0.4)',
    };

    return !currentUser.isAnonymous ? (
        <Box style={mainDivStyle} className={styles.invisibleScroll}>
            <AntTabs
                style={tabsStyle}
                value={currentUserStreamingData ? settingTabStreamer.indexOf(tab) : settingTabViewer.indexOf(tab)}
                onChange={(event, newValue) => {
                    history.replace(
                        `/settings/${
                            currentUserStreamingData ? settingTabStreamer[newValue] : settingTabViewer[newValue]
                        }`,
                    );
                }}
            >
                <Tab label="Account" style={tabStyle} />
                {!currentUserStreamingData && <Tab label="Become a streamer" style={tabStyle} />}
                {currentUserStreamingData && <Tab label="Description" style={tabStyle} />}
                {currentUserStreamingData && <Tab label="Subscriptions" style={tabStyle} />}
                {currentUserStreamingData && <Tab label="Streamers" style={tabStyle} />}
                <Tab label="Wallet" style={tabStyle} />
            </AntTabs>
            <Box>
                <TabPanel value={tab} index={'account'}>
                    <AccountTab />
                </TabPanel>
                {!currentUserStreamingData && (
                    <TabPanel value={tab} index={'becomeastreamer'}>
                        <BecomeAStreamerTab />
                    </TabPanel>
                )}
                {currentUserStreamingData && (
                    <TabPanel value={tab} index={'description'}>
                        <DescriptionTab />
                    </TabPanel>
                )}
                {currentUserStreamingData && (
                    <TabPanel value={tab} index={'subscription'}>
                        <SubscriptionsTab />
                    </TabPanel>
                )}
                {currentUserStreamingData && (
                    <TabPanel value={tab} index={'streamers'}>
                        <StreamersTab />
                    </TabPanel>
                )}
                <TabPanel value={tab} index={'wallet'}>
                    <WalletTab />
                </TabPanel>
            </Box>
        </Box>
    ) : (
        <NotFoundPage msg="Please sign in to see your settings page" />
    );
};

export default SettingsPage;
