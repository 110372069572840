import React, { useEffect, useState, CSSProperties } from 'react';
import { Box, Button, FormHelperText, Typography } from '@material-ui/core';
import { palette } from '../../../styles/theme';
import { useLocation, Link } from 'react-router-dom';
import { getParamsFromUrl } from '../../../helpers/helpers';
import { auth, functions } from '../../../Firebase';
import { Input, LoadingPage } from '../../../styles/CustomComponents';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const AuthEmailTemplate = () => {
    const location = useLocation();

    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

    const [loading, setLoading] = useState(false);
    const [passwordTooShort, setPasswordTooShort] = useState(false);
    const [passwordsDontMatch, setPassWordsDontMatch] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const [isPasswordReset, setIsPasswordReset] = useState(false);

    useEffect(() => {
        const handleAction = async (mode: string, actionCode: string) => {
            if (!mode || !actionCode) {
                setTitle('Oops!');
                setMessage('There was an error, please try again later');
            } else {
                switch (mode) {
                    case 'resetPassword':
                        handleResetPassword(actionCode);
                        return;
                    case 'recoverEmail':
                        await handleRecoverEmail(actionCode);
                        return;
                    case 'verifyEmail':
                        handleEmailVerification(actionCode);
                        return;
                }
            }
        };

        const params = getParamsFromUrl(location.search.substr(1));

        const mode = params.get('mode') as string;
        const actionCode = params.get('oobCode') as string;

        handleAction(mode, actionCode);
    }, [location]);

    useEffect(() => {
        setButtonDisabled(newPassword.length === 0 || passwordTooShort || passwordsDontMatch);
    }, [passwordTooShort, passwordsDontMatch]);

    const handleResetPassword = (actionCode: string) => {
        auth.verifyPasswordResetCode(actionCode)
            .then(() => {
                setIsPasswordReset(true);
            })
            .catch(() => {
                setTitle('Invalid or expired action');
                setMessage('Invalid or expired action code. Please reset your password again');
            });
        setIsPasswordReset(true);
    };

    const handleRecoverEmail = async (actionCode: string) => {
        setLoading(true);

        const handleRecoverEmail = functions.httpsCallable('usersFunctions-handleRecoverEmail');
        const res = await handleRecoverEmail({ actionCode: actionCode });

        if (res) {
            setTitle('Email recovery successful');
            setMessage('Your email has been successfully restored.');
        } else {
            setTitle('Invalid or expired action');
            setMessage('Invalid or expired action code. Please try recovering your email address again later');
        }

        setLoading(false);
    };

    const handleEmailVerification = (actionCode: string) => {
        auth.applyActionCode(actionCode)
            .then(() => {
                setTitle('Email verification successful');
                setMessage('Your email has been successfully verified. You can now enjoy the full Playerz experience!');
            })
            .catch(() => {
                setTitle('Invalid or expired action');
                setMessage('Invalid or expired action code. Please verify your email address again');
            });
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        if (event.target.value.length <= 50) {
            const newPasswordValue = event.target.value;
            setNewPassword(newPasswordValue);
            setPasswordTooShort(newPasswordValue.length > 0 && newPasswordValue.length < 6);
            setPassWordsDontMatch(newPasswordValue !== newPasswordConfirm);
        }
    };

    const handlePasswordConfirmChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        if (event.target.value.length <= 50) {
            const newPasswordConfirm = event.target.value;
            setNewPasswordConfirm(newPasswordConfirm);
            setPassWordsDontMatch(newPasswordConfirm !== newPassword);
        }
    };

    const handlePasswordResetButton = () => {
        if (newPassword.length < 6 || newPassword.length > 50) {
            setPasswordTooShort(true);
            return;
        }

        if (newPassword !== newPasswordConfirm) {
            setPassWordsDontMatch(true);
            return;
        }

        const params = getParamsFromUrl(location.search.substr(1));
        const actionCode = params.get('oobCode') as string;

        auth.confirmPasswordReset(actionCode, newPassword)
            .then(() => {
                setTitle('Password reset successful');
                setMessage('Your password has been successfully reset. You can now log in with your new password!');
            })
            .catch(() => {
                setTitle('Invalid or expired action');
                setMessage('Invalid or expired action code. Please try resetting your password again');
            });

        setIsPasswordReset(false);
    };

    const visibilityIconStyle: CSSProperties = {
        cursor: 'pointer',
        color: palette['text_p'],
    };

    return loading ? (
        <LoadingPage />
    ) : (
        <Box width="100%" height="100%">
            <Box
                style={{
                    width: '100%',
                    maxWidth: '500px',
                    backgroundColor: palette['primary_l'],
                    borderRadius: '20px',
                    padding: '10px 20px 20px 20px',
                    margin: '40px auto',
                }}
            >
                {isPasswordReset ? (
                    <Box>
                        <Typography
                            style={{
                                color: palette['secondary'],
                                fontWeight: 'bold',
                                fontSize: '20px',
                                marginBottom: '20px',
                            }}
                        >
                            Password recovery
                        </Typography>
                        <Typography>New password</Typography>
                        <Input
                            style={{ width: '100%' }}
                            type={showPassword ? 'text' : 'password'}
                            onChange={(event) => handlePasswordChange(event)}
                            value={newPassword}
                            endIcon={
                                showPassword ? (
                                    <Visibility
                                        style={visibilityIconStyle}
                                        onClick={() => setShowPassword(!showPassword)}
                                    />
                                ) : (
                                    <VisibilityOff
                                        style={visibilityIconStyle}
                                        onClick={() => setShowPassword(!showPassword)}
                                    />
                                )
                            }
                        />
                        <FormHelperText
                            style={{ color: palette['red'], visibility: passwordTooShort ? 'visible' : 'hidden' }}
                        >
                            *Password must be between 6 and 50 characters
                        </FormHelperText>
                        <Typography style={{ marginTop: '20px' }}>Confirm new password</Typography>
                        <Input
                            style={{ width: '100%' }}
                            type={showPassword ? 'text' : 'password'}
                            onChange={(event) => handlePasswordConfirmChange(event)}
                            value={newPasswordConfirm}
                        />
                        <FormHelperText
                            style={{ color: palette['red'], visibility: passwordsDontMatch ? 'visible' : 'hidden' }}
                        >
                            *Passwords do not match
                        </FormHelperText>
                        <Box width="100%" display="flex" justifyContent="flex-end">
                            <Button
                                style={{ marginTop: '20px', backgroundColor: palette['secondary'] }}
                                disabled={buttonDisabled}
                                onClick={() => handlePasswordResetButton()}
                            >
                                Confirm
                            </Button>
                        </Box>
                    </Box>
                ) : (
                    <Box textAlign="center">
                        <Typography
                            style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                marginBottom: '20px',
                                color: palette['secondary'],
                            }}
                        >
                            {title}
                        </Typography>
                        <Typography>{message}</Typography>
                        <Button
                            style={{
                                backgroundColor: palette['secondary'],
                                marginTop: '20px',
                            }}
                        >
                            <Link style={{ textDecoration: 'none' }} to={'/'}>
                                <Typography>Home page</Typography>
                            </Link>
                        </Button>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default AuthEmailTemplate;
