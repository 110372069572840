import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import WelcomePageComponent from './components/main_window/welcome_page/WelcomePage';
import StreamingViewPageComponent from './components/main_window/streaming_view_page/StreamingViewPage';
import { AuthProvider } from './contexts/AuthContext';
import SettingsPage from './components/main_window/settings_page/SettingsPage';
import { MainWindowHostProvider } from './contexts/MainWindowHostContext';
import { Theme, withStyles } from '@material-ui/core/styles';
import './styles.css';
import { ThemeProvider } from './contexts/ThemeContext';
import SearchResultPage from './components/main_window/search_result_page/SearchResultPage';
import InfoPageHost from './components/main_window/info_pages/InfoPageHost';

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        paypal: any;
        hls: string;
    }
}

const styles = (theme: Theme) => ({
    '@global': {
        html: {
            fontSize: 16,
            [theme.breakpoints.up('xs')]: {
                fontSize: 14,
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: 14,
            },
            [theme.breakpoints.up('md')]: {
                fontSize: 15,
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: 16,
            },
        },
    },
});

const Application = () => {
    return (
        <AuthProvider>
            <ThemeProvider>
                <BrowserRouter>
                    <MainWindowHostProvider>
                        <Switch>
                            <Route exact path="/" render={() => <WelcomePageComponent />} />
                            <Route exact path="/settings/:tab" render={() => <SettingsPage />} />
                            <Route exact path="/search/:query" render={() => <SearchResultPage />} />
                            <Route path="/help/:section" render={() => <InfoPageHost />} />
                            <Route path="/:username" render={() => <StreamingViewPageComponent />} />
                            <Route path="/:username/referral" render={() => <StreamingViewPageComponent />} />
                        </Switch>
                    </MainWindowHostProvider>
                </BrowserRouter>
            </ThemeProvider>
        </AuthProvider>
    );
};

const App = withStyles(styles)(Application);
export default App;
