import { Box, Typography } from '@material-ui/core';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import LensRoundedIcon from '@material-ui/icons/LensRounded';
import { palette } from '../../../../../styles/theme';
import { UserPublicData, UserStreamingData } from '../../../../../models/User';
import { StreamPublicData } from '../../../../../models/Stream';
import { useMainWindowHost } from '../../../../../contexts/MainWindowHostContext';
import ChatComponent from '../../chat/ChatComponent';
import ProfileHeaderButtons from './ProfileHeaderButtons';
import { Message } from '../../../../../models/Chat';

interface ProfileHeaderProps {
    user: UserPublicData;
    userStreamingData: UserStreamingData;
    currentStream: StreamPublicData;
    chatMessages: Message[];
    profileRef: React.RefObject<HTMLDivElement>;
    banned: boolean;
}

const ProfileHeader = (props: ProfileHeaderProps) => {
    const { isMobile } = useMainWindowHost();

    const [streamHeight, setStreamHeight] = useState(0);

    const headerRef = useRef<HTMLDivElement>(null);

    const isStreamActive = props.currentStream?.active && !props.banned;

    useEffect(() => {
        if (!streamHeight && headerRef.current) {
            setStreamHeight(
                (document.getElementById('activeStream')?.offsetHeight as number) + headerRef.current.offsetHeight,
            );
        }
    }, [document.getElementById('activeStream'), headerRef]);

    const handleProfilePictureClick = () => {
        if (!headerRef.current || !props.profileRef.current) return;

        window.scroll({ top: 0, behavior: 'smooth' });

        const top = headerRef.current.getBoundingClientRect().top;
        top < 200
            ? props.currentStream?.active
                ? document.getElementById('activeStream')?.scrollIntoView({ behavior: 'smooth', block: 'start' })
                : props.profileRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
            : headerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const profilePictureStyle: CSSProperties = {
        width: isMobile ? '45px' : '70px',
        height: isMobile ? '45px' : '70px',
        borderRadius: '45px',
        cursor: 'pointer',
        border: `solid 3px ${palette['primary_l']}`,
        margin: isMobile ? '0px 10px 0px 0px' : '0px 10px 0px 20px',
    };

    const centeredBox: CSSProperties = {
        display: 'flex',
        flexWrap: 'wrap',
        maxWidth: '100%',
        overflow: 'hidden',
    };

    const streamDescriptionStyle: CSSProperties = {
        lineHeight: isMobile ? '15px' : '20px',
        fontSize: isMobile ? '13px' : '16px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '100%',
    };

    const streamTagsContainerStyle: CSSProperties = {
        display: 'flex',
        overflow: 'hidden',
        borderRadius: '20px',
        marginTop: '5px',
    };

    const streamContentTagStyle: CSSProperties = {
        backgroundColor: palette['primary_l'],
        borderRadius: '20px',
        padding: '0px 10px 3px 10px',
        marginRight: '7px',
    };

    return (
        <div
            ref={headerRef}
            style={{
                ...centeredBox,
                justifyContent: 'space-between',
                height: isMobile ? '100%' : 'auto',
            }}
        >
            <Box style={centeredBox}>
                <Box display="flex" alignItems="center" marginBottom="5px" width="100%">
                    <img
                        src={props.user?.profilePictureUrl}
                        style={profilePictureStyle}
                        onClick={() => handleProfilePictureClick()}
                    />
                    <Box marginRight="20px" overflow="hidden">
                        <Box
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Box display="flex">
                                <Typography
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: isMobile ? '17px' : '20px',
                                        lineHeight: isMobile ? '20px' : '30px',
                                    }}
                                >
                                    {props.user?.displayUsername}&nbsp;&nbsp;
                                </Typography>
                                {isStreamActive && (
                                    <Box display="inline-block" lineHeight="20px">
                                        <LensRoundedIcon
                                            style={{
                                                fontSize: '10px',
                                                color: palette['red'],
                                                marginBottom: '1px',
                                            }}
                                        />
                                        <Typography
                                            style={{
                                                display: 'inline',
                                                color: palette['red'],
                                                lineHeight: isMobile ? '20px' : '30px',
                                            }}
                                        >
                                            &nbsp;LIVE
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        {isStreamActive ? (
                            <Box>
                                <Typography style={streamDescriptionStyle}>{props.currentStream.title}</Typography>
                                <Box style={streamTagsContainerStyle}>
                                    {props.currentStream.content.map((el, idx) => (
                                        <Box key={idx} style={streamContentTagStyle}>
                                            <Typography>{el}</Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        ) : (
                            <Typography
                                style={{
                                    color: palette['secondary'],
                                    fontSize: isMobile ? '16px' : '20px',
                                    fontWeight: 600,
                                }}
                            >
                                OFFLINE
                            </Typography>
                        )}
                    </Box>
                </Box>
            </Box>

            <ProfileHeaderButtons
                user={props.user}
                currentStream={props.currentStream}
                userStreamingData={props.userStreamingData}
                banned={props.banned}
            />
            {isMobile && streamHeight > 0 && isStreamActive && (
                <Box marginTop="15px" width="100%" height={`calc(100vh - ${streamHeight}px - 80px)`}>
                    <ChatComponent
                        streamId={props.currentStream.ownerId as string}
                        user={props.user}
                        userStreamingData={props.userStreamingData}
                        badgeUrl=""
                        canWatchStream={true}
                        messages={props.chatMessages}
                    />
                </Box>
            )}
        </div>
    );
};

export default ProfileHeader;
