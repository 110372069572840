import firebase from 'firebase/app';

export interface StreamPublicData {
    ownerId?: string;
    title: string;
    viewersCount?: number;
    thumbnailUrl?: string;
    content: string[];
    subscribersOnly?: boolean;
    // subscriptionTier?: number;
    playbackUrl?: string;
    startTime?: firebase.firestore.Timestamp;
    active?: boolean;
    donationReceivedCurrentLivestream?: number;
    startingScore?: number;
    bannedUsernameList?: string[];
}

export interface StreamPrivateData {
    streamId: string;
    streamKey: string;
    broadcasting: boolean;
    apiVideoReady: boolean;
}

export interface StreamInformationForm {
    streamName: string;
    streamContent: string[];
    isSubscribersOnly?: boolean;
    // subscriptionTier?: Subscription;
}

export const defaultStreamInformationForm: StreamInformationForm = {
    streamName: '',
    streamContent: [],
    isSubscribersOnly: false,
};
