import React, { CSSProperties, useEffect, useState } from 'react';
import { Box, Button, Checkbox, Divider, FormHelperText, Typography } from '@material-ui/core';
import { Input as CustomInput } from '../../../../../styles/CustomComponents';
import { useAuth } from '../../../../../contexts/AuthContext';
import { palette } from '../../../../../styles/theme';
import { useMainWindowHost } from '../../../../../contexts/MainWindowHostContext';
import { LoadingButton } from '../../../../../styles/CustomComponents';
import { functions, storage } from '../../../../../Firebase';
import { useHistory } from 'react-router';
import { settingTabStreamer } from '../../../../../constants/Constants';
import styles from '../../../../../styles/styles.module.css';
import { Link } from 'react-router-dom';

interface BecomeAStreamerTabProps {
    setDialogOpen?: (open: boolean) => void;
}

const BecomeAStreamerTab = (props: BecomeAStreamerTabProps) => {
    const history = useHistory();
    const { currentUser, currentUserStreamingData, currentUserPrivateData } = useAuth();
    const { isMobile } = useMainWindowHost();

    const [idPhotoUrl, setIdPhotoUrl] = useState('');
    const [userWithIdPhotoUrl, setUserWithIdPhotoUrl] = useState('');
    const [streamersName, setStreamersName] = useState('');

    const [streamersNameTooShort, setStreamersNameTooShort] = useState(false);
    const [loading, setLoading] = useState(false);
    const [invalidStreamersName, setInvalidStreamersName] = useState(false);
    const [applyButtonDisabled, setApplyButtonDisabled] = useState(true);
    const [validDocumentsChecked, setValidDocumentsChecked] = useState(false);
    const [termsOfAgreementChecked, setTermsOfAgreementChecked] = useState(false);
    const [invalidImage, setInvalidImage] = useState(false);

    useEffect(() => {
        setApplyButtonDisabled(
            idPhotoUrl === '' ||
                userWithIdPhotoUrl === '' ||
                streamersName === '' ||
                !validDocumentsChecked ||
                !termsOfAgreementChecked,
        );
    }, [idPhotoUrl, userWithIdPhotoUrl, streamersName, validDocumentsChecked, termsOfAgreementChecked]);

    const handleIdUpload = (
        event: React.ChangeEvent<HTMLInputElement>,
        setter: React.Dispatch<React.SetStateAction<string>>,
    ) => {
        if (event.target.files && event.target.files[0]) {
            const img: File = event.target.files[0];

            if (!img.type.startsWith('image') || img.size > 2000000) {
                setInvalidImage(true);
                return;
            }

            setInvalidImage(false);
            setter(URL.createObjectURL(img));
        }
    };

    const handleApplyButton = async () => {
        setLoading(true);

        if (currentUserPrivateData.streamers.some((streamer) => streamer.name === streamersName)) {
            setInvalidStreamersName(true);
            setLoading(false);
            return;
        }

        if (streamersName.length < 4 || streamersName.length > 20) {
            setStreamersNameTooShort(true);
            return;
        }

        let idUrl = '';
        let userWithIdUrl = '';

        const idPhotoFile = await fetch(idPhotoUrl).then((r) => r.blob());

        await storage
            .ref(`users/${currentUser.userId}/${streamersName}Id.jpg`)
            .put(idPhotoFile)
            .then(async (res) => {
                idUrl = await res.ref.getDownloadURL();
            });

        const userWithIdPhotoFile = await fetch(userWithIdPhotoUrl).then((r) => r.blob());
        await storage
            .ref(`users/${currentUser.userId}/${streamersName}WithId.jpg`)
            .put(userWithIdPhotoFile)
            .then(async (res) => {
                userWithIdUrl = await res.ref.getDownloadURL();
            });

        const createUserInfo = functions.httpsCallable('usersFunctions-createUserStreamingInfo');
        await createUserInfo({
            userId: currentUser.userId,
            idUrl: idUrl,
            userWithIdUrl: userWithIdUrl,
            name: streamersName,
        });

        props.setDialogOpen && props.setDialogOpen(false);
        history.replace(`/settings/${settingTabStreamer[settingTabStreamer.indexOf('description')]}`);
        setLoading(false);
    };

    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const re = /^[A-Za-z0-9_ ]+$/;

        if (event.target.value.length <= 40 && (event.target.value === '' || re.test(event.target.value))) {
            setStreamersName(event.target.value);
            setStreamersNameTooShort(event.target.value.length > 0 && event.target.value.length < 4);
            setInvalidStreamersName(false);
        }
    };

    const textStyle: CSSProperties = {
        lineHeight: '1.2',
        display: 'inline',
    };

    const introTextStyle: CSSProperties = {
        fontSize: '14px',
    };

    const informationBoxStyle: CSSProperties = {
        background: palette['primary'],
        borderRadius: '20px',
        padding: '20px',
        margin: isMobile ? '0px 0px 20px 0px' : '0px 20px 20px 0px',
    };

    const containerBoxStyle: CSSProperties = {
        width: isMobile ? '100%' : '40%',
        minWidth: isMobile ? '0px' : '500px',
    };

    const uploadPhotoStyle: CSSProperties = {
        backgroundColor: palette['secondary'],
        height: '30px',
        marginLeft: '15px',
    };

    const photoUploadStyle: CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
    };

    const photoStyle: CSSProperties = {
        height: '100px',
        marginBottom: '20px',
        borderRadius: '15px',
    };

    const checkboxContainerStyle: CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '15px',
    };

    const titlesStyle: CSSProperties = {
        fontWeight: 'bold',
        fontSize: '20px',
        color: palette['secondary'],
    };

    const dividersStyle: CSSProperties = {
        backgroundColor: palette['text_p'],
        marginBottom: '20px',
    };

    const rulesNumberStyle: CSSProperties = {
        fontSize: '20px',
        fontWeight: 600,
        marginRight: '10px',
    };

    const rulesStyle: CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        marginTop: '20px',
    };

    return (
        <Box display="flex" flexWrap="wrap" justifyContent={currentUserStreamingData ? 'center' : 'flex-start'}>
            {currentUser.isVerified ? (
                <Box style={{ ...informationBoxStyle, ...containerBoxStyle }}>
                    <Typography style={titlesStyle}>Information verification</Typography>
                    <Divider style={dividersStyle} />
                    <Box marginBottom="20px">
                        <Typography style={{ ...textStyle, display: 'block', marginBottom: '20px' }}>
                            For legal reasons, we must verify that you are 18 years old or older to become a streamer.
                        </Typography>
                        <Typography style={textStyle}>Please provide a valid legal document&nbsp;</Typography>
                        <Typography style={{ ...textStyle, color: palette['secondary'] }}>
                            (driver&apos;s license, passport)&nbsp;
                        </Typography>
                        <Typography style={textStyle}>as well as a photo of&nbsp;</Typography>
                        <Typography style={{ ...textStyle, color: palette['secondary'] }}>
                            yourself with that document in hand&nbsp;
                        </Typography>
                        <Typography style={textStyle}>to prove that you are of legal age.</Typography>
                    </Box>
                    <Typography
                        style={{
                            color: palette['secondary'],
                            fontSize: isMobile ? '13px' : '15px',
                            marginBottom: '10px',
                        }}
                    >
                        Note: We need to keep your documents in our database for legal reasons. We will NEVER show them
                        to anyone and you can request us to delete them if you don&apos;t want to stream anymore.
                    </Typography>
                    <Box>
                        <FormHelperText
                            style={{ color: palette['red'], visibility: invalidImage ? 'visible' : 'hidden' }}
                        >
                            *File must be of type image and be 6MB or less.
                        </FormHelperText>

                        <Box style={photoUploadStyle}>
                            <Typography style={textStyle}>Photo of your ID : </Typography>
                            <Button component="label" style={uploadPhotoStyle}>
                                Upload
                                <input
                                    accept="image/*"
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={(event) =>
                                        handleIdUpload(event as React.ChangeEvent<HTMLInputElement>, setIdPhotoUrl)
                                    }
                                />
                            </Button>
                        </Box>
                        <img src={idPhotoUrl} style={photoStyle} />
                        <Box style={photoUploadStyle}>
                            <Typography style={textStyle}>Photo of yourself holding your ID : </Typography>
                            <Button component="label" style={uploadPhotoStyle}>
                                Upload
                                <input
                                    accept="image/*"
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={(event) =>
                                        handleIdUpload(
                                            event as React.ChangeEvent<HTMLInputElement>,
                                            setUserWithIdPhotoUrl,
                                        )
                                    }
                                />
                            </Button>
                        </Box>
                        <img src={userWithIdPhotoUrl} style={photoStyle} />

                        <Box
                            style={{
                                display: 'flex',
                                marginBottom: '20px',
                            }}
                        >
                            <Typography>Streamer&apos;s name:</Typography>
                            <Box width="60%" marginLeft="20px">
                                <CustomInput
                                    value={streamersName}
                                    onChange={(event) => {
                                        handleUsernameChange(event);
                                    }}
                                    style={{
                                        border: `solid 3px ${palette['primary_d']}`,
                                        width: '100%',
                                    }}
                                />
                                <FormHelperText
                                    style={{
                                        color: palette['red'],
                                        visibility:
                                            invalidStreamersName || streamersNameTooShort ? 'visible' : 'hidden',
                                    }}
                                >
                                    {streamersNameTooShort
                                        ? "*Streamer's name must be between 4 and 20 characters"
                                        : '*This name is already in use for you channel'}
                                </FormHelperText>
                            </Box>
                        </Box>

                        <Box style={checkboxContainerStyle}>
                            <Checkbox
                                style={{ marginLeft: '-12px' }}
                                onChange={(event) => setValidDocumentsChecked(event.target.checked)}
                            />
                            <Typography style={textStyle}>
                                I confirm that the documents that I have provided are valid legal documents.
                            </Typography>
                        </Box>
                        <Box style={checkboxContainerStyle}>
                            <Checkbox
                                style={{ marginLeft: '-12px' }}
                                onChange={(event) => setTermsOfAgreementChecked(event.target.checked)}
                            />
                            <Typography style={textStyle}>I have read and accepted the rules.</Typography>
                        </Box>
                        <LoadingButton
                            value={
                                currentUserStreamingData
                                    ? 'Add streamer to your channel'
                                    : 'Apply to become a streamer!'
                            }
                            isLoading={loading}
                            onClick={() => {
                                handleApplyButton();
                            }}
                            style={{ ...uploadPhotoStyle, marginTop: '10px', float: 'right' }}
                            disabled={applyButtonDisabled}
                        />
                    </Box>
                </Box>
            ) : (
                <Box style={{ ...informationBoxStyle, ...containerBoxStyle }}>
                    <Typography style={titlesStyle}>Verify your email address</Typography>
                    <Divider style={dividersStyle} />
                    <Typography>
                        You need to verify your email address before you can apply to become a streamer.
                    </Typography>
                </Box>
            )}
            <Box style={containerBoxStyle}>
                <Box style={informationBoxStyle}>
                    <Typography style={titlesStyle}>It&lsquo;s time to have some fun!</Typography>
                    <Typography style={introTextStyle}>Thanks for streaming on Playerz.Tv!</Typography>
                    <br />
                    <Typography style={introTextStyle}>
                        Playerz is an adult streaming platform, where nudity isn’t required at all, it’s just allowed.
                        Feel free to express yourself and interact with our lovely community. You only need to follow
                        our community rules.
                    </Typography>
                    <br />
                    <Typography style={introTextStyle}>
                        Please keep in mind that everyone worldwide can see your stream content. You are the sole
                        copyright owner of your stream content! This means you can take steps to protect your
                        privacy/content, and reupload it elsewhere at will. Tell us if you have concerns about misuse of
                        your content.
                    </Typography>
                </Box>
                <Box style={informationBoxStyle}>
                    <Typography style={titlesStyle}>Rules</Typography>
                    <Divider style={dividersStyle} />
                    <Box style={rulesStyle}>
                        <Typography style={rulesNumberStyle}>1 </Typography>
                        <Typography style={introTextStyle}>
                            Showing adult content like nudity is allowed, but is not required at all.
                        </Typography>
                    </Box>
                    <Box style={rulesStyle}>
                        <Typography style={rulesNumberStyle}>2 </Typography>
                        <Typography style={introTextStyle}>You agree that you are over 18 years old.</Typography>
                    </Box>
                    <Box style={rulesStyle}>
                        <Typography style={rulesNumberStyle}>3 </Typography>
                        <Typography style={introTextStyle}>
                            It is strictly forbidden to stream and show sexual violence, rape, child porn, animal porn,
                            self-destructive behavior, harassment and other forbidden content.
                        </Typography>
                    </Box>
                    <Box style={rulesStyle}>
                        <Typography style={rulesNumberStyle}>4 </Typography>
                        <Typography style={introTextStyle}>
                            You are responsible for your content and the copyright of the games you broadcast.
                        </Typography>
                    </Box>
                    <Box style={rulesStyle}>
                        <Typography style={rulesNumberStyle}>5 </Typography>
                        <Typography style={introTextStyle}>
                            Promote and advertise Brands, Games or anything else is not allowed without the permission
                            of Playerz.
                        </Typography>
                    </Box>
                    <Box style={{ marginTop: '15px' }} className={styles.inline}>
                        <Typography style={introTextStyle}>
                            For a more exhaustive list of rules, please check our&nbsp;
                        </Typography>
                        <Link to="/help/rules" style={{ textDecoration: 'none' }}>
                            <Typography style={{ ...introTextStyle, color: palette['secondary'] }}>
                                Community Guidelines
                            </Typography>
                        </Link>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default BecomeAStreamerTab;
