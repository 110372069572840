import { Box, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import { useMainWindowHost } from '../../contexts/MainWindowHostContext';
import { UserPublicData } from '../../models/User';
import { SubscriptionStar } from '../../styles/CustomComponents';
import { palette } from '../../styles/theme';

interface SideBarInactiveStreamProps {
    user: UserPublicData;
    computerMode: boolean;
    subscription?: boolean;
}

const SideBarInactiveStream = (props: SideBarInactiveStreamProps) => {
    const { isComputer } = useMainWindowHost();

    const linkStyle: CSSProperties = {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        color: 'inherit',
    };

    const profilePictureStyle: CSSProperties = {
        width: isComputer ? '35px' : '3vw',
        minWidth: '35px',
        borderRadius: '15px',
        margin: isComputer ? '0px' : '8px 0px',
    };

    const starStyle: CSSProperties = {
        position: 'absolute',
        left: 0,
        top: 2,
        fontSize: '20px',
    };

    return (
        <Box>
            <Link key={props.user.userId} to={`/${props.user.displayUsername.toLowerCase()}`} style={linkStyle}>
                <ListItem button style={{ padding: '0px 8px' }}>
                    <ListItemIcon style={{ minWidth: '0px', marginRight: '10px' }}>
                        <img src={props.user.profilePictureUrl} style={profilePictureStyle} />
                        {props.subscription && (
                            <SubscriptionStar
                                style={starStyle}
                                tier={3}
                                borderStyle={{
                                    fontSize: '31px',
                                    color: palette['primary_l'],
                                    left: -5,
                                    top: -3,
                                }}
                            />
                        )}
                    </ListItemIcon>
                    {props.computerMode && (
                        <ListItemText
                            primaryTypographyProps={{ style: { fontWeight: 'bold', lineHeight: 1 } }}
                            style={{ whiteSpace: 'nowrap', overflowX: 'hidden' }}
                            primary={props.user.displayUsername}
                            secondary="Offline"
                        />
                    )}
                </ListItem>
            </Link>
        </Box>
    );
};

export default SideBarInactiveStream;
