import React, { CSSProperties, useState } from 'react';
import { Box, Button, FormHelperText, Typography } from '@material-ui/core';
import { palette } from '../../../../../styles/theme';
import { Input } from '../../../../../styles/CustomComponents';
import { Subscription } from '../../../../../models/User';
import { useMainWindowHost } from '../../../../../contexts/MainWindowHostContext';
import ReactQuill from 'react-quill';

interface SubscriptionConfigurationProps {
    subscription: Subscription;
    setSubscription: React.Dispatch<React.SetStateAction<Subscription>>;
}

const SubscriptionConfiguration = (props: SubscriptionConfigurationProps) => {
    const { isMobile } = useMainWindowHost();

    const [invalidImage, setInvalidImage] = useState(false);

    const handleBenefitsInputChange = (event: string) => {
        props.setSubscription((prev) => ({ ...prev, benefits: event }));
    };

    const handleMonthlyFeeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const re = /^[0-9\b]+$/;

        if (event.target.value.length <= 8 && (event.target.value === '' || re.test(event.target.value))) {
            props.setSubscription((prev) => ({ ...prev, monthlyFee: Number(event.target.value) }));
        }
    };

    const handleNewBadge = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const img: File = event.target.files[0];

            if (!img.type.startsWith('image') || img.size > 1000000) {
                setInvalidImage(true);
                return;
            }

            setInvalidImage(false);
            props.setSubscription((prev) => ({ ...prev, badgeUrl: URL.createObjectURL(img) }));
            event.target.value = '';
        }
    };

    const badgeStyle: CSSProperties = {
        width: '30px',
        height: '30px',
        marginRight: '20px',
    };

    const rowElementsStyle: CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        marginBottom: isMobile ? '10px' : '30px',
    };

    const uploadBannerStyle: CSSProperties = {
        backgroundColor: palette['primary'],
        borderRadius: '20px',
        padding: '2px 10px',
    };

    const mainContainerStyle: CSSProperties = {
        width: isMobile ? '100%' : '50%',
        minWidth: isMobile ? '0px' : '600px',
        display: isMobile ? 'block' : 'flex',
        height: '100%',
    };

    const benefitsStyle: CSSProperties = {
        minHeight: '100%',
        margin: isMobile ? '0px 50px 0px 30px' : '0px',
        flexGrow: 1,
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const modules: any = {
        toolbar: [
            [{ header: [1, 2, false] }],
            [
                'bold',
                'italic',
                'underline',
                'strike',
                'blockquote',
                { color: ['#ECECEC', '#fe3752', 'red', 'yellow', 'green', 'pink', 'blue'] },
            ],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            ['link', 'image'],
            ['clean'],
        ],
    };
    const formats: string[] = [
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'color',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
    ];

    return (
        <Box style={mainContainerStyle}>
            <Box margin="0px 50px 0px 30px">
                <Typography>Monthly fee:</Typography>
                <Input
                    style={rowElementsStyle}
                    onChange={(event) => handleMonthlyFeeChange(event)}
                    value={props.subscription.monthlyFee.toString()}
                    endIcon={
                        <Typography
                            style={{
                                margin: isMobile ? '0px 0px 15px 0px' : '0px 0px 35px -10px',
                                fontWeight: 'bold',
                                fontSize: '13px',
                            }}
                        >
                            USD
                        </Typography>
                    }
                />

                <Typography style={{ marginBottom: '10px' }}>Badge:</Typography>
                <Box style={{ ...rowElementsStyle, marginBottom: '0px' }}>
                    <img src={props.subscription.badgeUrl} style={badgeStyle} />
                    <Button component="label" style={uploadBannerStyle}>
                        Upload
                        <input
                            accept="image/*"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(event) => {
                                handleNewBadge(event as React.ChangeEvent<HTMLInputElement>);
                            }}
                        />
                    </Button>
                </Box>
                <FormHelperText
                    style={{
                        color: palette['secondary'],
                        visibility: invalidImage ? 'visible' : 'hidden',
                        marginBottom: isMobile ? '10px' : '30px',
                    }}
                >
                    *File must be of type image and be 1MB or less.
                </FormHelperText>
            </Box>
            <Box style={benefitsStyle}>
                <Typography>Benefits:</Typography>
                <ReactQuill
                    className={'subscription'}
                    theme="snow"
                    value={props.subscription.benefits}
                    onChange={(event) => handleBenefitsInputChange(event)}
                    modules={modules}
                    formats={formats}
                    style={{ margin: isMobile ? '0px 0px 25px 0px' : '0px 10px 0px 0px' }}
                />
            </Box>
        </Box>
    );
};

export default SubscriptionConfiguration;
