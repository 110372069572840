import React, { useState } from 'react';
import { Box, Button, Dialog, DialogTitle } from '@material-ui/core';
import Avatar from 'react-avatar-edit';
import { palette } from '../../../../styles/theme';
import { useMainWindowHost } from '../../../../contexts/MainWindowHostContext';

interface ProfilePictureEditorDialogProps {
    setDialogState: (open: boolean) => void;
    setProfilePictureUrl: (url: string) => void;
    open: boolean;
    profilePictureUrl: string;
}

const ProfilePictureEditorDialog = (props: ProfilePictureEditorDialogProps) => {
    const { isMobile } = useMainWindowHost();
    const [croppedProfilePicture, setCroppedProfilePicture] = useState('');

    const handleClose = () => {
        props.setDialogState(false);
    };

    const confirm = () => {
        props.setProfilePictureUrl(croppedProfilePicture);
        handleClose();
    };

    return (
        <Dialog open={props.open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle> Edit your profile picture </DialogTitle>
            <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                <Avatar
                    width={isMobile ? 300 : 600}
                    height={isMobile ? 400 : 500}
                    src={props.profilePictureUrl}
                    onClose={handleClose}
                    onCrop={(preview) => setCroppedProfilePicture(preview)}
                />
            </Box>
            <Box>
                <Button
                    style={{ backgroundColor: palette['secondary'], margin: '30px 10px 10px 10px', float: 'right' }}
                    onClick={() => confirm()}
                >
                    Confirm
                </Button>
            </Box>
        </Dialog>
    );
};

export default ProfilePictureEditorDialog;
