import React from 'react';
import { Box, Typography } from '@material-ui/core';

const Privacy = () => {
    return (
        <Box>
            <Typography>
                <h3>Playerz Privacy Policy</h3>
                Effective Date: July 10, 2021
                <br />
                <br />
                This Privacy Policy describes how your personal information is collected, used, and shared when you
                visit or make a purchase from playerz.tv (the “Site”).
                <br />
                <br />
                <h3>PERSONAL INFORMATION WE COLLECT</h3>
                When you visit the Site, we automatically collect certain information about your device, including
                information about your web browser, IP address, time zone, and some of the cookies that are installed on
                your device. Additionally, as you browse the Site, we collect information about the individual web pages
                or products that you view, what websites or search terms referred you to the Site, and information about
                how you interact with the Site. We refer to this automatically-collected information as “Device
                Information.”
                <br />
                <br />
                <h3>HOW WE COLLECT DATA</h3>
                We collect Device Information using the following technologies:
                <ul>
                    <li>
                        “Cookies” are data files that are placed on your device or computer and often include an
                        anonymous unique identifier. For more information about cookies, and how to disable cookies,
                        visit http://www.allaboutcookies.org.
                    </li>
                    <li>
                        “Log files” track actions occurring on the Site, and collect data including your IP address,
                        browser type, Internet service provider, referring/exit pages, and date/time stamps.
                    </li>
                    <li>
                        “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how
                        you browse the Site.
                    </li>
                </ul>
                Additionally when you make a purchase or attempt to make a purchase through the Site, we collect certain
                information from you, including your name, billing address, shipping address, payment information
                (including credit card numbers), email address, and phone number. We refer to this information as “Order
                Information.”
                <br />
                <br />
                When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device
                Information and Order Information.
                <br />
                <br />
                <h3>HOW DO WE USE YOUR PERSONAL INFORMATION</h3>
                We use the Order Information that we collect generally to fulfill any orders placed through the Site
                (including processing your payment information, and providing you with invoices and/or order
                confirmations). Additionally, we use this Order Information to:
                <ul>
                    <li>Communicate with you;</li>
                    <li>To improve customer service;</li>
                    <li>To personalize user experience;</li>
                    <li>To improve our Site;</li>
                    <li>Screen our orders for potential risk or fraud;</li>
                </ul>
                We use the Device Information that we collect to help us screen for potential risk and fraud (in
                particular, your IP address), and more generally to improve and optimize our Site (for example, by
                generating analytics about how our customers browse and interact with the Site, and to assess the
                success of our marketing and advertising campaigns).
                <br />
                <br />
                <h3>SHARING YOUR PERSONAL INFORMATION</h3>
                We do not sell, trade, or rent Users personal identification information to others. We may share generic
                aggregated demographic information not linked to any personal identification information regarding
                visitors and users with our business partners, trusted affiliates and advertisers for the purposes
                outlined above. We may use third-party service providers to help us operate our business and the Site or
                administer activities on our behalfs, such as sending out newsletters or surveys. We may share your
                information with these third parties for those limited purposes provided that you have given us your
                permission.
                <br />
                <br />
                Finally, we may also share your Personal Information to comply with applicable laws and regulations, to
                respond to a subpoena, search warrant or other lawful request for information we receive, or to
                otherwise protect our rights.
                <br />
                <br />
                <h3>YOUR RIGHTS</h3>
                If you are a European resident, you have the right to access personal information we hold about you and
                to ask that your personal information be corrected, updated, or deleted. If you would like to exercise
                this right, please contact us through the contact information below. Additionally, if you are a European
                resident we note that we are processing your information in order to fulfill contracts we might have
                with you (for example if you make an order through the Site), or otherwise to pursue our legitimate
                business interests listed above. Additionally, please note that your information will be transferred
                outside of Europe, including to Canada and the United States.
                <br />
                <br />
                <h3>DATA RETENTION</h3>
                When you place an order through the Site, we will maintain your Order Information for our records unless
                and until you ask us to delete this information.
                <br />
                <br />
                <h3>MINORS</h3>
                The Site is not intended for individuals under the age of 18 years old.
                <br />
                <br />
                <h3>CHANGES</h3>
                We may update this privacy policy from time to time in order to reflect, for example, changes to our
                practices or for other operational, legal or regulatory reasons.
                <br />
                <br />
                <h3>CONTACT US</h3>
                For more information about our privacy practices, if you have questions, or if you would like to make a
                complaint, please contact us by e-mail at contact@playerz.tv.
            </Typography>
        </Box>
    );
};

export default Privacy;
