import { Box, Typography } from '@material-ui/core';
import React, { CSSProperties } from 'react';
import ReactQuill from 'react-quill';
import { useMainWindowHost } from '../../../../../contexts/MainWindowHostContext';
import { palette } from '../../../../../styles/theme';

interface DescriptionColumsProps {
    setDescriptionLeft: (descriptionLeft: string) => void;
    setDescriptionRight: (descriptionRight: string) => void;
    descriptionLeft: string;
    descriptionRight: string;
}

const TextColumns = (props: DescriptionColumsProps) => {
    const { isMobile } = useMainWindowHost();

    const handleEditorChangeLeft = (state: string) => {
        props.setDescriptionLeft(state);
    };

    const handleEditorChangeRight = (state: string) => {
        props.setDescriptionRight(state);
    };

    const titlesStyle: CSSProperties = {
        display: 'inline',
        fontSize: '16px',
        fontWeight: 'bold',
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const modules: any = {
        toolbar: [
            [{ header: [1, 2, false] }],
            [
                'bold',
                'italic',
                'underline',
                'strike',
                'blockquote',
                { color: ['#ECECEC', '#fe3752', 'red', 'yellow', 'green', 'pink', 'blue'] },
            ],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            ['link', 'image'],
            ['clean'],
        ],
    };
    const formats: string[] = [
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'color',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
    ];

    return (
        <Box
            style={{
                backgroundColor: palette['primary'],
                borderRadius: '15px',
                padding: '10px',
            }}
        >
            <Typography style={{ ...titlesStyle }}>Setup your&nbsp;</Typography>
            <Typography style={{ ...titlesStyle, color: palette['secondary'] }}>description</Typography>
            <Box style={{ display: isMobile ? 'block' : 'flex', padding: isMobile ? '10px 0px' : '10px' }}>
                <Box>
                    <ReactQuill
                        theme="snow"
                        value={props.descriptionLeft}
                        onChange={handleEditorChangeLeft}
                        modules={modules}
                        formats={formats}
                        style={{ margin: isMobile ? '0px 0px 25px 0px' : '0px 10px 0px 0px' }}
                    />
                </Box>
                <Box>
                    <ReactQuill
                        theme="snow"
                        value={props.descriptionRight}
                        onChange={handleEditorChangeRight}
                        modules={modules}
                        formats={formats}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default TextColumns;
