import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { StreamPublicData } from '../../../models/Stream';
import { firestoreCollections, hlsPlayerPages, mediumShadow } from '../../../constants/Constants';
import { firestore } from '../../../Firebase';
import styles from '../../../styles/styles.module.css';
import LensRoundedIcon from '@material-ui/icons/LensRounded';
import { palette } from '../../../styles/theme';
import { Link } from 'react-router-dom';
import HlsPlayer from '../streaming_view_page/HlsPlayer';
import PinterestIcon from '@material-ui/icons/Pinterest';
import { CSSProperties } from '@material-ui/styles';
import { LoadingPage } from '../../../styles/CustomComponents';
import { useMainWindowHost } from '../../../contexts/MainWindowHostContext';
import { FollowUser, SubscriptionUser, UserPublicData, UserStreamingData } from '../../../models/User';
import { useAuth } from '../../../contexts/AuthContext';
import WelcomePageActiveStream from './WelcomePageActiveStream';
import FirestoreUsers from '../../../firestore/FirestoreUsers';
import { getNumberInThousands } from '../../../helpers/helpers';

const WelcomePageComponent = () => {
    const firestoreUsers = new FirestoreUsers();

    const { isComputer, isMobile } = useMainWindowHost();
    const { currentUser } = useAuth();

    const [loading, setLoading] = useState(true);
    const [activeSubbedAndFollowedStreams, setActiveSubbedAndFollowedStreams] = useState<StreamPublicData[]>();
    const [mostPopularStream, setMostPopularStream] = useState<StreamPublicData>();
    const [mostPopularStreamOwner, setMostPopularStreamOwner] = useState<UserPublicData>();
    const [mostPopularStreamerData, setMostPopularStreamerData] = useState<UserStreamingData>();
    const [leaderboard, setLeaderboard] = useState<StreamPublicData[]>();
    const [filteredActiveStreams, SetfilteredActiveStreams] = useState<StreamPublicData[]>([]);

    const [activeStreams, activeStreamsLoading] = useCollectionData<StreamPublicData>(
        firestore
            .collection(firestoreCollections.livestreamsPublic)
            .where('active', '==', true)
            .orderBy('donationReceivedCurrentLivestream', 'desc'),
    );

    const [leaderboardUsers, leaderboardUsersLoading] = useCollectionData<UserStreamingData>(
        firestore
            .collection(firestoreCollections.userStreaming)
            .where('monthlyScore', '>', 0)
            .orderBy('monthlyScore', 'desc')
            .limit(10),
    );

    const [followings] = useCollectionData<FollowUser>(
        firestore
            .collection(firestoreCollections.follows)
            .doc(currentUser.userId)
            .collection(firestoreCollections.following),
    );

    const [subscriptions] = useCollectionData<SubscriptionUser>(
        firestore
            .collection(firestoreCollections.subscriptions)
            .doc(currentUser.userId)
            .collection(firestoreCollections.subscribedTo),
    );

    useEffect(() => {
        if (!activeStreams) return;
        SetfilteredActiveStreams(
            activeStreams.filter((e) => !e.bannedUsernameList?.includes(currentUser.displayUsername as string)),
        );

        const setMostViewedStream = async () => {
            const maxViewerCount = Math.max(
                ...(filteredActiveStreams.map((activeStream) => activeStream.viewersCount) as number[]),
                -Infinity,
            );
            const mostViewedStream = filteredActiveStreams.find(
                (activeStream) => activeStream.viewersCount === maxViewerCount,
            );

            if (!mostViewedStream) return;

            const mostViewedStreamOwner = await firestoreUsers.getUserPublicInfo(mostViewedStream?.ownerId as string);
            const mostViewedStreamerData = await firestoreUsers.getUserStreamingData(
                mostViewedStream?.ownerId as string,
            );
            setMostPopularStreamerData(mostViewedStreamerData);
            setMostPopularStream(mostViewedStream);
            setMostPopularStreamOwner(mostViewedStreamOwner);
        };

        const subbedIds = subscriptions?.map((subbed) => subbed.userId);

        let followingIds = followings?.map((following) => following.userId);
        followingIds = followingIds?.filter((id) => !subbedIds?.includes(id));

        const activeSubbed = filteredActiveStreams.filter((stream) => subbedIds?.includes(stream.ownerId as string));
        const activeFollowed = filteredActiveStreams.filter((stream) =>
            followingIds?.includes(stream.ownerId as string),
        );

        setActiveSubbedAndFollowedStreams([...(activeSubbed || []), ...(activeFollowed || [])]);
        setMostViewedStream();
    }, [activeStreams, followings, subscriptions]);

    useEffect(() => {
        if (!leaderboardUsers) return;

        const getLeaderBoardStreams = async () => {
            const newLeaderBoard = [];
            for (const user of leaderboardUsers) {
                const stream = await firestore
                    .collection(firestoreCollections.livestreamsPublic)
                    .doc(user.userId)
                    .get();
                newLeaderBoard.push(stream.data() as StreamPublicData);
            }

            setLeaderboard(newLeaderBoard);
        };

        getLeaderBoardStreams();
    }, [leaderboardUsers]);

    useEffect(() => {
        setLoading(activeStreamsLoading || leaderboardUsersLoading);
    }, [activeStreamsLoading, leaderboardUsersLoading]);

    const topViewedContainerStyle: CSSProperties = {
        position: 'relative',
        margin: isMobile ? '5px auto' : '10px auto',
        width: isComputer ? '70%' : '90%',
        maxWidth: '1100px',
    };

    const topStreamDescriptionStyle: CSSProperties = {
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: '20px',
        backgroundColor: palette['primary_l'],
        marginRight: '10px',
        zIndex: 1,
        textDecoration: 'none',
        padding: isMobile ? '1vw' : '15px',
        boxShadow: isMobile ? '-10px 10px #391732' : mediumShadow,
        flexDirection: isMobile ? 'row' : 'column',
        width: isMobile ? 'calc(100% - 20px)' : '400px',
        marginBottom: isMobile ? '5px' : '0px',
        alignItems: isMobile ? 'center' : 'flex-start',
    };

    const topStreamProfilePicStyle: CSSProperties = {
        width: isComputer ? '4vw' : '50px',
        maxWidth: '100px',
        borderRadius: '100%',
        border: `solid 3px ${palette['primary']}`,
    };

    const topStreamAmountReceivedStyle: CSSProperties = {
        display: 'inline-flex',
        borderRadius: '20px',
        alignItems: 'center',
        border: `2px solid ${palette['primary']}`,
        padding: '1px 4px 1px 10px',
        backgroundColor: palette['primary_d'],
    };

    const topStreamContentTagStyle: CSSProperties = {
        backgroundColor: palette['primary'],
        borderRadius: '10px',
        padding: '0px 10px',
        margin: '0px 5px 5px 0px',
        wordBreak: 'break-word',
    };

    const viewersCountStyle: CSSProperties = {
        alignItems: 'center',
        display: 'inline-flex',
        backgroundColor: 'rgba(41, 53, 78, 0.7)',
        borderRadius: '20px',
        padding: '1px 10px',
    };

    const absoluteViewCount: CSSProperties = {
        zIndex: 1,
        position: 'absolute',
        top: '10px',
        left: '10px',
    };

    const lensIconStyle: CSSProperties = {
        fontSize: '15px',
        color: palette['red'],
        margin: '3px 5px 2px 0px',
    };

    const topViewedBodyStyle: CSSProperties = {
        borderRadius: '20px',
        display: isMobile ? 'block' : 'flex',
        boxShadow: `${
            isMobile ? '0px 0px #391732' : '-30px 30px #391732'
        }, -35px 35px 2.2px rgba(0, 0, 0, 0.034), -37px 37px 5.3px rgba(0, 0, 0, 0.048)`,
    };

    const subTitlesStyle: CSSProperties = {
        fontSize: isMobile ? '15px' : '20px',
        marginBottom: '10px',
    };

    const subCategoriesStyle: CSSProperties = {
        width: isMobile ? '100%' : '95%',
        margin: isMobile ? '10px' : '60px auto 10px auto',
    };

    const mainContainerStyle: CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        flexWrap: isMobile ? 'nowrap' : 'wrap',
        overflowX: 'auto',
    };

    return loading ? (
        <Box height="90vh">
            <LoadingPage />
        </Box>
    ) : (
        <Box height="100%" className={styles.invisibleScroll}>
            {mostPopularStream ? (
                <Box style={topViewedContainerStyle}>
                    <Typography
                        style={{ fontWeight: 'bold', fontSize: isMobile ? '15px' : '25px', marginBottom: '10px' }}
                    >
                        Top Viewed Show
                    </Typography>
                    <Box style={topViewedBodyStyle}>
                        <Link to={`/${mostPopularStreamOwner?.username}`} style={topStreamDescriptionStyle}>
                            <Box display={isMobile ? 'flex' : 'block'}>
                                <Box display="flex" alignItems="center">
                                    <img
                                        src={mostPopularStreamOwner?.profilePictureUrl}
                                        style={topStreamProfilePicStyle}
                                    />
                                    {isMobile ? (
                                        <Box marginLeft="2vw">
                                            <Box>
                                                <Box display="flex">
                                                    <Typography
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '18px',
                                                            lineHeight: '18px',
                                                        }}
                                                    >
                                                        {mostPopularStreamOwner?.displayUsername}
                                                    </Typography>
                                                    <Box
                                                        style={{
                                                            ...topStreamAmountReceivedStyle,
                                                            padding: '0px 5px',
                                                            marginLeft: '4px',
                                                            marginTop: '-4px',
                                                        }}
                                                    >
                                                        <Typography
                                                            style={{
                                                                marginRight: '2px',
                                                                lineHeight: '0px',
                                                            }}
                                                        >
                                                            {getNumberInThousands(
                                                                mostPopularStreamerData?.monthlyScore as number,
                                                            )}
                                                        </Typography>
                                                        <PinterestIcon
                                                            style={{ color: palette['text_p'], fontSize: '20px' }}
                                                        />
                                                    </Box>
                                                </Box>
                                                <Typography
                                                    style={{
                                                        lineHeight: '10px',
                                                    }}
                                                >
                                                    {mostPopularStream?.title}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    ) : (
                                        <Box marginLeft="20px">
                                            <Typography
                                                style={{
                                                    fontWeight: 'bold',
                                                    fontSize: isComputer ? 'min(1.5vw, 30px)' : '100%',
                                                }}
                                            >
                                                {mostPopularStreamOwner?.displayUsername}
                                            </Typography>
                                            <Box style={topStreamAmountReceivedStyle}>
                                                <Typography style={{ marginRight: '5px' }}>
                                                    {getNumberInThousands(
                                                        mostPopularStreamerData?.monthlyScore as number,
                                                    )}
                                                </Typography>
                                                <PinterestIcon style={{ color: palette['text_p'], right: '0px' }} />
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                                {!isMobile && (
                                    <Box>
                                        <Box style={{ margin: isComputer ? '1vw 0px' : '10px 0px' }}>
                                            <Typography style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                                {mostPopularStream?.title}
                                            </Typography>
                                            {/* <Typography>{mostPopularStream?.description}</Typography> */}
                                        </Box>
                                        <Box display="flex" flexWrap="wrap">
                                            {mostPopularStream?.content.map((el, idx) => (
                                                <Box key={idx} style={topStreamContentTagStyle}>
                                                    <Typography>{el}</Typography>
                                                </Box>
                                            ))}
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                            <Typography
                                style={{
                                    maxWidth: '15vw',
                                    lineHeight: isComputer ? '25px' : '10px',
                                }}
                            >
                                Watch now &gt;&gt;
                            </Typography>
                        </Link>
                        <Box width="100%" height="100%" position="relative">
                            <Box style={{ ...viewersCountStyle, ...absoluteViewCount }}>
                                <LensRoundedIcon style={lensIconStyle} />
                                <Typography style={{ color: palette['red'] }}>
                                    {getNumberInThousands(mostPopularStream?.viewersCount as number)}
                                </Typography>
                            </Box>
                            <Link
                                to={`/${mostPopularStreamOwner?.username}`}
                                style={{
                                    width: '100%',
                                    height: '80%',
                                    position: 'absolute',
                                    zIndex: 10,
                                    cursor: 'pointer',
                                }}
                            />
                            <HlsPlayer
                                videoUrl={mostPopularStream?.playbackUrl as string}
                                thumbnailUrl={mostPopularStream?.thumbnailUrl as string}
                                page={hlsPlayerPages.welcomePage}
                            />
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Typography className={styles.unselectable} style={{ fontSize: '30px', padding: '30px 0px 0px 50px' }}>
                    No active stream
                </Typography>
            )}
            {isMobile && activeSubbedAndFollowedStreams && activeSubbedAndFollowedStreams.length > 0 && (
                <Box style={subCategoriesStyle}>
                    <Typography style={subTitlesStyle}>Subbed & Followed </Typography>
                    <Box style={mainContainerStyle}>
                        {activeSubbedAndFollowedStreams.map((stream: StreamPublicData, idx: number) => (
                            <WelcomePageActiveStream activeStream={stream} key={idx} />
                        ))}
                    </Box>
                </Box>
            )}
            {filteredActiveStreams && filteredActiveStreams.length > 1 && (
                <Box style={subCategoriesStyle}>
                    <Box>
                        <Typography style={subTitlesStyle}>Recommended livestreams</Typography>
                        <Box style={mainContainerStyle}>
                            {filteredActiveStreams.map((stream: StreamPublicData, idx: number) => (
                                <>
                                    {stream.ownerId != mostPopularStream?.ownerId && (
                                        <WelcomePageActiveStream activeStream={stream} key={idx} />
                                    )}
                                </>
                            ))}
                        </Box>
                    </Box>
                </Box>
            )}
            {isMobile && leaderboard && leaderboard.length > 0 && (
                <Box style={subCategoriesStyle}>
                    <Typography style={subTitlesStyle}>Leaderboard </Typography>
                    <Box style={mainContainerStyle}>
                        {leaderboard.map((stream: StreamPublicData, idx: number) => (
                            <WelcomePageActiveStream activeStream={stream} key={idx} />
                        ))}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default WelcomePageComponent;
