import { Box, Typography } from '@material-ui/core';
import React, { CSSProperties, useEffect, useState } from 'react';
import { StreamPublicData } from '../../../../../models/Stream';
import { Input, LoadingButton } from '../../../../../styles/CustomComponents';
import { palette } from '../../../../../styles/theme';
import CloseIcon from '@material-ui/icons/Close';
import isEqual from 'lodash.isequal';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useMainWindowHost } from '../../../../../contexts/MainWindowHostContext';
import FireStoreLiveStreams from '../../../../../firestore/FirestoreLiveStreams';
import { useAuth } from '../../../../../contexts/AuthContext';

interface ManageTabProps {
    currentStream: StreamPublicData;
}

const ManageTab = (props: ManageTabProps) => {
    const firestoreLiveStreams: FireStoreLiveStreams = new FireStoreLiveStreams();
    const { isMobile } = useMainWindowHost();
    const { currentUser } = useAuth();

    const [title, setTitle] = useState(props.currentStream.title);
    const [content, setContent] = useState(props.currentStream.content);
    const [contentValue, setContentValue] = useState('');
    const [modified, setModified] = useState(false);
    const [loadingChanges, setLoadingChanges] = useState(false);
    const [processCompleted, setProcessCompleted] = useState(false);
    const [viewerName, setViewerName] = useState('');
    const [loadingBan, setLoadingBan] = useState(false);

    useEffect(() => {
        if (title !== props.currentStream.title || !isEqual(content, props.currentStream.content)) {
            setModified(true);
        } else {
            setModified(false);
        }
    }, [content, title, props.currentStream]);

    useEffect(() => {
        setProcessCompleted(false);
    }, [content, title]);

    const handleContentInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (event.target.value.length <= 15) {
            setContentValue(event.target.value);
        }
    };

    const handleContentInputEnter = (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (event.key === 'Enter' && content.length < 3) {
            setContent((prev) => [...prev, contentValue]);
            setContentValue('');
        }
    };

    const updateStreamInfo = async () => {
        setLoadingChanges(true);
        if (modified) {
            const streamInfo = {
                title: title,
                content: content,
            };
            await firestoreLiveStreams.setLivestreamInfo(streamInfo, currentUser.userId);
        }
        setLoadingChanges(false);
        setProcessCompleted(true);
        setTimeout(() => {
            setProcessCompleted(false);
        }, 3000);
    };

    const resetChanges = () => {
        setContent(props.currentStream.content);
        setTitle(props.currentStream.title);
    };

    const banUser = async () => {
        setLoadingBan(true);
        if (viewerName === currentUser.displayUsername) {
            alert('You cannot ban yourself');
            setLoadingBan(false);
            return;
        }
        const bannedList = {
            bannedUsernameList: [...(props.currentStream.bannedUsernameList as string[]), viewerName],
            title: props.currentStream.title,
            content: props.currentStream.content,
        };
        await firestoreLiveStreams.setLivestreamInfo(bannedList, currentUser.userId);
        setViewerName('');
        setLoadingBan(false);
    };

    const unbannedUser = async (username: string) => {
        const bannedList = {
            bannedUsernameList: (props.currentStream.bannedUsernameList as string[]).filter((e) => e !== username),
            title: props.currentStream.title,
            content: props.currentStream.content,
        };
        await firestoreLiveStreams.setLivestreamInfo(bannedList, currentUser.userId);
    };

    const boxStyle: CSSProperties = {
        backgroundColor: palette['primary_l'],
        borderRadius: '15px',
        padding: '20px 20px',
        margin: '20px 50px',
    };

    const titleStyle: CSSProperties = {
        fontSize: '18px',
        fontWeight: 700,
        display: 'inline',
    };

    const subTitleStyle: CSSProperties = {
        fontSize: '16px',
        fontWeight: 600,
        margin: '25px 0px 5px 0px',
    };

    const removeContentIconStyle: CSSProperties = {
        color: palette['text_s'],
        fontSize: '15px',
        marginLeft: '5px',
        cursor: 'pointer',
    };

    const contentBoxStyle: CSSProperties = {
        display: 'flex',
        borderRadius: '10px',
        padding: '0px 10px',
        margin: '0px 10px 5px 0px',
        alignItems: 'center',
        backgroundColor: palette['primary'],
    };

    return (
        <Box>
            <Box style={boxStyle}>
                <Typography style={titleStyle}>Change your </Typography>
                <Typography style={{ ...titleStyle, color: palette['secondary'] }}>Stream</Typography>
                <Box>
                    <Typography style={subTitleStyle}>Change your title</Typography>
                    <Input
                        value={title}
                        style={{ width: '400px' }}
                        onChange={(event) => setTitle(event.target.value)}
                    />
                </Box>
                <Box>
                    <Typography style={subTitleStyle}>Change your tags</Typography>
                    <Input
                        value={contentValue}
                        style={{ width: '400px' }}
                        onChange={(event) => handleContentInputChange(event)}
                        onKeyDown={(event) => handleContentInputEnter(event)}
                    />
                    <Box style={{ margin: '5px 0px 20px 0px', display: 'flex', flexWrap: 'wrap' }}>
                        {content.map((el, idx) => (
                            <Box key={idx} style={contentBoxStyle}>
                                <Typography>{el}</Typography>
                                <CloseIcon
                                    style={removeContentIconStyle}
                                    onClick={() => setContent((prev) => [...prev.filter((_, i) => i !== idx)])}
                                />
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Box style={{ display: 'flex' }}>
                    {!processCompleted && (
                        <LoadingButton
                            style={{ backgroundColor: palette['secondary'], borderRadius: '20px' }}
                            value="Save"
                            disabled={!modified}
                            isLoading={loadingChanges}
                            onClick={() => {
                                updateStreamInfo();
                            }}
                        />
                    )}
                    {processCompleted && (
                        <CheckCircleIcon
                            color="secondary"
                            style={{ fontSize: '30px', marginLeft: '10px', marginTop: '4px' }}
                        />
                    )}
                    {modified && (
                        <Typography
                            style={{
                                color: palette['secondary'],
                                cursor: 'pointer',
                                fontWeight: 500,
                                margin: '4px 15px',
                            }}
                            onClick={() => resetChanges()}
                        >
                            Undo
                        </Typography>
                    )}
                </Box>
            </Box>
            <Box style={boxStyle}>
                <Typography style={titleStyle}>Ban a </Typography>
                <Typography style={{ ...titleStyle, color: palette['secondary'] }}>viewers</Typography>
                <Typography style={{ fontSize: '13px', color: palette['secondary'] }}>
                    *Banned viewer will be unbanned at the start of your next stream.
                </Typography>
                <Typography style={subTitleStyle}>Viewer&lsquo;s username</Typography>
                <Box>
                    <Input
                        value={viewerName}
                        style={{ width: '400px' }}
                        onChange={(event) => setViewerName(event.target.value)}
                        inline={!isMobile}
                    />
                    <LoadingButton
                        style={{
                            backgroundColor: palette['secondary'],
                            borderRadius: '20px',
                            marginLeft: isMobile ? 'auto' : '20px',
                            marginTop: isMobile ? '10px' : 'auto',
                        }}
                        value={'Ban'}
                        disabled={!viewerName}
                        isLoading={loadingBan}
                        onClick={() => banUser()}
                    />
                </Box>
                <Box>
                    <Typography style={subTitleStyle}>Currently banned viewer</Typography>
                    <Box
                        style={{
                            backgroundColor: palette['softDarkBlue'],
                            borderRadius: '15px',
                            padding: '5px 10px',
                        }}
                    >
                        {props.currentStream.bannedUsernameList &&
                            props.currentStream.bannedUsernameList.map((username: string, idx: number) => {
                                return (
                                    <Box key={idx} style={{ display: 'flex' }}>
                                        <CloseIcon
                                            style={{
                                                color: palette['secondary'],
                                                padding: '1px 0px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => unbannedUser(username)}
                                        />
                                        <Typography>{username}</Typography>
                                    </Box>
                                );
                            })}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ManageTab;
