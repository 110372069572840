import { Box, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { TipOption } from '../../models/User';
import PinterestIcon from '@material-ui/icons/Pinterest';
import { palette } from '../../styles/theme';
import CloseIcon from '@material-ui/icons/Close';
import { LoadingButton } from '../../styles/CustomComponents';
import { useAuth } from '../../contexts/AuthContext';
import AuthenticationDialog from '../nav_bar/authentication/AuthenticationDialog';
import StoreDialog from '../nav_bar/store_page/StoreDialog';

interface SingleTipOptionProps {
    idx: number;
    tipOption: TipOption;
    handleDeleteOption?: (idx: number) => void;
    handleTipping?: (tipOptionId: number, tipOption: TipOption) => void;
    disable?: boolean;
    loading?: boolean;
}

const SingleTipOption = (props: SingleTipOptionProps) => {
    const [undo, setUndo] = useState(false);
    const [undoTimer, setUndoTimer] = useState<number>();
    const [openStoreModal, setOpenStoreModal] = useState(false);
    const [authenticationDialogOpen, setAuthenticationDialogOpen] = useState(false);
    const { currentUser, currentUserPrivateData } = useAuth();

    return (
        <Box key={props.idx} style={{ display: 'flex', alignItems: 'center' }}>
            {props.handleDeleteOption && <Typography>{props.idx + 1}.</Typography>}
            <Box
                style={{
                    margin: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: palette['primary_d'],
                    padding: '4px 4px 4px 10px',
                    borderRadius: '10px',
                    width: '100%',
                    maxWidth: '400px',
                }}
            >
                <Typography style={{ marginRight: '5px' }}>{props.tipOption.reward}</Typography>
                <Box
                    style={{
                        backgroundColor: palette['primary_l'],
                        borderRadius: '6px',
                        display: 'flex',
                        padding: '0px 0px 0px 10px',
                        alignItems: 'center',
                        marginLeft: 'auto',
                    }}
                >
                    <Typography>{props.tipOption.points}</Typography>
                    <PinterestIcon style={{ color: palette['text_p'], fontSize: '20px', margin: '0px 3px' }} />
                    <LoadingButton
                        disabled={!props.handleTipping || props.disable}
                        style={{
                            marginLeft: '3px',
                            backgroundColor: props.disable ? 'grey' : palette['secondary'],
                            borderRadius: '6px',
                            padding: '0px 10px',
                            cursor: props.handleTipping && 'pointer',
                            width: '50px',
                            height: '26px',
                            minWidth: '0px',
                        }}
                        value={
                            undo ? (
                                <Typography style={{ fontWeight: 700 }}>Undo</Typography>
                            ) : (
                                <Typography
                                    style={{
                                        fontWeight: 700,
                                        opacity: props.disable ? 0.5 : 1,
                                    }}
                                >
                                    TIP
                                </Typography>
                            )
                        }
                        onClick={() => {
                            if (currentUser.isAnonymous) {
                                setAuthenticationDialogOpen(true);
                            } else {
                                if (currentUserPrivateData.pointsAmount < props.tipOption.points) {
                                    setOpenStoreModal(true);
                                } else {
                                    if (undo) {
                                        setUndo(false);
                                        clearTimeout(undoTimer);
                                    } else {
                                        setUndo(true);
                                        setUndoTimer(
                                            setTimeout(() => {
                                                if (props.handleTipping) {
                                                    props.handleTipping(props.idx, props.tipOption);
                                                }
                                                setUndo(false);
                                            }, 3000) as unknown as number,
                                        );
                                    }
                                }
                            }
                        }}
                        isLoading={props.loading ? props.loading : false}
                    />
                </Box>
            </Box>
            {props.handleDeleteOption && (
                <CloseIcon
                    style={{ color: palette['secondary'], cursor: 'pointer' }}
                    onClick={() => {
                        if (props.handleDeleteOption) {
                            props.handleDeleteOption(props.idx);
                        }
                    }}
                />
            )}
            <AuthenticationDialog open={authenticationDialogOpen} setDialogState={setAuthenticationDialogOpen} />
            <StoreDialog setDialogState={setOpenStoreModal} open={openStoreModal} />
        </Box>
    );
};

export default SingleTipOption;
