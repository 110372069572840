import React, { useContext, useEffect, useState } from 'react';
import { auth, database, firestore, functions } from '../Firebase';
import firebase from 'firebase/app';
import {
    User,
    Notification,
    UserPrivateData,
    UserPublicData,
    UserStreamingData as UserStreamingData,
    Referral,
} from '../models/User';
import FirestoreUsers from '../firestore/FirestoreUsers';
import FirestoreNotifications from '../firestore/FirestoreNotifications';
import FirestoreFollows from '../firestore/FirestoreFollows';
import { defaultProfilePictureUrl, firestoreCollections } from '../constants/Constants';

interface AuthProviderValue {
    currentUser: User;
    currentUserPrivateData: UserPrivateData;
    currentUserPublicData: UserPublicData;
    currentUserStreamingData: UserStreamingData;
    signUp: (email: string, password: string, username: string, referral: Referral | undefined) => Promise<void>;
    signOut: () => Promise<void>;
    signInWithEmail: (email: string, password: string) => Promise<void>;
    signInWithUsername: (email: string, password: string) => Promise<void>;
    isEmailAlreadyUsed: (email: string) => Promise<boolean>;
    updateEmail: (email: string) => Promise<void>;
    updateUsername: (username: string) => Promise<void>;
    updateProfilePicture: (downloadUrl: string) => Promise<void>;
    reauthenticateUser: (email: string, password: string) => Promise<void>;
    verifyEmailAddress: () => Promise<void>;
    resetPassword: (email: string) => Promise<void>;
}

const AuthContext = React.createContext({} as AuthProviderValue);

export const useAuth = () => {
    return useContext(AuthContext);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AuthProvider: React.FC = ({ children }: any) => {
    const firestoreUsers: FirestoreUsers = new FirestoreUsers();
    const firestoreNotifications: FirestoreNotifications = new FirestoreNotifications();
    const firestoreFollows: FirestoreFollows = new FirestoreFollows();

    const [currentUser, setCurrentUser] = useState<User>();

    const [currentUserPrivateData, setCurrentUserPrivateData] = useState<UserPrivateData>();
    const [currentUserPublicData, setCurrentUserPublicData] = useState<UserPublicData>();
    const [currentUserStreamingData, setCurrentUserStreamingData] = useState<UserStreamingData>();

    useEffect(() => {
        if (!currentUser) return;

        const sub = firestore
            .collection(firestoreCollections.userPrivate)
            .doc(currentUser.userId)
            .onSnapshot((snap) => {
                setCurrentUserPrivateData(snap.data() as UserPrivateData);
            });

        return sub;
    }, [currentUser]);

    useEffect(() => {
        if (!currentUser) return;

        const sub = firestore
            .collection(firestoreCollections.userPublic)
            .doc(currentUser.userId)
            .onSnapshot((snap) => {
                setCurrentUserPublicData(snap.data() as UserPublicData);
            });

        return sub;
    }, [currentUser]);

    useEffect(() => {
        if (!currentUser) return;

        const sub = firestore
            .collection(firestoreCollections.userStreaming)
            .doc(currentUser.userId)
            .onSnapshot((snap) => {
                setCurrentUserStreamingData(snap.data() as UserStreamingData);
            });

        return sub;
    }, [currentUser]);

    const signUp = async (
        email: string,
        password: string,
        username: string,
        referral: Referral | undefined,
    ): Promise<void> => {
        if (password.length < 6 || password.length > 50 || username.length < 4 || username.length > 20) {
            throw new Error('Invalid username or password length');
        }

        const res = await auth.createUserWithEmailAndPassword(email, password);
        await res.user?.updateProfile({ displayName: username, photoURL: defaultProfilePictureUrl });

        const createUserInfo = functions.httpsCallable('usersFunctions-createUserInfo');
        await createUserInfo({
            user: { uid: res.user?.uid, displayName: username, email: email },
            referral: referral,
        });

        if (referral) {
            const addReferralBonusToUserBalance = functions.httpsCallable(
                'referralFunctions-addReferralBonusToUserBalance',
            );
            await addReferralBonusToUserBalance({
                referral: referral,
            });
        }

        await res.user?.sendEmailVerification();
        setCurrentUser((prev) => ({ ...(prev as User), displayUsername: username, username: username.toLowerCase() }));
    };

    const signOut = async (): Promise<void> => {
        await auth.signOut();
        setCurrentUser(undefined);
    };

    const signInWithEmail = async (email: string, password: string): Promise<void> => {
        await auth.signInWithEmailAndPassword(email, password);
    };

    const signInWithUsername = async (username: string, password: string): Promise<void> => {
        const re =
            /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;

        if (re.test(username)) {
            signInWithEmail(username, password);
            return;
        }

        const usernameAuthentication = functions.httpsCallable('usersFunctions-usernameAuthentication');
        const token: string = (await usernameAuthentication({ username: username.toLowerCase(), password: password }))
            .data;
        auth.signInWithCustomToken(token);
    };

    const isEmailAlreadyUsed = async (email: string): Promise<boolean> => {
        const res: string[] = await auth.fetchSignInMethodsForEmail(email);
        return res.length !== 0;
    };

    const updateEmail = async (email: string): Promise<void> => {
        const user: firebase.User | null = auth.currentUser;
        if (!user) return;

        await user.updateEmail(email);

        const updateUserEmail = functions.httpsCallable('usersFunctions-updateUserEmail');
        await updateUserEmail({ userId: user.uid, email: email });
    };

    const updateUsername = async (username: string): Promise<void> => {
        const user: firebase.User | null = auth.currentUser;
        if (!user) return;

        await user.updateProfile({
            displayName: username,
        });

        setCurrentUser((prev) => ({ ...(prev as User), displayUsername: username, username: username.toLowerCase() }));
    };

    const updateProfilePicture = async (downloadUrl: string): Promise<void> => {
        const user: firebase.User | null = auth.currentUser;
        if (!user) return;

        await user.updateProfile({
            photoURL: downloadUrl,
        });

        setCurrentUser((prev) => ({ ...(prev as User), profilePictureUrl: downloadUrl }));
    };

    const reauthenticateUser = async (email: string, password: string): Promise<void> => {
        const credentials = firebase.auth.EmailAuthProvider.credential(email, password);
        await auth.currentUser?.reauthenticateWithCredential(credentials);
    };

    const verifyEmailAddress = async () => {
        await auth.currentUser?.sendEmailVerification();
    };

    const resetPassword = async (email: string) => {
        await auth.sendPasswordResetEmail(email);
    };

    const subscribeToDatabase = async (uid: string) => {
        database.ref('.info/connected').on('value', async (snapshot) => {
            if (snapshot.val() === false) {
                return;
            }

            const defaultConnectionState = {
                connections: 0,
                lastChanged: firebase.database.ServerValue.TIMESTAMP,
                isAnonymous: auth.currentUser?.isAnonymous,
            };

            const userStatusDatabaseRef = database.ref('/status/' + uid);

            if (!(await userStatusDatabaseRef.get()).exists()) {
                userStatusDatabaseRef.set(defaultConnectionState);
            }

            userStatusDatabaseRef
                .onDisconnect()
                .update({
                    connections: firebase.database.ServerValue.increment(-1),
                    lastChanged: firebase.database.ServerValue.TIMESTAMP,
                })
                .then(() => {
                    userStatusDatabaseRef.update({
                        connections: firebase.database.ServerValue.increment(1),
                    });
                });
        });
    };

    const updateNotifications = async (userId: string) => {
        const followingIds: string[] = await firestoreFollows.getFollowingIds(userId);
        if (!followingIds.length) return;

        const lastSeen = await firestoreUsers.getUsersLastSeen(userId);
        if (!lastSeen) return;

        const newNotifications: Map<string, Notification> = await firestoreNotifications.getNotificationsSinceLastLogin(
            followingIds,
            lastSeen,
        );
        await firestoreUsers.addNotifications(userId, newNotifications);
    };

    const value: AuthProviderValue = {
        currentUser: currentUser as User,
        currentUserPrivateData: currentUserPrivateData as UserPrivateData,
        currentUserPublicData: currentUserPublicData as UserPublicData,
        currentUserStreamingData: currentUserStreamingData as UserStreamingData,
        signUp,
        signOut,
        signInWithEmail,
        signInWithUsername,
        isEmailAlreadyUsed,
        updateEmail,
        updateProfilePicture,
        updateUsername,
        reauthenticateUser,
        verifyEmailAddress,
        resetPassword,
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (!user) {
                await auth.signInAnonymously();
                return;
            }

            const firebaseUser: firebase.User = user as firebase.User;

            if (firebaseUser.isAnonymous) {
                setCurrentUser({
                    userId: firebaseUser.uid,
                    isAnonymous: true,
                });
            } else {
                setCurrentUser({
                    userId: firebaseUser.uid,
                    isAnonymous: false,
                    displayUsername: firebaseUser.displayName as string,
                    username: firebaseUser.displayName?.toLowerCase() as string,
                    profilePictureUrl: firebaseUser.photoURL as string,
                    isVerified: firebaseUser.emailVerified,
                });
            }

            await subscribeToDatabase(firebaseUser.uid);

            if (!firebaseUser.isAnonymous) {
                await updateNotifications(firebaseUser.uid);
            }
        });

        return unsubscribe;
    }, []);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
