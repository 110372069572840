import React from 'react';
import { Box, Typography } from '@material-ui/core';

const Dmca = () => {
    return (
        <Box>
            <Typography>DMCA</Typography>
        </Box>
    );
};

export default Dmca;
