import { firestoreCollections } from '../constants/Constants';
import { firestore } from '../Firebase';
import { FollowUser } from '../models/User';

export default class FirestoreFollows {
    private readonly collection = firestore.collection(firestoreCollections.follows);

    async addToFollowing(ownUserId: string, followingUser: FollowUser): Promise<void> {
        await this.collection
            .doc(ownUserId)
            .collection(firestoreCollections.following)
            .doc(followingUser.userId)
            .set(followingUser);
    }

    async addToFollowers(followingUserId: string, ownUser: FollowUser): Promise<void> {
        await this.collection
            .doc(followingUserId)
            .collection(firestoreCollections.followers)
            .doc(ownUser.userId)
            .set(ownUser);
    }

    async removeFromFollowing(ownUserId: string, followingUserId: string): Promise<void> {
        await this.collection.doc(ownUserId).collection(firestoreCollections.following).doc(followingUserId).delete();
    }

    async removeFromFollowers(followingUserId: string, ownUserId: string): Promise<void> {
        await this.collection.doc(followingUserId).collection(firestoreCollections.followers).doc(ownUserId).delete();
    }

    async getFollowingIds(userId: string): Promise<string[]> {
        return (await this.collection.doc(userId).collection(firestoreCollections.following).get()).docs.map(
            (doc) => doc.id,
        );
    }
}
