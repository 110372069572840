import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React, { CSSProperties, useEffect, useState } from 'react';
import { Subscription, SubscriptionUser, UserPublicData, UserStreamingData } from '../../../../../models/User';
import { LoadingButton } from '../../../../../styles/CustomComponents';
import { palette } from '../../../../../styles/theme';
import FirestoreTransactions from '../../../../../firestore/FirestoreTransactions';
import { useAuth } from '../../../../../contexts/AuthContext';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useMainWindowHost } from '../../../../../contexts/MainWindowHostContext';

interface UnsubscribeDialogProps {
    isOpen: boolean;
    setDialogState: (isOpen: boolean) => void;
    user: UserPublicData;
    userStreamingData: UserStreamingData;
    subscription: SubscriptionUser;
}

const UnsubscribeDialog = (props: UnsubscribeDialogProps) => {
    const firestoreTransactions: FirestoreTransactions = new FirestoreTransactions();
    const { currentUser } = useAuth();
    const { isMobile } = useMainWindowHost();

    const [subscription, setSubscription] = useState<Subscription>();

    const [loading, setLoading] = useState(false);
    const [processCompleted, setProcessCompleted] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        if (!props.user || !props.subscription || !props.isOpen) return;

        const sub = props.userStreamingData.subscription;

        setSubscription(sub);
    }, [props.subscription, props.user, props.isOpen]);

    const handleClose = () => {
        props.setDialogState(false);
        setProcessCompleted(false);
    };

    const handleUnsubscribeButtonClick = async () => {
        setLoading(true);

        const res = await firestoreTransactions.cancelPaypalSubscription(
            props.subscription.subscriptionId,
            currentUser.userId,
            props.user.userId,
        );

        setProcessCompleted(true);
        setSuccess(res);

        setLoading(false);
    };

    const unsubscribeButtonStyle: CSSProperties = {
        margin: isMobile ? '0px 0px 0px 5px' : '30px 10px 0px 0px',
    };

    const unsubscribeWarningStyle: CSSProperties = {
        backgroundColor: palette['primary'],
        borderRadius: '5px',
        padding: '5px 10px',
        margin: `10px ${isMobile ? '0px' : '40px'}`,
    };

    const finalMessageHeaderStyle: CSSProperties = {
        fontSize: '40px',
    };

    const finalMessageIconStyle: CSSProperties = {
        marginTop: '20px',
        fontSize: '60px',
        color: palette['secondary'],
    };

    const subscriptionTitleStyle: CSSProperties = {
        marginTop: '10px',
        display: 'inline-block',
        backgroundColor: palette['primary'],
        padding: '7px 12px',
        borderRadius: '20px 20px 0px 0px',
    };

    const subscriptionBadgeStyle: CSSProperties = {
        width: '25px',
        height: '25px',
        marginRight: '5px',
    };

    const subscriptionContentStyle: CSSProperties = {
        border: `3px solid ${palette['primary']}`,
        borderRadius: '0px 20px 20px 20px',
        padding: '10px 20px',
    };

    return (
        <Dialog open={props.isOpen} onClose={handleClose}>
            {processCompleted ? (
                <Box textAlign="center" margin="40px 0px">
                    {success ? (
                        <Box>
                            <Typography style={finalMessageHeaderStyle}>Success!</Typography>
                            <Typography>Succesfully unsubscribed</Typography>
                            <CheckCircleIcon style={finalMessageIconStyle} />
                        </Box>
                    ) : (
                        <Box>
                            <Typography style={finalMessageHeaderStyle}>Oops!</Typography>
                            <Typography>Something went wrong, please try again later</Typography>
                            <SentimentVeryDissatisfiedIcon style={finalMessageIconStyle} />
                        </Box>
                    )}
                </Box>
            ) : (
                <Box>
                    <DialogTitle>
                        <Typography style={{ color: palette['secondary'], fontWeight: 'bold', fontSize: '20px' }}>
                            Unsubscribe from {props.user.displayUsername}&apos;s channel
                        </Typography>
                    </DialogTitle>

                    <DialogContent>
                        <Typography>Your subscription : </Typography>
                        <Box style={{ margin: `10px ${isMobile ? '0px' : '40px'}` }}>
                            <Box style={subscriptionTitleStyle}>
                                <Box display="flex">
                                    <img src={subscription?.badgeUrl} style={subscriptionBadgeStyle} />
                                </Box>
                            </Box>
                            <Box style={subscriptionContentStyle}>
                                <Typography>{subscription?.monthlyFee} USD/month</Typography>
                                <Typography style={{ color: palette['secondary'], marginTop: '10px' }}>
                                    Benefits:
                                </Typography>
                                <Typography>{subscription?.benefits}</Typography>
                            </Box>
                        </Box>
                        <Box style={unsubscribeWarningStyle}>
                            <Typography>
                                Are you sure you want to unsubscribe from {props.user.displayUsername}?
                            </Typography>
                            <Typography style={{ color: palette['red'], marginTop: '10px' }}>
                                You will lose all your subscription&apos;s benefits on this channel!
                            </Typography>
                        </Box>
                        <DialogActions>
                            <LoadingButton
                                isLoading={loading}
                                onClick={() => {
                                    handleUnsubscribeButtonClick();
                                }}
                                value={'Yes, unsubscribe'}
                                style={{ ...unsubscribeButtonStyle, backgroundColor: palette['red'] }}
                            />
                            <Button
                                style={{ ...unsubscribeButtonStyle, backgroundColor: palette['secondary'] }}
                                onClick={() => handleClose()}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Box>
            )}
        </Dialog>
    );
};

export default UnsubscribeDialog;
