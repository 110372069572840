import {
    Box,
    Button,
    ClickAwayListener,
    FormHelperText,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Typography,
} from '@material-ui/core';
import React, { CSSProperties, useState } from 'react';
import { UserPublicData } from '../../../../../models/User';
import { palette } from '../../../../../styles/theme';
import { Input } from '../../../../../styles/CustomComponents';
import { useMainWindowHost } from '../../../../../contexts/MainWindowHostContext';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { colorSelection } from '../../../../../constants/Constants';

interface PublicInformationProps {
    user: UserPublicData;
    usernameAvailable: boolean;
    setUser: React.Dispatch<React.SetStateAction<UserPublicData>>;
    setUsernameAvailable: (usernameAvailable: boolean) => void;
}

const PublicInformation = (props: PublicInformationProps) => {
    const { isMobile } = useMainWindowHost();

    const [usernameTooShort, setUsernameTooShort] = useState(false);
    const [openColorPicker, setOpenColorPicker] = useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const handleUsernameChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const newUsername = event.target.value;
        const re = /^[A-Za-z0-9_]+$/;

        if (newUsername.length <= 20 && (newUsername === '' || re.test(newUsername))) {
            props.setUser((prev: UserPublicData) => ({
                ...prev,
                displayUsername: newUsername,
                username: newUsername.toLowerCase(),
            }));

            setUsernameTooShort(newUsername.length < 4);
            props.setUsernameAvailable(true);
        }
    };

    const handleColorChange = (color: string) => {
        props.setUser((prev: UserPublicData) => ({
            ...prev,
            color: color,
        }));
    };

    const inputsStyle: CSSProperties = {
        flexGrow: 1,
    };

    const textFieldsStyle: CSSProperties = {
        width: isMobile ? '100%' : '300px',
    };

    const labelsStyle: CSSProperties = {
        width: '25vw',
        marginRight: '10px',
        maxWidth: '200px',
        lineHeight: '1.2',
    };

    const rowsStyle: CSSProperties = {
        marginBottom: isMobile ? '20px' : '50px',
        display: 'flex',
        justifyContent: isMobile ? 'space-between' : 'unset',
    };

    return (
        <Box>
            <Box style={rowsStyle}>
                <Typography style={labelsStyle}> Username : </Typography>
                <Box style={inputsStyle}>
                    <Input
                        autoComplete="none"
                        onChange={(event) => handleUsernameChange(event)}
                        value={props.user.displayUsername}
                        style={textFieldsStyle}
                    />
                    <FormHelperText
                        style={{
                            color: palette['secondary'],
                            visibility: usernameTooShort || !props.usernameAvailable ? 'visible' : 'hidden',
                        }}
                    >
                        {!props.usernameAvailable
                            ? '*This username is already used by another account'
                            : '*Username must be between 4 and 20 characters'}
                    </FormHelperText>
                </Box>
            </Box>
            <Box style={rowsStyle}>
                <Typography style={labelsStyle}>Chat color : </Typography>
                <Box style={{}}>
                    <Button
                        ref={anchorRef}
                        style={{
                            backgroundColor: palette['lightPurple'],
                            border: `3px solid ${palette['primary']}`,
                            borderRadius: '10px',
                            padding: '0px 8px',
                            display: 'flex',
                        }}
                        onClick={() => {
                            setOpenColorPicker(true);
                        }}
                    >
                        <Box
                            style={{
                                width: '28px',
                                height: '18px',
                                backgroundColor: colorSelection[props.user.color],
                                margin: '5px 0px',
                            }}
                        />
                        <PlayArrowIcon
                            style={{
                                WebkitTransform: 'rotate(90deg)',
                                MozTransformOrigin: 'rotate(90deg)',
                                msTransform: 'rotate(90deg)',
                                OTransform: 'rotate(90deg)',
                                transform: 'rotate(90deg)',
                            }}
                        />
                    </Button>
                    <Popper
                        open={openColorPicker}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                        style={{ zIndex: 1 }}
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                            >
                                <Paper
                                    style={{
                                        backgroundColor: palette['lightPurple'],
                                        borderRadius: '10px',
                                        border: `3px solid ${palette['primary']}`,
                                        zIndex: 1,
                                    }}
                                >
                                    <ClickAwayListener onClickAway={() => setOpenColorPicker(false)}>
                                        <MenuList>
                                            {Object.keys(colorSelection).map((key, idx) => {
                                                return (
                                                    <MenuItem key={idx} onClick={() => setOpenColorPicker(false)}>
                                                        <Box
                                                            style={{
                                                                width: '28px',
                                                                height: '18px',
                                                                backgroundColor: colorSelection[key],
                                                            }}
                                                            onClick={() => {
                                                                handleColorChange(key);
                                                            }}
                                                        />
                                                    </MenuItem>
                                                );
                                            })}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Box>
            </Box>
        </Box>
    );
};

export default PublicInformation;
