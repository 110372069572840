import { Box } from '@material-ui/core';
import React from 'react';
import { TipOption } from '../../models/User';
import SingleTipOption from './SingleTipOption';

interface TipMenuOptionProps {
    tipMenu: TipOption[];
    handleDeleteOption?: (idx: number) => void;
    handleTipping?: (tipOptionId: number, tipOption: TipOption) => void;
    loading?: boolean;
    disable?: boolean;
}

const TipMenuOption = (props: TipMenuOptionProps) => {
    return (
        <Box>
            {props.tipMenu.map((tipOption: TipOption, idx: number) => {
                return (
                    <Box key={idx}>
                        <SingleTipOption
                            idx={idx}
                            tipOption={tipOption}
                            handleDeleteOption={props.handleDeleteOption}
                            handleTipping={props.handleTipping}
                            disable={props.disable}
                            loading={props.loading}
                        />
                    </Box>
                );
            })}
        </Box>
    );
};

export default TipMenuOption;
