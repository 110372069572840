import { Box } from '@material-ui/core';
import React, { CSSProperties, useEffect, useState } from 'react';
import { palette } from '../../styles/theme';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { useMainWindowHost } from '../../contexts/MainWindowHostContext';
import { Input } from '../../styles/CustomComponents';
import { useHistory, useLocation } from 'react-router-dom';

const SearchBarComponent = () => {
    const { isMobile } = useMainWindowHost();
    const history = useHistory();
    const location = useLocation();

    const [searchWord, setSearchWord] = useState('');

    useEffect(() => {
        const unsubscribe = history.listen(async (loc) => {
            if (!loc.pathname.startsWith('/search')) setSearchWord('');
        });

        return unsubscribe;
    }, []);

    const searchBarStyle: CSSProperties = {
        borderRadius: '20px',
        backgroundColor: palette['primary_d'],
        border: 'none',
        height: '25px',
        width: '100%',
    };

    const iconsStyle: CSSProperties = {
        color: palette['text_s'],
        cursor: 'pointer',
        marginTop: '-3px',
    };

    const updateSearchWord = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchWord(event.target.value);
    };

    const onKeyUp = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (!(event.key === 'Enter')) return;

        (event.target as HTMLInputElement).blur();
        await onPressEnter();
    };

    const onPressEnter = async (): Promise<void> => {
        location.pathname.startsWith('/search') ? history.replace(searchWord) : history.push(`search/${searchWord}`);
    };

    return (
        <Box maxWidth="400px" width="100%">
            <Input
                autoFocus={isMobile}
                placeholder="Search"
                value={searchWord}
                style={searchBarStyle}
                endIcon={
                    searchWord ? (
                        isMobile ? (
                            <SearchIcon style={iconsStyle} onClick={() => onPressEnter()} />
                        ) : (
                            <CloseIcon style={iconsStyle} onClick={() => setSearchWord('')} />
                        )
                    ) : (
                        <SearchIcon style={iconsStyle} />
                    )
                }
                onChange={(event) => updateSearchWord(event)}
                onKeyDown={(event) => onKeyUp(event)}
            />
        </Box>
    );
};

export default SearchBarComponent;
