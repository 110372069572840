import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { Box, List, Typography } from '@material-ui/core';
import { StreamPublicData } from '../../models/Stream';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { firestore } from '../../Firebase';
import { firestoreCollections } from '../../constants/Constants';
import { useAuth } from '../../contexts/AuthContext';
import { FollowUser, SubscriptionUser, UserPublicData, UserStreamingData } from '../../models/User';
import SideBarActiveStream from './SideBarActiveStream';
import { palette } from '../../styles/theme';
import FirestoreUsers from '../../firestore/FirestoreUsers';
import { useMainWindowHost } from '../../contexts/MainWindowHostContext';
import styles from '../../styles/styles.module.css';
import SideBarInactiveStream from './SideBarInactiveStream';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

interface SideBarComponentProps {
    activeStream: boolean;
    infoMode: boolean;
}

const SideBarComponent = (props: SideBarComponentProps) => {
    const firestoreUsers: FirestoreUsers = new FirestoreUsers();
    const { currentUser } = useAuth();
    const { isComputer, isMobile } = useMainWindowHost();

    const [activeStreams] = useCollectionData<StreamPublicData>(
        firestore.collection(firestoreCollections.livestreamsPublic).where('active', '==', true),
    );

    const [leaderboardUsers] = useCollectionData<UserStreamingData>(
        firestore
            .collection(firestoreCollections.userStreaming)
            .where('monthlyScore', '>', 0)
            .orderBy('monthlyScore', 'desc')
            .limit(10),
    );

    const [followings, setFollowings] = useState<FollowUser[]>();
    const [subscriptions, setSubscriptions] = useState<SubscriptionUser[]>();

    const [activeSubbedStreams, setActiveSubbedStreams] = useState<StreamPublicData[]>();
    const [inactiveSubbedStreams, setInactiveSubbedStreams] = useState<UserPublicData[]>();
    const [activeFollowedStreams, setActiveFollowedStreams] = useState<StreamPublicData[]>();
    const [inactiveFollowedStreams, setInactiveFollowedStreams] = useState<UserPublicData[]>();
    const [leaderboard, setLeaderboard] = useState<StreamPublicData[]>();

    const [sideBarHover, setSideBarHover] = useState(false);
    const [isScrolledToAbouts, setIsScrolledToAbouts] = useState(false);

    const sideBarAboutsRef = useRef<HTMLDivElement>(null);
    const sideBarContainerRef = useRef<HTMLDivElement>(null);
    const computerMode = sideBarHover || isComputer;

    useEffect(() => {
        if (!currentUser) return;

        const sub = firestore
            .collection(firestoreCollections.follows)
            .doc(currentUser?.userId)
            .collection(firestoreCollections.following)
            .onSnapshot((snap) => {
                setFollowings(snap.docs.map((doc) => doc.data() as FollowUser));
            });

        return sub;
    }, [currentUser]);

    useEffect(() => {
        if (!currentUser) return;
        const sub = firestore
            .collection(firestoreCollections.subscriptions)
            .doc(currentUser?.userId)
            .collection(firestoreCollections.subscribedTo)
            .onSnapshot((snap) => {
                setSubscriptions(snap.docs.map((doc) => doc.data() as SubscriptionUser));
            });

        return sub;
    }, [currentUser]);

    useEffect(() => {
        async function setStreams(actives: StreamPublicData[], follows: FollowUser[], subs: SubscriptionUser[]) {
            const subbedIds = subs.map((subbed) => subbed.userId);
            let followingIds = follows.map((following) => following.userId);
            followingIds = followingIds.filter((id) => !subbedIds?.includes(id));

            setActiveSubbedStreams(actives.filter((stream) => subbedIds?.includes(stream.ownerId as string)));
            setActiveFollowedStreams(actives.filter((stream) => followingIds?.includes(stream.ownerId as string)));

            const activeStreamsIds = actives.map((stream) => stream.ownerId);
            const inactiveSubs = subs.filter((sub) => !activeStreamsIds?.includes(sub.userId));
            const inactiveFollows = follows.filter(
                (following) => !activeStreamsIds?.includes(following.userId) && !subbedIds?.includes(following.userId),
            );

            if (inactiveSubs) {
                const newInactiveSubbedStreams = [];
                for (const inactiveSub of inactiveSubs) {
                    newInactiveSubbedStreams.push(await firestoreUsers.getUserPublicInfo(inactiveSub.userId));
                }
                setInactiveSubbedStreams(newInactiveSubbedStreams);
            }
            if (inactiveFollows) {
                const newInactiveFollowedStreams = [];
                for (const inactiveFollow of inactiveFollows) {
                    newInactiveFollowedStreams.push(await firestoreUsers.getUserPublicInfo(inactiveFollow.userId));
                }
                setInactiveFollowedStreams(newInactiveFollowedStreams);
            }
        }

        const actives: StreamPublicData[] = [];
        const follows = followings ? followings : [];
        const subs = subscriptions ? subscriptions : [];

        // setStreams(actives, follows, subs);
    }, [activeStreams, followings, subscriptions]);

    useEffect(() => {
        if (!leaderboardUsers) return;

        const getLeaderBoardStreams = async () => {
            const newLeaderBoard = [];
            for (const user of leaderboardUsers) {
                const stream = await firestore
                    .collection(firestoreCollections.livestreamsPublic)
                    .doc(user.userId)
                    .get();
                if (stream.exists) {
                    newLeaderBoard.push(stream.data() as StreamPublicData);
                }
            }

            setLeaderboard(newLeaderBoard);
        };

        getLeaderBoardStreams();
    }, [leaderboardUsers, activeStreams]);

    const getSubscriptionTier = (ownerId: string) => {
        const subscription: SubscriptionUser = subscriptions?.filter(
            (sub) => sub.userId === ownerId,
        )[0] as SubscriptionUser;
        if (subscription) {
            return true;
        }
        return false;
    };

    const handleAboutClick = () => {
        const isBottom = sideBarAboutsRef.current?.getBoundingClientRect().bottom !== window.innerHeight;
        setIsScrolledToAbouts(isBottom);

        if (isBottom) {
            sideBarAboutsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        } else {
            sideBarContainerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    const channelsTitleStyle: CSSProperties = {
        visibility: computerMode ? 'visible' : 'hidden',
        fontSize: '19px',
        color: palette['secondary'],
        padding: '10px 10px 0px 10px',
        fontWeight: 600,
    };

    const sideBarStyle: CSSProperties = {
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        backgroundColor: palette['primary_l'],
        height: 'calc(100% - 35px)',
        margin: computerMode ? '0px 20px 5px 10px' : '0px 3px 5px 5px',
        borderRadius: '20px',
        boxShadow: '5px 5px 8px rgba(0, 0, 0, 0.2)',
    };

    const sideBarContainerStyle: CSSProperties = {
        boxSizing: 'border-box',
        zIndex: 2,
        maxWidth: '310px',
        background: palette['primary_d'],
        transition: '0.3s',
        position: sideBarHover && !isComputer ? 'absolute' : 'relative',
        width: computerMode ? '275px' : '55px',
        minWidth: computerMode ? '275px' : '55px',
        paddingTop: isMobile ? '55px' : '70px',
        height: '100%',
    };

    const linksStyle: CSSProperties = {
        textDecoration: 'none',
    };

    const linksTextStyle: CSSProperties = {
        fontSize: '14px',
        marginTop: '8px',
        marginLeft: '5px',
        textDecoration: 'underline',
    };

    const expandIconsStyle: CSSProperties = {
        color: palette['text_p'],
        padding: '2px 0px 0px 4px',
        fontSize: '20px',
    };

    return (
        <div
            ref={sideBarContainerRef}
            style={sideBarContainerStyle}
            className={`${styles.invisibleScroll} ${props.activeStream ? styles.backgroundShadow : ''}`}
            onMouseEnter={() => setSideBarHover(true)}
            onMouseLeave={() => setSideBarHover(false)}
        >
            <Box
                style={{ transition: '0.3s', height: '100%' }}
                className={props.activeStream ? styles.sidebarHover : ''}
            >
                {!props.infoMode && (
                    <Box style={sideBarStyle} id="sidebar" className={styles.invisibleScroll}>
                        {((activeSubbedStreams?.length as number) > 0 ||
                            (inactiveSubbedStreams?.length as number) > 0 ||
                            (activeFollowedStreams?.length as number) > 0 ||
                            (inactiveFollowedStreams?.length as number) > 0) && (
                            <Typography style={channelsTitleStyle}>Subbed & Followed</Typography>
                        )}

                        {activeSubbedStreams && (
                            <List disablePadding>
                                {activeSubbedStreams.map((stream: StreamPublicData) => (
                                    <SideBarActiveStream
                                        stream={stream}
                                        key={stream.ownerId}
                                        isLeaderBoard={false}
                                        subscription={true}
                                    />
                                ))}
                            </List>
                        )}

                        {activeFollowedStreams && (
                            <List disablePadding>
                                {activeFollowedStreams.map((stream: StreamPublicData) => (
                                    <SideBarActiveStream stream={stream} key={stream.ownerId} isLeaderBoard={false} />
                                ))}
                            </List>
                        )}

                        {inactiveSubbedStreams && (
                            <List disablePadding>
                                {inactiveSubbedStreams.map((user: UserPublicData) => (
                                    <SideBarInactiveStream
                                        key={user.userId}
                                        user={user}
                                        computerMode={computerMode}
                                        subscription={true}
                                    />
                                ))}
                            </List>
                        )}

                        {inactiveFollowedStreams && (
                            <List disablePadding>
                                {inactiveFollowedStreams.map((user: UserPublicData) => (
                                    <SideBarInactiveStream key={user.userId} user={user} computerMode={computerMode} />
                                ))}
                            </List>
                        )}

                        {leaderboardUsers && leaderboardUsers.length > 0 && (
                            <Box>
                                <Typography style={channelsTitleStyle}>Leaderboard</Typography>

                                <List disablePadding>
                                    {leaderboard &&
                                        leaderboard.map((stream: StreamPublicData, idx: number) => (
                                            <SideBarActiveStream
                                                stream={stream}
                                                key={stream.ownerId}
                                                isLeaderBoard={true}
                                                index={idx}
                                                subscription={getSubscriptionTier(stream.ownerId as string)}
                                            />
                                        ))}
                                </List>
                            </Box>
                        )}
                    </Box>
                )}
                <div style={{ padding: '0px 15px 10px 15px' }} ref={sideBarAboutsRef}>
                    <Box
                        style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
                        onClick={() => handleAboutClick()}
                    >
                        <Typography className={styles.unselectable} style={{ fontWeight: 'bold' }}>
                            About Playerz
                        </Typography>
                        {!props.infoMode && (
                            <Box>
                                {isScrolledToAbouts ? (
                                    <ExpandLessIcon style={expandIconsStyle} />
                                ) : (
                                    <ExpandMoreIcon style={expandIconsStyle} />
                                )}
                            </Box>
                        )}
                    </Box>
                    <Link to="/help/about" style={linksStyle}>
                        <Typography style={linksTextStyle} className={styles.unselectable}>
                            About
                        </Typography>
                    </Link>
                    <Link to="/help/rules" style={linksStyle} className={styles.unselectable}>
                        <Typography style={linksTextStyle}>Community Guidelines</Typography>
                    </Link>
                    <Link to="/help/contact" style={linksStyle} className={styles.unselectable}>
                        <Typography style={linksTextStyle}>Contact</Typography>
                    </Link>
                    <Link to="/help/terms" style={linksStyle}>
                        <Typography style={linksTextStyle}>Terms & Conditions</Typography>
                    </Link>
                    <Link to="/help/privacy" style={linksStyle}>
                        <Typography style={linksTextStyle}>Privacy Policy</Typography>
                    </Link>
                    <Link to="/help/usc2257" style={linksStyle}>
                        <Typography style={linksTextStyle}>18 U.S. Code 2257</Typography>
                    </Link>
                    {/* <Link to="/help/dmca" style={linksStyle}>
                        <Typography style={linksTextStyle}>DMCA</Typography>
                    </Link> */}
                    <br />
                    <Typography style={{ fontSize: '14px' }}>
                        All models appearing on this website are 18 years or older. By entering this site you swear that
                        you are of legal age in your area to view adult material and that you wish to view such
                        material.
                    </Typography>
                </div>
            </Box>
        </div>
    );
};

export default SideBarComponent;
