import React, { CSSProperties, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import ChatComponent from './chat/ChatComponent';
import { StreamPublicData } from '../../../models/Stream';
import styles from '../../../styles/styles.module.css';
import ProfilePage from './profile_page/ProfilePage';
import { UserStreamingData, UserPublicData, Referral } from '../../../models/User';
import FirestoreLiveStreams from '../../../firestore/FirestoreLiveStreams';
import { useAuth } from '../../../contexts/AuthContext';
import { firestore, functions } from '../../../Firebase';
import { firestoreCollections, hlsPlayerPages } from '../../../constants/Constants';
import HlsPlayer from './HlsPlayer';
import { LoadingPage } from '../../../styles/CustomComponents';
import { useMainWindowHost } from '../../../contexts/MainWindowHostContext';
import { Message } from '../../../models/Chat';
import ReferralDialog from './dialogs/ReferralDialog';
import AuthenticationDialog from '../../nav_bar/authentication/AuthenticationDialog';

interface Params {
    username: string;
}

const StreamingViewPageComponent = () => {
    const firestoreLiveStreams: FirestoreLiveStreams = new FirestoreLiveStreams();

    const { username } = useParams<Params>();
    const { currentUser } = useAuth();
    const { isMobile } = useMainWindowHost();

    const history = useHistory();
    const location = useLocation();
    const { setActiveStream } = useMainWindowHost();

    const [user, setUser] = useState<UserPublicData>();
    const [userStreamingData, setUserStreamingData] = useState<UserStreamingData>();
    const [messages, setMessages] = useState<Message[]>();

    const [canWatchStream, setCanWatchStream] = useState(false);
    const [badgeUrl, setBadgeUrl] = useState('');
    const [currentStream, setCurrentStream] = useState<StreamPublicData>();
    const [banned, setBanned] = useState(
        currentStream
            ? (currentStream.bannedUsernameList?.includes(currentUser.displayUsername as string) as boolean)
            : false,
    );

    const [authDialogOpen, setAuthDialogOpen] = useState(false);
    const [referralDialogOpen, setReferralDialogOpen] = useState(false);
    const [referral, setReferral] = useState<Referral>();

    const [pageLoading, setPageLoading] = useState(true);
    const [referralLoading, setReferralLoading] = useState(true);

    useEffect(() => {
        if (!location) return;

        const getUsernameReferral = async () => {
            const getReferral = functions.httpsCallable('referralFunctions-getReferral');
            const ref = await getReferral({ username: username });

            setReferralLoading(false);
            setReferral(ref.data);
            setReferralDialogOpen(true);
        };

        location.pathname === `/${username}/referral` ? getUsernameReferral() : setReferralLoading(false);
    }, [location]);

    useEffect(() => {
        if (currentStream) {
            setBanned(currentStream.bannedUsernameList?.includes(currentUser.displayUsername as string) as boolean);
        }
    }, [currentStream]);

    useEffect(() => {
        if (!username) return;

        const checkIfUserExists = async () => {
            const users = await firestore
                .collection(firestoreCollections.userPublic)
                .where('username', '==', username)
                .get();

            users.size > 0 ? setUser(users.docs[0].data() as UserPublicData) : setPageLoading(false);
        };

        checkIfUserExists();
    }, [username]);

    const incrementViewCount = async (newStream: StreamPublicData) => {
        await firestoreLiveStreams.addSelfToStreamViewers(newStream.ownerId as string, {
            userId: currentUser.userId,
            displayUsername: currentUser.displayUsername ? currentUser.displayUsername : '',
            profilePictureUrl: currentUser.profilePictureUrl ? currentUser.profilePictureUrl : '',
        });
    };

    const setUsersTier = async (newStream: StreamPublicData) => {
        const subscription = await firestore
            .collection(firestoreCollections.subscriptions)
            .doc(currentUser.userId)
            .collection(firestoreCollections.subscribedTo)
            .doc(currentStream?.ownerId)
            .get();

        if (subscription.exists) {
            setBadgeUrl(userStreamingData?.subscription.badgeUrl as string);
            setCanWatchStream(true);
        } else if (!newStream.subscribersOnly || newStream.ownerId === currentUser.userId) {
            setCanWatchStream(true);
        }
    };

    useEffect(() => {
        if (!user) return;

        const sub = firestore
            .collection(firestoreCollections.livestreamsPublic)
            .doc(user?.userId)
            .onSnapshot(async (snap) => {
                const newStream = snap.data() as StreamPublicData;
                setPageLoading(false);

                if (!newStream) return;
                if (!newStream.subscribersOnly) {
                    setCanWatchStream(true);
                }
                if (!currentStream?.active && newStream.active && newStream.ownerId != currentUser.userId) {
                    await setUsersTier(newStream);
                    await incrementViewCount(newStream);
                }

                setActiveStream(newStream.active as boolean);
                setCurrentStream(newStream);
            });

        return sub;
    }, [user]);

    useEffect(() => {
        if (!user) return;

        const sub = firestore
            .collection(firestoreCollections.userStreaming)
            .doc(user?.userId)
            .onSnapshot((snap) => {
                setUserStreamingData(snap.data() as UserStreamingData);
            });

        return sub;
    }, [user]);

    useEffect(() => {
        if (!user) return;

        const sub = firestore
            .collection(firestoreCollections.livestreamsPublic)
            .doc(user?.userId)
            .collection(firestoreCollections.chat)
            .orderBy('time', 'desc')
            .onSnapshot((snap) => {
                const newMessages: Message[] = [];
                snap.docs.forEach((doc) => {
                    newMessages.push({ ...doc.data(), id: doc.id } as Message);
                });
                setMessages(newMessages);
            });

        return sub;
    }, [user]);

    useEffect(() => {
        if (!currentStream) return;

        const unsubscribe = history.listen(async () => {
            setActiveStream(false);
            setPageLoading(true);
            if (username !== currentUser.username && currentStream.active) {
                await firestoreLiveStreams.removeSelfToStreamViewers(
                    currentStream.ownerId as string,
                    currentUser.userId,
                );
            }
        });

        return unsubscribe;
    }, [currentStream]);

    const streamBoxStyle: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    };

    return pageLoading || referralLoading ? (
        <Box height="90vh">
            <LoadingPage />
        </Box>
    ) : (
        <Box
            style={{
                display: 'flex',
                height: '100%',
            }}
        >
            <Box style={streamBoxStyle}>
                <Box className={styles.invisibleScroll}>
                    <Box height={isMobile ? 'auto' : '100%'} position="relative">
                        {currentStream?.active && !banned && (
                            <Box id="activeStream">
                                <HlsPlayer
                                    videoUrl={currentStream?.playbackUrl as string}
                                    thumbnailUrl={currentStream?.thumbnailUrl as string}
                                    cantWatchStream={!canWatchStream}
                                    page={hlsPlayerPages.streamingPage}
                                />
                            </Box>
                        )}
                        <ProfilePage
                            currentStream={currentStream as StreamPublicData}
                            user={user as UserPublicData}
                            userStreamingData={userStreamingData as UserStreamingData}
                            chatMessages={messages as Message[]}
                            banned={banned}
                        />
                    </Box>
                </Box>
            </Box>

            {currentStream?.active && !banned && !isMobile && (
                <ChatComponent
                    streamId={currentStream?.ownerId as string}
                    canWatchStream={canWatchStream}
                    badgeUrl={badgeUrl}
                    user={user as UserPublicData}
                    userStreamingData={userStreamingData as UserStreamingData}
                    messages={messages as Message[]}
                />
            )}

            <ReferralDialog
                open={referralDialogOpen}
                referral={referral as Referral}
                username={username}
                setOpen={setReferralDialogOpen}
                setAuthDialogOpen={setAuthDialogOpen}
            />

            <AuthenticationDialog open={authDialogOpen} setDialogState={setAuthDialogOpen} referral={referral} />
        </Box>
    );
};

export default StreamingViewPageComponent;
