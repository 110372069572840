import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { palette } from '../../../styles/theme';
import { Link, useParams } from 'react-router-dom';
import About from './About';
import Rules from './Rules';
import Contact from './Contact';
import Terms from './Terms';
import Privacy from './Privacy';
import Dmca from './Dmca';
import Usc2257 from './Usc2257';
import AuthEmailTemplate from '../auth_email_template/AuthEmailTemplate';
import styles from '../../../styles/styles.module.css';
import { useMainWindowHost } from '../../../contexts/MainWindowHostContext';

interface SectionInfo {
    element: JSX.Element;
    title: string;
}

const helpSections = new Map<string, SectionInfo>([
    ['about', { element: <About key={1} />, title: 'About Playerz' }],
    ['rules', { element: <Rules key={2} />, title: 'Community Guidelines' }],
    ['contact', { element: <Contact key={3} />, title: 'Contact' }],
    ['terms', { element: <Terms key={4} />, title: 'Terms and Conditions' }],
    ['privacy', { element: <Privacy key={5} />, title: 'Privacy Policy' }],
    ['dmca', { element: <Dmca key={6} />, title: 'DCMA' }],
    ['usc2257', { element: <Usc2257 key={7} />, title: '18 U.S. Code 2257' }],
    ['auth', { element: <AuthEmailTemplate key={8} />, title: 'Email' }],
]);

interface Params {
    section: string;
}

const InfoPageHost = () => {
    const { isMobile } = useMainWindowHost();

    const { section } = useParams<Params>();
    const sectionInfo = helpSections.get(section);

    return (
        <Box
            className={styles.invisibleScroll}
            style={{ width: '95%', maxWidth: '900px', margin: 'auto', height: '100%' }}
        >
            <Typography style={{ padding: '10px 0px', fontSize: isMobile ? '20px' : '30px' }}>
                {sectionInfo?.title}
            </Typography>
            <Box
                style={{
                    backgroundColor: palette['primary_l'],
                    padding: '20px',
                    borderRadius: '20px',
                }}
            >
                {sectionInfo?.element}
            </Box>
            {isMobile && (
                <Box margin="15px">
                    {Array.from(helpSections.entries()).map((entry, idx) => {
                        if (entry[0] !== section && entry[0] !== 'auth') {
                            return (
                                <Link to={`/help/${entry[0]}`} style={{ textDecoration: 'none' }} key={idx}>
                                    <Typography style={{ textDecoration: 'underline' }}>{entry[1].title}</Typography>
                                </Link>
                            );
                        }
                    })}
                </Box>
            )}
        </Box>
    );
};

export default InfoPageHost;
