/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, DialogContent, DialogTitle, Divider, Typography } from '@material-ui/core';
import React, { CSSProperties, useState } from 'react';
import { PayPalButton } from 'react-paypal-button-v2';
import { paypalTransactionTypes } from '../../constants/Constants';
import { useAuth } from '../../contexts/AuthContext';
import FirestoreTransactions from '../../firestore/FirestoreTransactions';
import {
    CompletedPaypalOrder,
    IPointsPackage,
    ProductInformation,
    SubscriptionPackage,
} from '../../models/Transaction';
import { palette } from '../../styles/theme';
import styles from '../../styles/styles.module.css';
import { LoadingPage } from '../../styles/CustomComponents';
import { UserStreamingData } from '../../models/User';
import FirestoreUsers from '../../firestore/FirestoreUsers';
import { useMainWindowHost } from '../../contexts/MainWindowHostContext';

interface NewCompleteOrderDialog {
    selectedProduct: ProductInformation;
    setTransactionEnded: () => void;
    setTransactionSuccess: (isSuccessful: boolean) => void;
}

const CompleteOrderDialog = (props: NewCompleteOrderDialog) => {
    const { isMobile } = useMainWindowHost();

    const [transactionInProgress, setTransactionInProgress] = useState(false);
    const firestoreTransactions: FirestoreTransactions = new FirestoreTransactions();
    const firestoreUsers: FirestoreUsers = new FirestoreUsers();
    const { currentUser } = useAuth();

    const subtitleStyle: CSSProperties = {
        fontSize: isMobile ? '18px' : '22px',
        fontWeight: 600,
    };

    const getPaypalButton = () => {
        switch (props.selectedProduct.type) {
            case 'order': {
                const pointsPackages: IPointsPackage = props.selectedProduct.product as IPointsPackage;
                return (
                    <Box style={{ width: '100%', maxWidth: '400px', margin: 'auto', padding: '20px 0px 5px 0px' }}>
                        {/* <PayPalButton
                            style={{ width: '100%', maxWidth: '200px' }}
                            createOrder={(data: any, actions: any) => {
                                setTransactionInProgress(false);

                                return actions.order.create({
                                    purchase_units: [
                                        {
                                            amount: {
                                                value: pointsPackages.price,
                                            },
                                        },
                                    ],
                                    application_context: {
                                        shipping_preference: 'NO_SHIPPING',
                                    },
                                });
                            }}
                            onApprove={async (data: any, actions: any) => {
                                setTransactionInProgress(true);
                                const cap = await actions.order.capture();
                                const paypalCompleteOrder: CompletedPaypalOrder = {
                                    paypalId: data.orderID,
                                    createTime: cap.create_time,
                                    emailAddress: cap.payer.email_address,
                                    payerId: data.payerID,
                                    pointsAmount: pointsPackages.points,
                                    userId: currentUser.userId,
                                    price: pointsPackages.price,
                                    productId: pointsPackages.productId,
                                    type: paypalTransactionTypes.order,
                                };
                                if (await firestoreTransactions.setNewPaypalTransaction(paypalCompleteOrder)) {
                                    props.setTransactionSuccess(true);
                                    props.setTransactionEnded();
                                }
                            }}
                            onError={(err: any) => {
                                console.log('PAYPAL ERROR');
                                console.log(err);
                                setTransactionInProgress(false);
                                props.setTransactionEnded();
                            }}
                        /> */}
                    </Box>
                );
            }
            case 'subscription': {
                const subscriptionPackage: SubscriptionPackage = props.selectedProduct.product as SubscriptionPackage;
                return (
                    <Box style={{ maxWidth: '400px', margin: 'auto', padding: '20px 0px 5px 0px' }}>
                        <PayPalButton
                            createSubscription={async (data: any, actions: any) => {
                                setTransactionInProgress(true);
                                const user: UserStreamingData = await firestoreUsers.getUserStreamingData(
                                    subscriptionPackage.userId,
                                );
                                const monthlyFee = user.subscription.monthlyFee;

                                const planId = await firestoreTransactions.getSubscriptionPlanId(monthlyFee);

                                return actions.subscription.create({
                                    plan_id: planId,
                                });
                            }}
                            onApprove={async (data: any) => {
                                const paypalCompleteOrder: CompletedPaypalOrder = {
                                    paypalId: data.subscriptionID,
                                    userId: currentUser.userId,
                                    price: subscriptionPackage.subscription.monthlyFee as number,
                                    productId: 'sub',
                                    type: paypalTransactionTypes.subscription,
                                    subscribedToId: subscriptionPackage.userId,
                                    numberOfMonthsPayed: 0,
                                };

                                if (await firestoreTransactions.setNewPaypalTransaction(paypalCompleteOrder)) {
                                    props.setTransactionSuccess(true);
                                    props.setTransactionEnded();
                                }
                            }}
                            onError={(err: any) => {
                                console.log('PAYPAL ERROR');
                                console.log(err);
                                setTransactionInProgress(false);
                                props.setTransactionEnded();
                            }}
                        />
                    </Box>
                );
            }
        }
    };

    return (
        <>
            <DialogTitle>
                <Typography
                    style={{ fontSize: isMobile ? '20px' : '26px', fontWeight: 600, color: palette['secondary'] }}
                >
                    Purchase Review
                </Typography>
            </DialogTitle>
            <DialogContent className={styles.invisibleScroll} style={{ scrollSnapType: 'y' }}>
                <Box style={{ scrollSnapAlign: 'end', paddingBottom: '10px' }}>
                    <Typography style={subtitleStyle}>You have selected:</Typography>
                    <Box
                        style={{
                            backgroundColor: palette['softDarkBlue'],
                            borderRadius: '25px',
                            margin: isMobile ? '10px 0px' : '15px 20px',
                            padding: '0px 10px',
                        }}
                    >
                        {props.selectedProduct.display}
                    </Box>
                    <Divider style={{ height: '2px', backgroundColor: palette['primary_d'], margin: '35px 0px' }} />
                    <Typography style={subtitleStyle}>Complete your purchase</Typography>
                    {transactionInProgress && <LoadingPage />}
                    <Box
                        style={{
                            backgroundColor: palette['text_p'],
                            borderRadius: '25px',
                            margin: isMobile ? '10px 0px' : '15px 20px',
                            padding: isMobile ? '0px 10px' : '0px',
                            opacity: transactionInProgress ? 0 : 1,
                            height: transactionInProgress ? 0 : 'auto',
                        }}
                    >
                        {getPaypalButton()}
                    </Box>
                </Box>
            </DialogContent>
        </>
    );
};

export default CompleteOrderDialog;
