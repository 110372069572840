import { Box, Checkbox, Typography } from '@material-ui/core';
import React, { CSSProperties, SetStateAction, useState } from 'react';
import { PointsObjective } from '../../../../../models/User';
import { Input } from '../../../../../styles/CustomComponents';
import { palette } from '../../../../../styles/theme';
import CloseIcon from '@material-ui/icons/Close';
import PointsObjectiveBar from '../../../../helper/PointsObjectiveBar';
import { useMainWindowHost } from '../../../../../contexts/MainWindowHostContext';
import { getNumberInThousands } from '../../../../../helpers/helpers';

interface PointsObjectiveSetupProps {
    setObjectives: React.Dispatch<SetStateAction<PointsObjective[]>>;
    objectives: PointsObjective[];
    monthlyScore: number;
}

const PointsObjectiveSetup = (props: PointsObjectiveSetupProps) => {
    const { isMobile } = useMainWindowHost();

    const [pointsObjectiveField, setPointsObjectiveField] = useState<string>('');
    const [rewardObjectiveField, setRewardObjectiveField] = useState<string>('');
    const [isScoreTooLow, setIsScoreTooLow] = useState(false);

    const handleAddObjective = () => {
        if (props.objectives.length < 6 && pointsObjectiveField && rewardObjectiveField) {
            if (Number(pointsObjectiveField) < props.monthlyScore) {
                setIsScoreTooLow(true);
                return;
            }
            const newObjective: PointsObjective = {
                points: Number(pointsObjectiveField),
                reward: rewardObjectiveField,
                isHidden: false,
            };
            props.setObjectives(
                [...props.objectives, newObjective].sort((a, b) => {
                    return a.points - b.points;
                }),
            );
            setPointsObjectiveField('');
            setRewardObjectiveField('');
        }
    };

    const handleDeleteObjective = (index: number) => {
        props.setObjectives((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)]);
    };

    const titlesStyle: CSSProperties = {
        display: 'inline',
        fontSize: '16px',
        fontWeight: 'bold',
    };

    const subTitlesStyle: CSSProperties = {
        display: 'inline',
        fontSize: '13px',
    };

    const mainContainerStyle: CSSProperties = {
        backgroundColor: palette['primary'],
        borderRadius: '15px',
        padding: '10px',
        marginBottom: '10px',
    };

    return (
        <Box style={mainContainerStyle}>
            <Box>
                <Typography style={titlesStyle}>Setup your&nbsp;</Typography>
                <Typography style={{ ...titlesStyle, color: palette['secondary'] }}>Points</Typography>
                <Typography style={titlesStyle}>&nbsp;Tracker</Typography>
            </Box>
            <Box style={{ marginBottom: '10px' }}>
                <Typography style={subTitlesStyle}>You have a montly score of&nbsp;</Typography>
                <Typography style={{ ...subTitlesStyle, color: palette['secondary'] }}>
                    {getNumberInThousands(props.monthlyScore)} Points
                </Typography>
            </Box>
            <Box style={{ display: 'flex' }}>
                <Box>
                    <Typography>{props.objectives?.length}/6</Typography>
                </Box>
                <Typography>&nbsp;objectives:&nbsp;</Typography>
            </Box>
            <Box
                style={{
                    backgroundColor: palette['primary_l'],
                    borderRadius: '20px',
                    padding: '4px 10px',
                    marginBottom: '5px',
                }}
            >
                <Box style={{ display: isMobile ? 'block' : 'flex', alignItems: 'center' }}>
                    <Typography>Objective:</Typography>
                    <Input
                        value={pointsObjectiveField}
                        onChange={(event) => {
                            if (event.target.value === '' || /^[0-9\b]+$/.test(event.target.value)) {
                                setPointsObjectiveField(event.target.value);
                                setIsScoreTooLow(false);
                            }
                        }}
                        style={{ margin: isMobile ? '0px' : '0px 10px 0px 5px', width: '100px' }}
                    />
                    <Typography>Reward:</Typography>
                    <Box display="flex" alignItems="center">
                        <Input
                            value={rewardObjectiveField}
                            onChange={(event) => setRewardObjectiveField(event.target.value)}
                            style={{
                                margin: isMobile ? '0px 10px 0px 0px' : '0px 10px 0px 5px',
                                width: isMobile ? '100%' : 'auto',
                            }}
                        />
                        <Box
                            style={{
                                borderRadius: '10px',
                                backgroundColor: palette['secondary'],
                                padding: '3px 10px',
                                cursor: 'pointer',
                            }}
                            onClick={handleAddObjective}
                        >
                            <Typography>Add</Typography>
                        </Box>
                    </Box>
                </Box>
                {isScoreTooLow && (
                    <Typography style={{ color: palette['secondary'], fontSize: '12px' }}>
                        *Your objective cannot be lower than your current score
                    </Typography>
                )}
            </Box>
            <Box>
                {props.objectives?.map((objective: PointsObjective, idx: number) => {
                    return (
                        <Box
                            key={idx}
                            style={{ paddingLeft: '15px', display: 'flex', marginBottom: '5px', alignItems: 'center' }}
                        >
                            <Typography style={{ marginRight: '10px' }}>{idx + 1}. </Typography>
                            <Box
                                style={{
                                    backgroundColor: palette['primary_l'],
                                    padding: '3px 8px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    position: 'relative',
                                    borderRadius: '10px',
                                    width: '350px',
                                    marginRight: '15px',
                                }}
                            >
                                <Typography style={{ color: palette['secondary'], fontWeight: 600 }}>
                                    {objective.points}
                                </Typography>
                                <Typography noWrap style={{ textOverflow: 'ellipsis', maxWidth: '220px' }}>
                                    : {objective.reward}
                                </Typography>
                                <Box
                                    style={{
                                        backgroundColor: palette['primary_d'],
                                        display: 'flex',
                                        alignItems: 'center',
                                        right: '0px',
                                        position: 'absolute',
                                        marginRight: '5px',
                                        borderRadius: '5px',
                                        padding: '1px 5px',
                                    }}
                                >
                                    <Checkbox
                                        checked={objective.isHidden}
                                        size="small"
                                        style={{ padding: '0px', color: palette['secondary'] }}
                                        onChange={(event) =>
                                            props.setObjectives((prev) => {
                                                prev[idx].isHidden = event.target.checked;
                                                return [...prev];
                                            })
                                        }
                                    />
                                    <Typography style={{ fontSize: '14px', margin: '0px 2px', fontWeight: 500 }}>
                                        Hide
                                    </Typography>
                                </Box>
                            </Box>
                            <CloseIcon
                                onClick={() => {
                                    handleDeleteObjective(idx);
                                }}
                                style={{ color: palette['secondary'], cursor: 'pointer' }}
                            />
                        </Box>
                    );
                })}
            </Box>
            <Box style={{ height: '60px', marginTop: '5px' }}>
                <Typography>Preview</Typography>
                {props.objectives.length ? (
                    <PointsObjectiveBar
                        objectives={props.objectives}
                        currentScore={props.monthlyScore}
                        startingScore={props.monthlyScore}
                    />
                ) : (
                    <Typography style={{ marginLeft: '10px', color: palette['secondary'], fontSize: '16px' }}>
                        No Objective set
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default PointsObjectiveSetup;
