import { Box, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import 'react-quill/dist/quill.snow.css';
import { PointsObjective } from '../../models/User';
import { palette } from '../../styles/theme';
import LockIcon from '@material-ui/icons/Lock';
import { CSSProperties } from '@material-ui/styles';
import { getNumberInThousands } from '../../helpers/helpers';

interface PointsObjectiveBarProps {
    objectives: PointsObjective[];
    currentScore: number;
    startingScore?: number;
}

const PointsObjectiveBar = (props: PointsObjectiveBarProps) => {
    const getEndingPoints = () => {
        if (props.objectives.length) {
            if (props.currentScore > props.objectives[props.objectives.length - 1].points) {
                return props.currentScore;
            } else {
                return props.objectives[props.objectives.length - 1].points;
            }
        } else {
            return props.currentScore + 500;
        }
    };

    const LightTooltip = withStyles((theme: Theme) => ({
        arrow: {
            color: palette['text_p'],
        },
        tooltip: {
            backgroundColor: palette['text_p'],
            color: 'rgba(0, 0, 0, 1)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }))(Tooltip);

    const mainContainerStyle: CSSProperties = {
        display: 'flex',
        height: '35px',
        backgroundColor: palette['primary_l'],
        borderRadius: '10px',
        alignItems: 'center',
        padding: '0px 5px',
        position: 'relative',
    };

    const pointsBarStyle: CSSProperties = {
        backgroundColor: palette['secondary'],
        width: `${
            ((props.currentScore - (props.startingScore ? props.startingScore : props.currentScore)) /
                (getEndingPoints() - (props.startingScore ? props.startingScore : props.currentScore))) *
            100
        }%`,
        minWidth: 'fit-content',
        height: '25px',
        borderRadius: '7px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    };

    return (
        <Box style={mainContainerStyle}>
            <Box style={pointsBarStyle}>
                <Typography
                    style={{
                        margin: '0px 3px',
                        fontWeight: 700,
                        color: palette['primary_l'],
                        display: 'inline-block',
                    }}
                >
                    {getNumberInThousands(props.currentScore)}
                </Typography>
            </Box>
            {props.objectives.map((objective: PointsObjective, idx: number) => {
                return (
                    <LightTooltip
                        key={idx}
                        placement="top"
                        arrow
                        title={
                            objective.isHidden && props.currentScore < objective.points ? (
                                <LockIcon />
                            ) : (
                                <Box style={{ display: 'flex' }}>
                                    <Typography style={{ color: palette['secondary'], fontWeight: 700 }}>
                                        {getNumberInThousands(objective.points)}
                                    </Typography>
                                    <Typography style={{ color: palette['primary_d'], fontWeight: 500 }}>
                                        : {objective.reward}
                                    </Typography>
                                </Box>
                            )
                        }
                    >
                        <Box
                            style={{
                                backgroundColor: palette['text_p'],
                                position: 'absolute',
                                width: '7px',
                                borderRadius: '2px',
                                height: '25px',
                                left: `${
                                    ((objective.points -
                                        (props.startingScore ? props.startingScore : props.currentScore)) /
                                        (getEndingPoints() -
                                            (props.startingScore ? props.startingScore : props.currentScore))) *
                                    97
                                }%`,
                            }}
                        ></Box>
                    </LightTooltip>
                );
            })}
        </Box>
    );
};

export default PointsObjectiveBar;
