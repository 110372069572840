import { Avatar, Box, Dialog, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { UserStreamingData, UserPublicData } from '../../../../../models/User';
import { palette } from '../../../../../styles/theme';
import { ProductInformation, SubscriptionPackage } from '../../../../../models/Transaction';
import CompleteOrderDialog from '../../../../helper/CompleteOrderContent';
import OrderEnded from '../../../../helper/OrderEnded';
import SubscriptionDialogContent from './SubscriptionDialogContent';
import { useMainWindowHost } from '../../../../../contexts/MainWindowHostContext';

interface SubscribeDialogProps {
    isOpen: boolean;
    setDialogState: (isOpen: boolean) => void;
    userStreamingData: UserStreamingData;
    user: UserPublicData;
}

const SubscribeDialog = (props: SubscribeDialogProps) => {
    const { isMobile } = useMainWindowHost();

    const [state, setState] = useState(0);

    const [orderSuccessful, setOrderSuccessful] = useState(false);

    useEffect(() => {
        if (props.isOpen) {
            setState(0);
            const payPalSdk = document.getElementById('payPalSdk') as HTMLScriptElement;
            payPalSdk.src =
                'https://www.paypal.com/sdk/js?client-id=AVXgn-q6aQNNnKx6Q38pVZCuax1xJPXGZ_Ob_064RFI8pwVfZKXxl7YRS4qt0FbnykqC0xHzuzRKa3Bp&vault=true&intent=subscription';
        }
    }, [props.isOpen]);

    const handleClose = () => {
        props.setDialogState(false);
    };

    const handleSubscribeButton = () => {
        setState(1);
    };

    const setDialogContent = () => {
        switch (state) {
            case 0: {
                return (
                    <Box>
                        <SubscriptionDialogContent
                            subscriptionBannerUrl={props.userStreamingData?.subscriptionBannerUrl}
                            profilePictureUrl={props.user?.profilePictureUrl}
                            displayUsername={props.user?.displayUsername}
                            subscription={props.userStreamingData?.subscription}
                            subscribe={handleSubscribeButton}
                        />
                    </Box>
                );
            }
            case 1: {
                const element = (
                    <Box style={{ display: 'flex', padding: '10px 0px' }}>
                        <Avatar
                            src={props.user?.profilePictureUrl}
                            style={{
                                width: '12vw',
                                height: '12vw',
                                maxWidth: 65,
                                maxHeight: 65,
                                border: `3px solid  ${palette['primary_l']}`,
                                padding: '2px',
                                marginRight: '10px',
                            }}
                        />
                        <Box style={{ margin: 'auto 0px' }}>
                            <Typography
                                style={{
                                    fontSize: isMobile ? '16px' : '19px',
                                    fontWeight: 600,
                                    lineHeight: isMobile ? '1' : 'auto',
                                }}
                            >
                                Subscription to {props.user?.displayUsername}
                            </Typography>
                            <Typography style={{ opacity: 0.5, fontSize: '15px' }}>
                                {props.userStreamingData?.subscription.monthlyFee}$/month
                            </Typography>
                        </Box>
                    </Box>
                );

                const product: SubscriptionPackage = {
                    userId: props.userStreamingData.userId,
                    subscription: props.userStreamingData?.subscription,
                };

                const productInfo: ProductInformation = {
                    display: element,
                    product: product,
                    type: 'subscription',
                };

                return (
                    <CompleteOrderDialog
                        selectedProduct={productInfo}
                        setTransactionEnded={onTransactionEnded}
                        setTransactionSuccess={onTransactionSuccess}
                    />
                );
            }
            case 2: {
                if (orderSuccessful) {
                    return <OrderEnded isOrderSuccessful={orderSuccessful} setClose={handleClose} />;
                }
            }
        }
    };

    const onTransactionSuccess = (isSuccessful: boolean) => {
        setOrderSuccessful(isSuccessful);
    };

    const onTransactionEnded = () => {
        setState(2);
    };

    return (
        <Dialog open={props.isOpen} onClose={handleClose} PaperProps={{ style: { margin: '18px', width: '600px' } }}>
            {setDialogContent()}
        </Dialog>
    );
};

export default SubscribeDialog;
